import React from 'react';
import { connect } from "react-redux";
import { fetchBranchList } from '../../action/branch';
import { fetchUserList, deleteAUser } from '../../action/user';
import EditUserForm from './EditUserForm';
import ManageUser from './ManageUser';
import BranchList from './BranchList';
import SelectBranch from './SelectBranch';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  fetchBranchList,
  fetchUserList,
  deleteAUser
};

class Users extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      branch_id: 0,
    }
    this.props.fetchBranchList();
    this.props.fetchUserList();
  }

  onBranchChange(branch_id) {
    this.setState({
      branch_id: branch_id
    })
  }

  handleDelete(user_id) {
    // after delete, the survey list need to reload using the same data from the previous page
    this.props.deleteAUser(user_id, this.state.branch_id);
  }

  render() {
    const user = this.props.userView.user;
    return (
      <div className="Users">
        <BranchList onBranchChange={this.onBranchChange.bind(this)}></BranchList>
        <ManageUser handleDelete={this.handleDelete.bind(this)}></ManageUser>
        {user.id ?
          this.props.root.overlay ?
            <SelectBranch></SelectBranch> :
            <EditUserForm></EditUserForm> :
          ""}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
