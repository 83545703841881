import React from 'react';
import BranchList from './BranchList';
import ManageSurvey from './ManageSurvey';
import SurveyInfo from './SurveyInfo';
import { connect } from "react-redux";
import { fetchBranchList } from '../../action/branch';
import { fetchSurveyList, deleteASurvey } from '../../action/survey';
import * as Constants from '../../Constants';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    fetchBranchList,
    fetchSurveyList,
    deleteASurvey
};

class Surveys extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: 1, 
            branch_id: '',
            page: 1,
            endPage: false
        }
        this.props.fetchBranchList();
        this.props.fetchSurveyList();
    }
    
    handleClick(e) {
        this.setState({active: e.target.id});
    }

    loadMore() {
        let nextPage = this.state.page + 1;
        let currentCount = this.state.page * Constants.MAX_COUNT;
        let nextCount = nextPage * Constants.MAX_COUNT;
        

        if (this.state.endPage !== true) {
            if (nextCount < this.props.surveyView.total_count) {
                this.setState({ page: nextPage });
            } else {
                nextCount = this.props.surveyView.total_count;
                this.setState({ page: nextPage, endPage: true});
            }
            // fetch survey list again
            this.props.fetchSurveyList(this.state.branch_id, currentCount, Constants.MAX_COUNT);
        }
    }

    handleDelete(survey_id) {
        // after delete, the survey list need to reload using the same data from the previous page
        this.props.deleteASurvey(survey_id, this.state.branch_id, this.state.page);
        this.props.fetchBranchList();
    }

    onBranchChange(branch_id) {
        this.setState({
            branch_id: branch_id,
            page: 1,
            endPage: false
        })
    }

    render() {
        const selectedSurvey = this.props.surveyView.survey;
        return (
            <div className="Surveys">
                {/* Branch List with survey in it */}
                <BranchList onBranchChange={this.onBranchChange.bind(this)}/>
                <ManageSurvey loadMore={this.loadMore.bind(this)} handleDelete={this.handleDelete.bind(this)}/>
                {selectedSurvey.id ?
                    <SurveyInfo/> : ''
                }
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Surveys);
