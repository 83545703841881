import React from 'react';
import DatePicker from "react-datepicker";
import { getDateInUsFormat, getDateInAuFormat } from '../../utils/date';
import { connect } from "react-redux";
import { receivePerformanceData } from '../../action/performance'
import { receiveOverviewData } from '../../action/overview.js'
import { receiveReportCardData } from '../../action/reportCard.js'

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    receiveOverviewData,
    receivePerformanceData,
    receiveReportCardData
};

class CustomDateRange extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            startShow: 1,
            close: 1,
            apply: 1
        }
    }

    startInputClick() {
        this.setState({
            ...this.state,
            startShow: 1
        });
    }

    endInputClick() {
        this.setState({
            ...this.state,
            startShow: 0
        });
    }

    onStartDateChange(date) {
        const seDate = this.getStartEndDate()

        let endDate = seDate.endDate
        if (this.props.position === 'overview') {
            let currentData = this.props.overviewView

            if (endDate !== '' && date > endDate) {
                this.props.receiveOverviewData(currentData.data, currentData.timeSelected, date, '')
            } else {
                this.props.receiveOverviewData(currentData.data, currentData.timeSelected, date, endDate)
            }
        }
        if (this.props.position === 'performance') {
            let currentData = this.props.performanceView

            if (endDate !== '' && date > endDate) {
                this.props.receivePerformanceData({
                    data: {
                        summary: currentData.timeData,
                        detail: currentData.data
                    }
                }, currentData.timeSelected, date, '')
            } else {
                this.props.receivePerformanceData({
                    data: {
                        summary: currentData.timeData,
                        detail: currentData.data
                    }
                }, currentData.timeSelected, date, endDate)
            }
        }
        if (this.props.position === 'reportcard') {
            let currentData = this.props.reportCardView[this.props.type]

            if (endDate !== '' && date > endDate) {
                this.props.receiveReportCardData(currentData.typeCard, '', { data: currentData.data }, currentData.timeSelected, date, '')
            } else {
                this.props.receiveReportCardData(currentData.typeCard, '', { data: currentData.data }, currentData.timeSelected, date, endDate)
            }
        }

        this.setState({
            ...this.state,
            startShow: 0
        });

    }

    onEndDateChange(date) {
        const seDate = this.getStartEndDate()
        let startDate = seDate.startDate
        if (this.props.position === 'overview') {
            let currentData = this.props.overviewView

            if (date < startDate) {
                this.props.receiveOverviewData(currentData.data, currentData.timeSelected, date, '')
            } else {
                this.props.receiveOverviewData(currentData.data, currentData.timeSelected, startDate, date)
            }
        }
        if (this.props.position === 'performance') {
            let currentData = this.props.performanceView
            if (date < startDate) {
                this.props.receivePerformanceData({
                    data: {
                        summary: currentData.timeData,
                        detail: currentData.data
                    }
                }, currentData.timeSelected, date, '')
            } else {
                this.props.receivePerformanceData({
                    data: {
                        summary: currentData.timeData,
                        detail: currentData.data
                    }
                }, currentData.timeSelected, startDate, date)
            }
        }
        if (this.props.position === 'reportcard') {
            let currentData = this.props.reportCardView[this.props.type]

            if (date < startDate) {
                this.props.receiveReportCardData(currentData.typeCard, '', { data: currentData.data }, currentData.timeSelected, date, '')
            } else {
                this.props.receiveReportCardData(currentData.typeCard, '', { data: currentData.data }, currentData.timeSelected, startDate, date)
            }
        }
    }

    closeClick() {
        this.setState({
            ...this.state,
            close: 1,
            startShow: 1
        });
    }

    applyClick() {
        const seDate = this.getStartEndDate()
        let startDate = seDate.startDate
        let endDate = seDate.endDate

        if (startDate === '' || endDate === '') {
            alert('Please select both Date From and Date To')
        } else {
            this.props.handleOnClick(5, startDate, endDate, '')
            this.setState({
                ...this.state,
                close: 1
            });
        }
    }

    selectDateClick() {
        this.setState({
            ...this.state,
            close: 0
        });
    }

    getStartEndDate() {
        let startDate = '';
        let endDate = '';
        if (this.props.position === 'overview') {
            startDate = this.props.overviewView.startDate ? this.props.overviewView.startDate : '';
            endDate = this.props.overviewView.endDate ? this.props.overviewView.endDate : '';
        }
        if (this.props.position === 'performance') {
            startDate = this.props.performanceView.startDate ? this.props.performanceView.startDate : '';
            endDate = this.props.performanceView.endDate ? this.props.performanceView.endDate : '';
        }
        if (this.props.position === 'reportcard') {
            startDate = this.props.reportCardView[this.props.type].startDate ? this.props.reportCardView[this.props.type].startDate : '';
            endDate = this.props.reportCardView[this.props.type].endDate ? this.props.reportCardView[this.props.type].endDate : '';
        }
        return {
            startDate: startDate,
            endDate: endDate
        }
    }

    render() {
        const seDate = this.getStartEndDate()
        let startDate = seDate.startDate
        let endDate = seDate.endDate
        return (
            <div>
                {this.state.close ?
                    <div className={"select-date-div position-" + this.props.position} onClick={this.selectDateClick.bind(this)}>
                        {startDate === '' || endDate === '' ? 'Select Dates' : getDateInAuFormat(startDate) + ' - ' + getDateInAuFormat(endDate)}
                    </div>
                    :
                    <div className={"datepicker-div position-" + this.props.position}>
                        <div className="close" onClick={this.closeClick.bind(this)}>X</div>
                        <div className="input-div">
                            <div className="inline reltv">
                                <label>From Date:</label>
                                <br />
                                <input type="text" readOnly className={"date-picker" + (this.state.startShow ? ' active' : '')}
                                    value={startDate === '' ? "YYYY-MM-DD" : getDateInUsFormat(startDate)}
                                    onClick={this.startInputClick.bind(this)}></input>
                                {startDate !== '' ?
                                    <img src='/img/calendar.svg' alt="calendar" className="calendar-svg" />
                                    : ""}
                            </div>
                            <div className="inline reltv">
                                <label>To Date:</label>
                                <br />
                                <input type="text" readOnly className={"date-picker" + (!this.state.startShow ? ' active' : '')}
                                    value={endDate === '' ? "YYYY-MM-DD" : getDateInUsFormat(endDate)}
                                    onClick={this.endInputClick.bind(this)}></input>
                                {endDate !== '' ?
                                    <img src='/img/calendar.svg' alt="calendar" className="calendar-svg" />
                                    : ""}
                            </div>
                            <div className="inline">
                                <button className="apply-button" onClick={this.applyClick.bind(this)}>Apply</button>
                            </div>
                        </div>

                        <div className={"start-datepicker custom-datepicker" + (this.state.startShow ? ' active' : '')}>
                            <DatePicker
                                selected={startDate}
                                onChange={this.onStartDateChange.bind(this)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                inline
                            />
                        </div>
                        <div className={"end-datepicker custom-datepicker" + (!this.state.startShow ? ' active' : '')}>
                            <DatePicker
                                selected={endDate}
                                onChange={this.onEndDateChange.bind(this)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                inline
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomDateRange);
