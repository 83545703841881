import React from 'react';
import { connect } from "react-redux";
import { updateAttribute, handleChangeAttribute } from '../../action/attribute';
import { hoverEnter, hoverLeave } from '../../action/user';
import { toggleSelect } from '../../action/view';
import * as Constants from '../../Constants';
import Modal from 'react-responsive-modal';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  handleChangeAttribute,
  updateAttribute,
  hoverEnter,
  hoverLeave,
  toggleSelect
};
class EditAttributeForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      dataId: null
    };
  }

  handHoverEnter(e) {
    e.preventDefault();
    this.props.hoverEnter(e.currentTarget.getAttribute('data-id'));
  }

  handHoverLeave(e) {
    e.preventDefault();
    this.props.hoverLeave();
  }

  handleChange(e) {
    e.preventDefault();
    // get current props
    let attribute = { ...this.props.attributeView.attribute };
    const currentField = e.currentTarget.getAttribute('data-field');

    if (currentField === 'attribute_options') {
      const id = e.currentTarget.getAttribute('data-id');
      attribute[currentField][id].option = e.currentTarget.value
    } else {
      attribute[currentField] = e.currentTarget.value;
    }
    this.props.handleChangeAttribute(attribute);
  }

  toggleSelect(e) {
    e.preventDefault();
    this.props.toggleSelect(e.currentTarget.getAttribute('data-id'));
  }

  upsertAttribute(e) {
    e.preventDefault();
    this.props.updateAttribute(this.props.attributeView.attribute);
  }

  addOptionToAttribute(e) {
    e.preventDefault();
    let options = Object.values({ ...this.props.attributeView.attribute.attribute_options });
    let blankOption = {
      attribute_id: '',
      created_at: '',
      option: 'Option',
      updated_at: ''
    }
    options.push(blankOption)
    this.props.attributeView.attribute.attribute_options = options;
    this.props.hoverEnter(e.currentTarget.getAttribute('data-id'));
  }

  onOpenModal = (e) => {
    this.setState({ open: true, dataID: e.currentTarget.getAttribute('data-id') })
  };

  onCloseModal = () => { this.setState({ open: false }) };

  handArchive(e) {
    let options = Object.values({ ...this.props.attributeView.attribute.attribute_options });
    const currentOptionId = e.currentTarget.getAttribute('data-id');
    options.find((option) => parseInt(option.id) === parseInt(currentOptionId)).archived = (e.currentTarget.getAttribute('option').toLowerCase() === 'true');
    let attribute = { ...this.props.attributeView.attribute };
    attribute['attribute_options'] = options;
    this.props.updateAttribute(attribute);
    this.onCloseModal();
  }

  render() {
    const user = JSON.parse(localStorage.getItem('user')).user;
    const errors = this.props.attributeView.errors;
    const server_errors = this.props.attributeView.server_errors;
    const server_error_str = server_errors ? server_errors.map((error, key) =>
      <div className="error-img-div" key={key}>
        <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
      </div>
    ) : "";

    const clubList = this.props.attributeView.attribute.branches;
    const clubCount = clubList.length;
    const clubStr = clubList.map((club, key) => {
      return (
        (key < 1) ? <div className="image-circle inline" key={key}>{club.name}</div> : ""
      )
    }
    );

    // the activities that an attribute belongs to ALREADY
    const activityList = this.props.attributeView.attribute.activities;
    const activityCount = activityList.length;
    const activityStr = activityList.map((activity, key) => {
      return (
        (key < 3) ? <div key={key} className="image-circle inline"><img className="img small" src={activity.image_url} alt={activity.name} /></div> : ""
      )
    }
    );

    const attribute_options = this.props.attributeView.attribute.attribute_options;
    const optionsStr = attribute_options.map((option, key) => {
      return (
        <div key={key} className='flex'>
          {!option.archived &&
            <>
              <input data-id={key} className="narrow" placeholder="Options" name="attribute_options[]" data-field="attribute_options" onChange={this.handleChange.bind(this)} value={option.option} disabled={user.role_id >= Constants.GUEST}></input>
              <div className='ml-auto self-center '>
                {user.role_id < Constants.GUEST ?
                  <button onClick={this.onOpenModal.bind(this)} className='deleteButton cursor-pointer' data-id={option.id}>Archive</button>
                  : ""}
              </div>
              <Modal open={this.state.open && parseInt(this.state.dataID) === parseInt(option.id)} onClose={this.onCloseModal.bind(this)} center>
                <div className="userModal">
                  <p>Are you sure you want to archive</p>
                  <p>{option.option}</p>
                  <div className="center">
                    <button className="yesButton" data-id={option.id} option='true' onClick={this.handArchive.bind(this)}>Yes</button>
                    <button className="cancelButton" onClick={this.onCloseModal.bind(this)}>Cancel</button>
                  </div>
                </div>
              </Modal>
            </>
          }
        </div>
      )
    });

    const archivedStr = attribute_options.map((option, key) => {
      return (
        <div key={key} className='flex'>
          {option.archived === 1 &&
            <>
              <input data-id={key} className="narrow" placeholder="Options" name="attribute_options[]" data-field="attribute_options" onChange={this.handleChange.bind(this)} value={option.option} disabled={user.role_id >= Constants.GUEST}></input>
              <div className='ml-auto self-center '>
                {user.role_id < Constants.GUEST ?
                  <button onClick={this.onOpenModal.bind(this)} className='deleteButton cursor-pointer' data-id={option.id}>Restore</button>
                  : ""}
              </div>
              <Modal open={this.state.open && parseInt(this.state.dataID) === parseInt(option.id)} onClose={this.onCloseModal.bind(this)} center>
                <div className="userModal">
                  <p>Are you sure you want to restore</p>
                  <p>{option.option}</p>
                  <div className="center">
                    <button className="yesButton" data-id={option.id} option='false' onClick={this.handArchive.bind(this)}>Yes</button>
                    <button className="cancelButton" onClick={this.onCloseModal.bind(this)}>Cancel</button>
                  </div>
                </div>
              </Modal>
            </>
          }
        </div>
      )
    });

    return (
      <div className="upsert-one-element-div normal-box normal-box-margin float-left width320px">
        <div className="upsert-one-element" >
          {server_errors ?
            <div className="error-div">
              {server_error_str}
            </div> : ""}
          <h1>Edit Attribute</h1>
          <div className="field-group">
            <div className="field with-arrow" data-id="branch" onClick={user.role_id < Constants.GUEST ? this.toggleSelect.bind(this) : ""}>
              <label className="bold">Clubs</label>
              <div>
                {clubCount > 0 ? clubStr : "No assigned clubs"}
                <div className="more inline">{clubCount > 1 ? " +" + (clubCount - 1) + " More" : ""}</div>
              </div>
              <div className="absolute arrow-div">
                <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
              </div>
            </div>
            <div className="field with-arrow" data-id="activity" onClick={user.role_id < Constants.GUEST ? this.toggleSelect.bind(this) : ""}>
              <label className="bold">Activities</label>
              <div>
                {activityCount > 0 ? activityStr : "No assigned activities"}
                <div className="more inline">{activityCount > 3 ? " +" + (activityCount - 3) + " More" : ""}</div>
              </div>
              <div className="absolute arrow-div">
                <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
              </div>
            </div>
            <div className="field">
              <div className="flex">
                <label className="bold">Question</label>
                {(this.props.attributeView.attribute.attribute_type_id === 1 || this.props.attributeView.attribute.attribute_type_id === 2) &&
                  <div onClick={this.addOptionToAttribute.bind(this)} className="addButton cursor-pointer" data-field="new-option">
                    <span className='add'>+</span>
                    <span className="child-text">Add Option</span>
                  </div>
                }
              </div>
              <input className="narrow" placeholder="Question" data-field="title" onChange={this.handleChange.bind(this)} value={this.props.attributeView.attribute.title} disabled={user.role_id >= Constants.GUEST}></input>
            </div>
            {
              attribute_options.length > 0 ?
                <>
                  <div className="field-group">
                    <label className="bold">Options</label>
                    {optionsStr}
                  </div>
                  <div className="field-group">
                    <label className="bold">Archived</label>
                    {archivedStr}
                  </div>
                </>
                : ''
            }

          </div>
          <button className={server_errors || (errors && Object.keys(errors).length > 2) ? "red-white-button not-ab" : "red-white-button"} onClick={this.upsertAttribute.bind(this)}>Update</button>
        </div>

      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditAttributeForm);
