import React from 'react';

class PlaceholderReportCard extends React.Component {
    render() {
        let timeBar = 3;

        if (this.props.type === 2 || this.props.type === 3) {
            timeBar = 5;
        }

        let resultBar = [];

        for (let i = 0; i < timeBar; i++) {
            resultBar.push(<div key={i}><div className="textLoad big quarter" /><div className="textLoad bigMargin" /></div>);
        }

        return (
            <div className="loaderContainer" style={{padding: '30px 20px', maxWidth: '273px', width: '100%'}}>
                <div className={this.props.type === 2 || this.props.type === 3 ? 'radialCircle hide' : 'radialCircle'}/>
                {resultBar}
            </div>
        )
    }
}

export default PlaceholderReportCard;
