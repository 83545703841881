import React from 'react';
import { connect } from "react-redux";
import * as Constants from '../../Constants';
import { pageChangeWithNoStateChange, pageChange } from '../../action/view';
import { Link } from "react-router-dom";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageChange,
    pageChangeWithNoStateChange
};
class AddSuccess extends React.Component {
    returnToActivities(e){
        this.props.pageChange(Constants.ACTIVITIES);
    }

    editActivityButton(e){
        this.props.pageChangeWithNoStateChange(Constants.ACTIVITIES);
    }


    render() {
        return (
            <div className="add-user-div normal-box float-left">
                <div className="add-user normal-margin center">
                    <img alt="Pistive Vote" src="/img/positive-vote.svg" className="postive-vote"></img>
                    <p>{this.props.activityView.message ? this.props.activityView.message: ""}</p>
                    <Link to="/activities" className="white-red-button" onClick={this.editActivityButton.bind(this)}>Edit Activity</Link>
                    <br/>
                    <Link to="/activities" onClick={this.returnToActivities.bind(this)}><img src="/img/arrow-left-red.svg" alt="Arrow Left" className="arrow"></img>Return to activities</Link>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSuccess);
