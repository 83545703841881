import React from 'react';
import { connect } from "react-redux";
import { updateModal } from '../../action/user';
import { pageChange } from '../../action/view';
import * as Constants from '../../Constants';
import { toggleSelect } from '../../action/view';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageChange,
    updateModal,
    toggleSelect
};
class BranchList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            date: 0,
            end_date: 0,
            activity_type: 0
        }
    }


    onDateChange(date, e) {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        this.setState({
            ...this.state,
            date: date,
        });
        var moment = require('moment-timezone');
        var local = moment.tz(date.toISOString(), "Australia/Brisbane");

        this.props.onDateChange(local.format());

    }

    onEndDateChange(date, e) {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        this.setState({
            ...this.state,
            end_date: date,
        });
        var moment = require('moment-timezone');
        var local = moment.tz(date.toISOString(), "Australia/Brisbane");

        this.props.onEndDateChange(local.format());

    }

    onCloseClick() {
        this.setState({
            ...this.state,
            date: 0,
            end_date: 0
        });
        this.props.onDateChange(0);
        this.props.onEndDateChange(0);
    }

    onActivtyChange(e) {
        this.setState({
            ...this.state,
            activity_type: parseInt(e.currentTarget.value)
        });
        this.props.onActivityChange(parseInt(e.currentTarget.value));
    }

    handBranchChange(e) {
        e.preventDefault();
        this.setState({
            id: parseInt(e.currentTarget.getAttribute('data-id'))
        })
        this.props.onBranchChange(e.currentTarget.getAttribute('data-id'))
    }

    openTypePopup() {
        this.props.toggleSelect('popup');
    }

    onPageChange(e) {
        this.props.pageChange(e.currentTarget.getAttribute('data-page'));
        this.props.toggleSelect();
    }

    render() {
        const activityList = this.props.activityView.activityList.map((activity, key) => {
            return (<option value={activity.id} key={key}>{activity.name}</option>)
        });
        const user = JSON.parse(localStorage.getItem('user')).user;
        const branchList = this.props.branchView.branchList;
        let myCount = 0;

        branchList.map((branch, key) => {
            myCount += branch.my_activity_session_count;
            return null;
        });
        const branches = branchList.map((branch, key) => {
            return (
                branch.activity_session_count ?
                    <a href="#1" key={branch.id} data-id={branch.id} onClick={this.handBranchChange.bind(this)}>
                        <div className={"branch-name " + (this.state.id === branch.id ? "active" : "")}>
                            <div className="left">{branch.name}</div>
                            <span className="float-right">{branch.activity_session_count}</span>
                        </div>
                    </a> : "");
        });
        return (
            <div className="branch-div float-left ab session-branch-datepicker">

                <DatePicker
                    selected={this.state.date}
                    onChange={this.onDateChange.bind(this)}
                    value={this.state.date ? this.state.date : 'Search Date'}
                    dateFormat={'dd/MM/yyy'}
                />
                {this.state.date ? 
                    <DatePicker
                    selected={this.state.end_date}
                    onChange={this.onEndDateChange.bind(this)}
                    value={this.state.end_date ? this.state.end_date : 'End Date (Optional)'}
                    dateFormat={'dd/MM/yyy'}
                /> : <React.Fragment/>
                }
                <img src='/img/image-close-black.svg' alt="Close" className="image-close clickable" onClick={this.onCloseClick.bind(this)} />
                <div className="field">
                    <select className="inline" onChange={this.onActivtyChange.bind(this)}>
                        <option value={0}>Search Activity</option>
                        {activityList}
                    </select>
                </div>
                <a href="#1" onClick={this.handBranchChange.bind(this)} data-id="0">
                    <div className={"branch-name " + (this.state.id === 0 ? "active" : "")}>
                        All Sessions
                      <span className="float-right">{this.props.sessionView.total_count}</span>
                    </div>
                </a>
                <div className="single-branch">
                    {branches}
                </div>
                <a href="#1" onClick={this.handBranchChange.bind(this)} data-id="-1">
                    <div className={"branch-name " + (this.state.id === -1 ? "active" : "")}>
                        My Sessions
                      <span className="float-right">{myCount}</span>
                    </div>
                </a>
                {user.role_id < Constants.GUEST ?
                    <button className="white-red-button" onClick={this.openTypePopup.bind(this)}>Log Session</button>
                    : ""}
                {this.props.root.overlay && this.props.root.view_type === 'popup' ?
                    <div className="type-popup">
                        <Link to="/add/sessions/direct" className="white-blue-button" onClick={this.onPageChange.bind(this)} data-page={Constants.ADD_DIRECT_SESSION}>Direct</Link>
                        <Link to="/add/sessions/indirect" className="blue-white-button" onClick={this.onPageChange.bind(this)} data-page={Constants.ADD_INDIRECT_SESSION}>Indirect</Link>
                    </div>
                    : ""}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BranchList);
