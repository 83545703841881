import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, BarChart, Bar, Tooltip, ResponsiveContainer } from 'recharts';
import {CustomTooltip, CustomizedAxisTick} from "../dashboard/MyChart";
import { connect } from "react-redux";
import { sizeForPrint } from '../../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    sizeForPrint
};
class YearChart extends React.Component {
    render() {
        let data = this.props.data;
        let dummyData = [
            {
                'total': 1000, 'name': 1
            }
        ]

        return (
            <div style={{height: '170px', 'position': 'relative'}}>
                <div className="backgroundChart">
                    <ResponsiveContainer height="100%" width={"100%"}>
                        <BarChart data={dummyData}
                            margin={{ top: 7, bottom: 0, left: 0, right: 0 }}
                        >
                            <YAxis tickCount={3} hide={true} />
                            <XAxis dataKey="name" axisLine={false} tick={false} tickLine={false} />
                            <CartesianGrid vertical={false} stroke="rgba(112,112,112, 0.2)" />
                            <Bar dataKey="total" fill="transparent" maxBarSize={20} radius={[0, 0, 0, 0]}></Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                <ResponsiveContainer height="100%" width={"100%"}>
                    <LineChart
                        data={data}
                        margin={{
                            top: 7, right: 12, left: 12, bottom: 0,
                        }}
                    >
                        <YAxis tickCount={3} hide={true} />
                        <XAxis dataKey="name" axisLine={false} tickLine={false} tick={<CustomizedAxisTick />}/>
                        <Tooltip content={CustomTooltip} cursor={{ stroke: '#2F2F2F', strokeWidth: 1, strokeDasharray: '2 5' }}/>
                        <Line type="monotone" dataKey="total" stroke="#0074BB" strokeWidth={3} activeDot={{ strokeWidth: 0, r: 6.5}} dot={false}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(YearChart);