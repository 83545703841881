import React from 'react';
import { connect } from "react-redux";
import { pageChange } from '../../action/view';
import AddBranchForm from './AddBranchForm';
import AddSuccess from './AddSuccess';
import SelectUser from './SelectUser';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  pageChange
};

class AddBranch extends React.Component {
  render() {
    return (
      <div className="AddBranch">
        {
          this.props.branchView.add_success ? <AddSuccess></AddSuccess> : <AddBranchForm></AddBranchForm>
        }
        {
          this.props.root.overlay ?  <SelectUser></SelectUser> : ""
        }
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBranch);
