import React from 'react';
import { connect } from "react-redux";
import * as Constants from '../../Constants';
import { changeFirstName, changeLastName, changeEmail, changePhone, changePassword, changeRole, createUser, fetchUserList, assignUserBranches } from '../../action/user';
import { fetchBranchList } from '../../action/branch';
import { toggleSelect, pageChange } from '../../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    changeFirstName,
    changeLastName,
    changeEmail,
    changePhone,
    changePassword,
    changeRole,
    createUser,
    fetchUserList,
    toggleSelect,
    pageChange,
    fetchBranchList,
    assignUserBranches
};
class EditUser extends React.Component {

    constructor(props) {
        super(props);
        if (this.props.branchView.branchList.length === 0)
            this.props.fetchBranchList();
    }

    handleChangeRole(e) {
        e.preventDefault();
        this.props.changeRole(e.currentTarget.getAttribute('data-id'));
    }

    handleChangeFirstName(e) {
        e.preventDefault();
        this.props.changeFirstName(e.currentTarget.value);
    }

    handleChangeLastName(e) {
        e.preventDefault();
        this.props.changeLastName(e.currentTarget.value);
    }

    handleChangeEmail(e) {
        e.preventDefault();
        this.props.changeEmail(e.currentTarget.value);
    }

    handleChangePhone(e) {
        e.preventDefault();
        this.props.changePhone(e.currentTarget.value);
    }

    handleChangePassword(e) {
        e.preventDefault();
        this.props.changePassword(e.currentTarget.value);
    }

    toggleSelect(e) {
        e.preventDefault();
        this.props.toggleSelect();
    }

    handleSelectAll(e) {
        this.props.assignUserBranches([], true);
    }

    handleCreateButton(e) {
        e.preventDefault();
        this.props.createUser(this.props.userView.user);
    }

    render() {
        const loginUser = JSON.parse(localStorage.getItem('user')).user;
        const errors = this.props.userView.errors;
        const server_errors = this.props.userView.server_errors;
        const server_error_str = server_errors ? server_errors.map((error, key) =>
            <div className="error-img-div" key={key}>
                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
            </div>
        ) : "";
        const user = this.props.userView.user;

        const branchStr = user.all_branches ? 
                "All" 
            : 
                (user.branch && user.branch.length > 0 ? 
                    user.branch.map(branch => branch.name).join(', ') 
                : 
                    "No clubs Selected");

        return (
            <div className="add-user-div normal-box float-left">
                <div className="add-user normal-margin">
                    {server_errors ?
                        <div className="error-div">
                            {server_error_str}
                        </div> : ""}
                    <div className="field-group">
                        <div className="field">
                            <input placeholder="First Name" type="text" value={user.first_name ? user.first_name : ""} onChange={this.handleChangeFirstName.bind(this)}></input>
                        </div>
                        {errors && errors.first_name ?
                            <div className="error-img-div float-right">
                                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.first_name}
                            </div> : ""}
                        <div className="field">
                            <input placeholder="Last Name" type="text" value={user.last_name ? user.last_name : ""} onChange={this.handleChangeLastName.bind(this)}></input>
                        </div>
                        {errors && errors.last_name ?
                            <div className="error-img-div float-right">
                                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.last_name}
                            </div> : ""}
                    </div>
                    <div className="field-group">
                        <div className="field">
                            <input placeholder="Email Address" type="email" value={user.email ? user.email : ""} onChange={this.handleChangeEmail.bind(this)}></input>
                        </div>
                        {errors && errors.email ?
                            <div className="error-img-div float-right">
                                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.email}
                            </div> : ""}
                        <div className="field">
                            <input placeholder="Phone Number" type="text" value={user.phone ? user.phone : ""} onChange={this.handleChangePhone.bind(this)}></input>
                        </div>
                    </div>

                    <div className="field-group">
                        <div className="field">
                            <label><span>Clubs</span></label>
                            <div className="role">
                                <div className={"role-div inline" + (user.branch && user.branch.length > 0 ? " active" : "")} onClick={this.toggleSelect.bind(this)}>Specific Clubs</div>
                                {loginUser.role_id < Constants.ADMIN && <div className={"role-div inline" + (user.all_branches ? " active" : "")} onClick={this.handleSelectAll.bind(this)}>All Clubs</div>}
                            </div>
                            <div>{branchStr}</div>
                        </div>
                        <div className="field">
                            <label><span>Role</span></label>
                            <div className="role">
                                {loginUser.role_id <= Constants.SUPER_ADMIN ?
                                    <div
                                        className={user.role_id === Constants.SUPER_ADMIN ? 'role-div inline active' : 'role-div inline'}
                                        data-id={Constants.SUPER_ADMIN}
                                        onClick={this.handleChangeRole.bind(this)}>
                                        Super Admin
                            </div>
                                    : ""}
                                {loginUser.role_id < Constants.ADMIN ?
                                    <div
                                        className={user.role_id === Constants.ADMIN ? 'role-div inline active' : 'role-div inline'}
                                        data-id={Constants.ADMIN}
                                        onClick={this.handleChangeRole.bind(this)}>
                                        Admin
                            </div>
                                    : ""}
                                {loginUser.role_id < Constants.BRANCH_MANAGER ?
                                    <div
                                        className={user.role_id === Constants.BRANCH_MANAGER ? 'role-div inline active' : 'role-div inline'}
                                        data-id={Constants.BRANCH_MANAGER}
                                        onClick={this.handleChangeRole.bind(this)}>
                                        Manager
                            </div>
                                    : ""}
                                <div
                                    className={user.role_id === Constants.BRANCH_OFFICER ? 'role-div inline active' : 'role-div inline'}
                                    data-id={Constants.BRANCH_OFFICER}
                                    onClick={this.handleChangeRole.bind(this)}>
                                    Officer
                            </div>
                            {loginUser.role_id !== Constants.BRANCH_MANAGER ?
                                <div
                                    className={user.role_id === Constants.GUEST ? 'role-div inline active' : 'role-div inline'}
                                    data-id={Constants.GUEST}
                                    onClick={this.handleChangeRole.bind(this)}>
                                    Guest
                            </div>
                            :""}
                            </div>
                        </div>
                        {errors && errors.role_id ?
                            <div className="error-img-div float-right">
                                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.role_id}
                            </div> : ""}
                    </div>
                    <button className="white-red-button ab" onClick={this.handleCreateButton.bind(this)}>Add</button>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditUser);
