function diff_seconds(dt2, dt1) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    return Math.abs(Math.round(diff));
}

export function timeDifference(createdAt) {
    let options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit"
    };

    let timeCreated = new Date(createdAt);

    let timeDifference = diff_seconds(timeCreated, new Date());
    let timeDisplay = timeCreated.toLocaleString('en-au', options);

    if (timeDifference < 3600) {

        // check if the time is less than a minute
        if (timeDifference < 60) {
            timeDisplay = Math.floor(timeDifference) + ' seconds ago';
        } else {
            timeDisplay = Math.floor(timeDifference / 60) + ' minutes ago';
        }
    }

    return timeDisplay;
}

export function formatDeliveryDate(date) {
    let options = {
        month: "short",
        day: "numeric",
        year: "numeric",
        weekday: "long"
    };

    let timeCreated = new Date(date);
    let timeDisplay = timeCreated.toLocaleString('en-au', options);

    return timeDisplay;
}

export function formatDeliveryDateHourMinute(date) {
    let options = {
        hour: "numeric",
        minute: "numeric",
        hour12: false
    };

    let timeCreated = new Date(date);
    let timeDisplay = timeCreated.toLocaleString('en-au', options);

    return timeDisplay;
}

export function getPeriods(currentPeriod) {
    let yearQuarter = currentPeriod.split(' Q');
    let period = [currentPeriod];
    let year = yearQuarter[0];
    let quarter = parseInt(yearQuarter[1]);
    let int = 0;
    while (int < 3) {
        if (quarter === 4) {
            year++;
            quarter = 1
        } else {
            quarter++;
        }
        period.push(year + ' Q' + quarter)
        int++;
    }
    return period;
}


export function getDateRange(key) {
    var date = new Date();

    var startDate;
    var endDate;
    switch (parseInt(key)) {
        case 0:
            endDate = date;
            startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 27)
            return [
                ("0" + startDate.getDate()).slice(-2) + '-' + ("0" + (startDate.getMonth() + 1)).slice(-2) + '-' + startDate.getFullYear(),
                ' to ',
                ("0" + endDate.getDate()).slice(-2) + '-' + ("0" + (endDate.getMonth() + 1)).slice(-2) + '-' + endDate.getFullYear()
            ]
        case 1:
            if (date.getMonth() > 5) {
                endDate = '31-12-' + date.getFullYear();
                startDate = '01-07-' + date.getFullYear();
            } else {
                endDate = '30-06-' + date.getFullYear();
                startDate = '01-01-' + date.getFullYear();
            }
            return [
                startDate,
                ' to ',
                endDate
            ]
        case 2:
            if (date.getMonth() > 5) {
                endDate = '30-06-' + date.getFullYear();
                startDate = '01-01-' + date.getFullYear();
            } else {
                endDate = '31-12-' + (date.getFullYear() - 1);
                startDate = '01-07-' + (date.getFullYear() - 1);
            }
            return [
                startDate,
                ' to ',
                endDate
            ]
        case 3:
            endDate = '01-01-' + date.getFullYear();
            startDate = '31-12-' + date.getFullYear();
            return [
                startDate,
                ' to ',
                endDate
            ]
        case 4:
            endDate = '01-01-' + (date.getFullYear() - 1);
            startDate = '31-12-' + (date.getFullYear() - 1);
            return [
                startDate,
                ' to ',
                endDate
            ]
        default:
            return []
    }

}

export function getDateInAuFormat(date) {
    return date ? ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear() : date
}

export function getDateInUsFormat(date) {
    return date ? date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2) : date
}