import React from 'react';
import { connect } from "react-redux";
import * as Constants from '../../Constants';
import { timeDifference } from '../../utils/date';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {

};
class SurveyInfo extends React.Component {

    renderSwitch(attribute) {
        let answer = [];
        let answersId = [];
        switch (attribute.attribute_type_id) {
            case 1: // multi select
                answersId = attribute.pivot.option_select_answer.split(',');
                answersId.forEach(id => {
                    if (attribute.attribute_options.find((x) => x.id === parseInt(id)))
                        answer.push(
                            attribute.attribute_options.find((x) => x.id === parseInt(id)).option
                        )
                    else
                        answer = ['Attribute Deleted']
                });
                return answer.join(', ');
            case 2: // single select
                answersId = attribute.pivot.option_select_answer.split(',');
                answersId.forEach(id => {
                    if (attribute.attribute_options.find((x) => x.id === parseInt(id)))
                        answer = attribute.attribute_options.find((x) => x.id === parseInt(id)).option;
                    else
                        answer = 'Attribute Deleted'
                });
                return answer;
            case 3: // yes no question
                answer = parseInt(attribute.pivot.true_false_answer) === 0 ? 'No' : 'Yes';
                return answer;
            case 4:
                answer = attribute.pivot.free_text_answer;
                return answer;
            default:
                return '';
        }
    }

    render() {
        const survey = this.props.surveyView.survey;

        const activityImg = survey.activity_type.image_url;
        //const genderImg = '/img/' + Constants.GENDER[survey.gender];
        //const ethImg = '/img/' + Constants.ETHNICITY[survey.ethnicity];
        const satisfactionImg = '/img/' + Constants.SATISFACTION[Math.floor(survey.satisfaction_level)];
        //const attendImg = '/img/' + Constants.TRUE_FALSE[survey.attended_before ? 'yes' : 'no'];
        //const returnImg = '/img/' + Constants.TRUE_FALSE[survey.is_intent_to_return ? 'yes' : 'no'];

        // 1 Jan 2019 9:00 AM
        let timeDisplay = timeDifference(survey.created_at);

        let userName = 'Deleted User';

        if (survey.user !== null) {
            userName = survey.user.first_name + ' ' + survey.user.last_name;
        }

        return (
            <div className="edit-user-div survey-info normal-box normal-box-margin float-left">
                <div className="edit-user normal-margin">
                    <div className="name surveyLine">
                        {userName}
                        <br />
                        <span>{timeDisplay} - {survey.branch.name}</span>
                    </div>
                    <hr />

                    <div className="activity surveyLine">
                        <div className="surveyLineTitle">
                            Activity
                        </div>
                        <div className="surveyLineValue">
                            <div className="imageContainer">
                                {activityImg ? <img src={activityImg} alt={survey.activity_type.name} />
                                    : <div className="user-logo-div inline">
                                        <div className="user-logo small">
                                            {survey.activity_type.abbreviation}
                                        </div>
                                    </div>}
                            </div>
                            <div className="valueContainer">{survey.activity_type.name}</div>
                        </div>
                    </div>

                    <div className="satisfaction surveyLine">
                        <div className="surveyLineTitle">
                            Satisfaction Level
                        </div>
                        <div className="surveyLineValue">
                            <div className="imageContainer"><img src={satisfactionImg} alt={survey.satisfaction_level} /></div>
                            <div className="valueContainer">{survey.satisfaction_level}</div>
                        </div>
                    </div>

                    {
                        survey.attribute_survey && survey.attribute_survey.length > 0 ? survey.attribute_survey.map((attribute, key) => {
                            return (
                                <div className="surveyLine" key={key}>
                                    <div className="surveyLineTitle">
                                        {attribute.title}
                                    </div>
                                    <div className="surveyLineValue">
                                        <div className="valueContainer">
                                            {this.renderSwitch(attribute)}
                                        </div>
                                    </div>
                                </div>
                            )
                        }) : ''
                    }
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SurveyInfo);
