import React from 'react';
import { connect } from "react-redux";
import { hoverEnter, hoverLeave } from '../../action/user';
import { fetchAWorkplan, deleteAWorkplan } from '../../action/workplan';
import Modal from 'react-responsive-modal';
import { timeDifference } from '../../utils/date';
import { toggleSelect } from '../../action/view';
import * as Constants from '../../Constants';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  hoverEnter,
  hoverLeave,
  fetchAWorkplan,
  deleteAWorkplan,
  toggleSelect
};
class ManageWorkplan extends React.Component {
  //test
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    // Detect when scrolled to bottom.
    this.refs.scroll.addEventListener("scroll", () => {
      if (
        this.refs.scroll.scrollTop + this.refs.scroll.clientHeight >=
        this.refs.scroll.scrollHeight
      ) {
        this.props.loadMore();
      }
    });
  }


  handHoverEnter(e) {
    e.preventDefault();
    this.props.hoverEnter(e.currentTarget.getAttribute('data-id'));
  }

  handHoverLeave(e) {
    e.preventDefault();
    this.props.hoverLeave();
  }

  handDelete(e) {
    e.preventDefault();
    this.onCloseModal();
    this.props.handleDelete(e.currentTarget.getAttribute('data-id'));
  }

  handleEdit(e) {
    e.preventDefault();
    this.props.fetchAWorkplan(e.currentTarget.getAttribute('data-id'))
  }

  onOpenModal = () => { this.setState({ open: true }) };
  onCloseModal = () => { this.setState({ open: false }) };

  openTypeExport() {
    this.props.toggleSelect('export', 'workplanReport');
  }

  render() {
    const user = JSON.parse(localStorage.getItem('user')).user;
    const workplan = this.props.workplanView.workplan;
    const workplanList = this.props.workplanView.workplanList;
    const workplans = workplanList.map((workplan, key) => {
      return (
        <a href="#1" key={workplan.id} data-id={workplan.id}
          className={this.props.workplanView.workplan.id && this.props.workplanView.workplan.id === workplan.id ? "active" : ""}
          onMouseEnter={this.handHoverEnter.bind(this)}
          onMouseLeave={this.handHoverLeave.bind(this)}
          onClick={this.handleEdit.bind(this)}>
          <div className="list-div">
            <div className="name inline">
              {workplan.branch.name + " | " + workplan.period}
              <br />
              <span>{timeDifference(workplan.updated_at) + " . " + workplan.user.first_name + " " + workplan.user.last_name}</span>
            </div>
            <div className={this.props.userView.hover_id === workplan.id ? 'button-div inline float-right' : 'button-div inline float-right display-none'}>
              {user.role_id < Constants.GUEST ?
                <>
                  <button onClick={this.onOpenModal.bind(this)} data-id={workplan.id}>Delete</button>
                </>
                : ""}
              {user.role_id < Constants.GUEST ?
                <button href="#1">Edit</button>
                : ""}
            </div>
            <div className={this.props.userView.hover_id === workplan.id ? 'arrow-div inline float-right display-none' : 'arrow-div inline float-right'}>
              <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
            </div>
          </div>
        </a>
      );
    });
    return (
      <div className="manage-div normal-box normal-box-margin-1 float-left" ref="scroll">
        <div className="manage-list" >
          <h1>Manage Program</h1>
          {user.role_id < Constants.GUEST ?
            <div className="clickable export-link ab" onClick={this.openTypeExport.bind(this)}>Export</div> : ""}
          {workplans}
        </div>
        <Modal open={this.state.open} onClose={this.onCloseModal.bind(this)} center>
          <div className="userModal">
            <p>Are you sure you want to delete this workplan</p>
            <div className="center">
              <button className="yesButton" data-id={workplan.id} onClick={this.handDelete.bind(this)}>Yes</button>
              <button className="cancelButton" onClick={this.onCloseModal.bind(this)}>Cancel</button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageWorkplan);
