import axios from 'axios';
import * as Constants from '../Constants';
// import { fetchingStart } from './view';
import { fetchBranchList } from './branch';
import { showInlineErrorMsg, showServerErrorMsg } from './common';

export const receiveWorkplanList = (data) => ({
    type: Constants.RECEIVE_WORKPLAN_LIST,
    workplanList: data.data.workplan,
    total_count: data.data.total_count
});

export const receiveWorkplanListBranch = (data) => ({
    type: Constants.RECEIVE_WORKPLAN_LIST_BRANCH,
    workplanList: data.data.workplan,
    total_count: data.data.total_count
});

function fetchWorkplanListAPI(branch_id = 0, start = 0, count = Constants.MAX_COUNT) {
    return dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        let user_id = ''
        if (branch_id === "-1") {
            user_id = JSON.parse(localStorage.getItem('user')).user.id;
            branch_id = '';
        }
        // dispatch(fetchingStart())
        return axios.get(Constants.API + 'work_plan/list?start=' + start + '&count=' + count + (branch_id ? '&branch_id=' + branch_id : '') + (user_id ? '&user_id=' + user_id : ''), axiosConfig)
            .then(response => response.data)
            .then(data => { if(start === 0) {dispatch(receiveWorkplanListBranch(data))} else {dispatch(receiveWorkplanList(data))}})
            .catch(function(error) {
                if (error.response?.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            })
    }
}

export function fetchWorkplanList(branch_id = null, start = 0, count = Constants.MAX_COUNT) {
    return (dispatch) => {
        return dispatch(fetchWorkplanListAPI(branch_id, start, count))
    }
}

export const fetchAWorkplan = (id) => ({
    type: Constants.FETCH_A_WORKPLAN,
    id: id
});

export const selectWorkplanBranch = (branch) => ({
    type: Constants.SELECT_WORKPLAN_BRANCH,
    branch: branch
});

export const selectWorkplanActivity = (activities) => ({
    type: Constants.SELECT_WORKPLAN_ACTIVITY,
    activities: activities
});

export const updateWorkplanActivityField = (field, id, order, value) => ({
    type: Constants.UPDATE_WORKPLAN_ACTIVITY_FIELD,
    field: field,
    id: id,
    order: order,
    value: value
});

export const selectWorkplanUser = (user, id, order) => ({
    type: Constants.SELECT_WORKPLAN_USER,
    user: user,
    id: id,
    order: order
});


function upsertWorkplanAPI(workplan, action = 'update') {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        try {
            // dispatch(fetchingStart())
            let response = await axios.post(Constants.API + 'work_plan/' + action, workplan, axiosConfig)
            const data = response.data;
            dispatch(action === 'update' ? receiveUpdateWorkplan(data) : receiveCreateWorkplan(data))
            if (action === 'update')
                alert('Successfully Updated')
        } catch (error) {
            if (error.response.data.error.code === 1) {
                localStorage.removeItem('user');
                window.location.reload();
            }
            dispatch(showServerErrorMsg(error.response.data))
        }
    }
}

export const receiveUpdateWorkplan = (data) => ({
    type: Constants.RECEIVE_UPDATE_WORKPLAN,
    workplan: data.data.workplan,
    message: data.data.message
});

export const receiveCreateWorkplan = (data) => ({
    type: Constants.RECEIVE_CREATE_WORKPLAN,
    workplan: data.data.workplan,
    message: data.data.message
});

export function upsertWorkplan(workplan, action = 'update') {
    let errors = {};

    var moment = require('moment-timezone');
    var date = new Date(Date.now());

    if (!workplan.start_date) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        workplan.start_date = moment.tz(date.toISOString(), "Australia/Brisbane").format()
    }
    if (!workplan.end_date) {
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        workplan.end_date = moment.tz(date.toISOString(), "Australia/Brisbane").format()
    }
    if (workplan.start_date > workplan.end_date) {
        errors.date = "End date must be later than start date";
    }
    if (!workplan.period) {
        errors.period = "Period is required";
    }
    if (!workplan.branch_id) {
        errors.branch = "Branch is required";
    }

    if (workplan.work_plan_activity) {
        for (let i = 0; i < workplan.work_plan_activity.length; i++) {
            let workplan_activity = workplan.work_plan_activity[i]
            if (!workplan_activity.deleted) {
                if (!workplan_activity.age) {
                    if (!errors.age) {
                        errors.age = [];
                    }
                    errors.age.push("Target age is required for " + workplan_activity.activity_type.name)
                } else {
                    let ageArr = [];
                    workplan_activity.age.forEach(age => {
                        ageArr.push(age.id);
                    });
                    workplan_activity.target_age = ageArr;
                }

                if (!workplan_activity.target_gender) {
                    if (!errors.target_gender) {
                        errors.target_gender = [];
                    }
                    errors.target_gender.push("Target gender is required for " + workplan_activity.activity_type.name)
                }

                if (!workplan_activity.occurrence) {
                    if (!errors.occurrence) {
                        errors.occurrence = [];
                    }
                    errors.occurrence.push("Occurrence is required for " + workplan_activity.activity_type.name)
                }

                if (!workplan_activity.delivery_duration) {
                    if (!errors.delivery_duration) {
                        errors.delivery_duration = [];
                    }
                    errors.delivery_duration.push("Delivery duration is required for " + workplan_activity.activity_type.name)
                }

                if (!workplan_activity.assign_officer) {
                    if (!errors.assign_officer) {
                        errors.assign_officer = [];
                    }
                    errors.assign_officer.push("Officer is required for " + workplan_activity.activity_type.name)
                }

                if (!workplan_activity.overhead_duration) {
                    workplan.work_plan_activity[i].overhead_duration = 0
                }

                if (!workplan_activity.venue) {
                    workplan.work_plan_activity[i].venue = ""
                }
            }
        }
    } else {
        errors.activity = "At least one activity is required";
    }



    if (Object.keys(errors).length > 0) {
        return (dispatch) => {
            return dispatch(showInlineErrorMsg(errors))
        }
    } else {
        return (dispatch) => {
            return dispatch(upsertWorkplanAPI(workplan, action))
        }
    }
}

function deleteAWorkplanAPI(workplan_id, branch_id, page) {

    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        // dispatch(fetchingStart())
        try {
            const response = await axios.get(Constants.API + 'work_plan/delete/' + workplan_id, axiosConfig);
            const data = response.data;
            dispatch(receiveDeleteAWorkplan(data));
            dispatch(fetchWorkplanList(branch_id, 0, page * Constants.MAX_COUNT));
            dispatch(fetchBranchList());
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

export const receiveDeleteAWorkplan = (data) => ({
    type: Constants.RECEIVE_DELETE_A_WORKPLAN,
    id: data.data.id
});


export function deleteAWorkplan(workplan_id = 0, branch_id = 0, page = 1) {
    return (dispatch) => {
        return dispatch(deleteAWorkplanAPI(workplan_id, branch_id, page))
    }
}