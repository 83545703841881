import React from 'react';
import FormStep from './FormStep';
import AddSuccess from './AddSuccess';
import { connect } from "react-redux";
import * as Constants from '../../Constants';
import { pageChange } from '../../action/view';
import { updateModal, createAttribute } from '../../action/attribute';
import Modal from 'react-responsive-modal';
import { withRouter } from "react-router-dom";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageChange,
    updateModal,
    createAttribute
};

const initialState = {
    currentStep: 1,
    branch_id: null,
    attached_to: null,
    attribute_type: null,
    activity_type_id: null,
    options: null,
    title: null
}
class AddAttributeForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = initialState;

        this.handleUpdateForm = this.handleUpdateForm.bind(this);
        this.handleNavigation = this.handleNavigation.bind(this);
        this.handleLogAnother = this.handleLogAnother.bind(this);
    }

    onCloseBlockModal(e) {
        let block = this.props.attributeView.block;
        this.props.updateModal({
            blockOpen: false,
            block_id: block.block_id,
            isBlocking: block.isBlocking,
            type: block.type
        })
    }

    handleUpdateForm(type, id) {
        if(type === 'branch_id' || type === 'activity_type_id') {
            if(Array.isArray(this.state[type])) {
                if(Array.isArray(id)) {  // all id
                    this.setState({
                        [type]: null
                    })
                } else {
                    id = parseInt(id);
                    if(this.state[type].length > 0) {
                        let currentType = [...this.state[type]];
                        if(currentType.includes(id)) {
                            currentType = currentType.filter((item) => item !== id);
                        } else {
                            currentType.push(id)
                        }
    
                        this.setState({
                            [type]: currentType
                        })
                    } else {
                        this.setState({ [type]: [id] });
                    }
                }
                
            } else {
                if(Array.isArray(id)) {
                    this.setState({ [type]: id});
                } else {
                    id = parseInt(id);
                    this.setState({ [type]: [id] });
                }
                
            }
        } else {
            this.setState({ [type]: id });
        }
        
        this.props.updateModal({
            isBlocking: true,
            blockOpen: false,
            block_id: Constants.ADD_ATTRIBUTE,
            type: 'page'
        });
    }

    handleNavigation(stepType, step) {
        let currentStep = this.state.currentStep;
        let nextStep = currentStep;

        switch(step) {
            case '+1':
                if (this.state[stepType] === '' || this.state[stepType] === null) {
                    alert('Please select a valid answer!');
                } else {
                    nextStep = currentStep + 1;
                    if(this.state.attached_to.toLowerCase() === 'social impact') {
                        if(nextStep === 4) {
                            nextStep += 1; // good news doesnt need activities
                        }
                    }
                    if (nextStep > Constants.MAX_ATTRIBUTE_STEP) {
                        //const date = new Date();
                        //const user = JSON.parse(localStorage.getItem('user')).user;

                        // clean up data    
                        let submitData = {
                            branch_id: this.state.branch_id,
                            attached_to: this.state.attached_to,
                            attribute_type_id: Constants.ATTRIBUTE_TYPE_DATABASE[this.state.attribute_type],
                            activities: this.state.activity_type_id,
                            options: this.state.options ? this.state.options.split(',') : [],
                            title: this.state.title,
                            description: ''
                        };

                        this.props.updateModal({
                            isBlocking: false,
                            blockOpen: false,
                            block_id: 0
                        });

                        // submit
                        this.props.createAttribute(submitData);

                        //nextStep = 1;
                        this.setState(initialState);
                    }
                }
                break;
            
            case '-1':
                nextStep = currentStep - 1;

                if(this.state.attached_to && this.state.attached_to.toLowerCase() === 'social impact') {
                    if(nextStep === 4) {
                        nextStep -= 1; // good news doesnt need activities
                    }
                }

                if (nextStep <= 0) {
                    // change page
                    if (this.props.attributeView.block.isBlocking) {
                        this.props.updateModal({
                            isBlocking: true,
                            blockOpen: true,
                            block_id: Constants.ATTRIBUTES,
                            type: 'page'
                        });

                        nextStep = currentStep;
                    } else {
                        this.props.pageChange(Constants.ATTRIBUTES);
                    };
                    this.props.history.push('/attributes');
                }
                break
            
            default:
                nextStep = currentStep;
                break;
        }

        this.setState({ currentStep: nextStep});
    }

    handleLogAnother() {
        this.setState({ currentStep: 1 });

        this.props.pageChange(Constants.ADD_ATTRIBUTE);
    }

    onHandlePageChange() {
        let block = this.props.attributeView.block;

        this.props.pageChange(block.block_id);
    }

    render() {
        let block = this.props.attributeView.block;

        const addAttributeForm = () => {
            return (
            <div>
                <FormStep mainState={this.state} currentStep={this.state.currentStep} onHandleNavigation={this.handleNavigation} onHandleUpdateForm={this.handleUpdateForm} />
                <Modal open={block.blockOpen} onClose={this.onCloseBlockModal.bind(this)} center>
                    <div className="userModal">
                        <p>You have not finished submitting the attribute</p>
                        <p>Are you sure you want to navigate away?</p>
                        <div className="center">
                            <button className="yesButton" onClick={this.onHandlePageChange.bind(this)}>Yes</button>
                            <button className="cancelButton" onClick={this.onCloseBlockModal.bind(this)}>Cancel</button>
                        </div>
                    </div>
                </Modal>
            </div>
            )
        }

        return (
            <div>
                {
                    this.props.attributeView.add_success ? <AddSuccess onLogAnother={this.handleLogAnother}></AddSuccess> : addAttributeForm()
                }
            </div>
        );
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AddAttributeForm));
