import React from 'react';
import HeaderFilter from '../dashboard/HeaderFilter';
import OverviewBanner from '../dashboard/OverviewBanner';
import PlaceholderOverviewBanner from '../dashboard/placeholder/PlaceholderOverviewBanner';
import * as Constants from '../../Constants';
import { connect } from "react-redux";
import { fetchOverviewData, fetchDaysSinceLastSession } from '../../action/overview';
import { fetchAllDashboard, sizeForPrint } from '../../action/view';
import { getDateRange } from '../../utils/date';
import CustomDateRange from "./CustomDateRange";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    fetchAllDashboard,
    fetchOverviewData,
    fetchDaysSinceLastSession,
    sizeForPrint
};

class Overview extends React.Component {

    constructor(props) {
        super(props);
        //this.props.fetchOverviewData();
        //this.props.fetchDaysSinceLastSession();
        this.handleOnClick = this.handleOnClick.bind(this);
        this.state = {
            startDate: '',
            endDate: ''
        }
    }

    handleOnClick(key, startDate = null, endDate = null) {
        console.log(key)
        this.props.fetchAllDashboard(key, this.props.root.dashboard_branch, startDate, endDate);
    }

    print(){
        this.props.sizeForPrint();
        setTimeout(function() {
            console.log(this)
            window.print();
            this.props.sizeForPrint();
        }.bind(this), 1500); 
    }


    render() {
        let data = [];
        let timeSelected = 0;

        if (this.props.overviewView) {
            data = this.props.overviewView.data.data;
            timeSelected = this.props.overviewView.timeSelected;
        }

        let location = 'All'

        if (this.props.root.dashboard_branch){
            location = this.props.branchView.branchList.filter(branch => branch.id === this.props.root.dashboard_branch)[0].name
        }


        return (
            <div className="overview reltv">
                {/* Filter headers */}
                <div className={"header" + (timeSelected === 5 ? ' active' : '')}>
                    <div className="headerTitle" style={{maxWidth: '450px'}}>
                        <h4>Overview - {location}</h4>
                    </div>

                    <HeaderFilter keySelected={timeSelected} isDropdown={false} isControlAll onClick={this.handleOnClick} />
                    <div className="print-button white-red-button" onClick={this.print.bind(this)}>Print</div>
                </div>
                
                {data.length > 0 ?
                    <div className="overviewContainer">
                        {data.map((filter, key) => {
                            if (filter.data !== undefined) {
                                return <OverviewBanner key={key} data={filter.data} total="" percentage="" title="" colorStart={Constants.OVERVIEW_LIST[key].colorStart} colorEnd={Constants.OVERVIEW_LIST[key].colorEnd} positive="" icon={Constants.OVERVIEW_LIST[key].icon} />
                            } else {
                                return <OverviewBanner key={key} data="" total={filter.total} percentage={filter.percentage} title={filter.name} colorStart={Constants.OVERVIEW_LIST[key].colorStart} colorEnd={Constants.OVERVIEW_LIST[key].colorEnd} positive={filter.positive} icon={Constants.OVERVIEW_LIST[key].icon} />
                            }
                        })}
                    </div>
                    :
                    /* Overview Components */
                    <div className="overviewContainer">
                        {Constants.OVERVIEW_LIST.map((filter, key) => {
                            return <PlaceholderOverviewBanner key={key} />
                        })}
                    </div>
                }
  
                {timeSelected === 5 ?
                    <CustomDateRange startDate={this.state.startDate} endDate={this.state.endDate}
                        handleOnClick={this.handleOnClick.bind(this)} position={'overview'}
                    ></CustomDateRange>
                    : <div className="report-card-date">{getDateRange(timeSelected)}</div>}
            </div>
        )

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Overview);
