import React from 'react';
import { connect } from "react-redux";
import ManageActivity from './ManageActivity';
import EditActivityForm from './EditActivityForm';
import { fetchActivityList } from '../../action/activity';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  fetchActivityList
};

class Activities extends React.Component {

  constructor(props) {
    super(props);
    this.props.fetchActivityList();
  }
  render() {
    return (
      <div className="Activities">
        <ManageActivity></ManageActivity>
        {this.props.activityView.activity.id ? <EditActivityForm></EditActivityForm> : ""}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Activities);
