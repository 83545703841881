import React from 'react';
import { connect } from "react-redux";
import { login, removeErrorMsg } from '../../action/user';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  login,
  removeErrorMsg
};

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    }
  }

  emailChange(e) {
    this.setState({
      email: e.currentTarget.value,
      password: this.state.password
    });
    this.props.removeErrorMsg();
  }

  passwordChange(e) {
    this.setState({
      email: this.state.email,
      password: e.currentTarget.value
    });
    this.props.removeErrorMsg();
  }

  handleClick(e) {
    this.props.login(this.state);
  }

  onRegisterClick() {
    this.props.removeErrorMsg();
    this.props.onRegisterClick();
    window.location.href = "/signup";
  }

  onForgetPasswordClick(e) {
    e.preventDefault();
    this.props.removeErrorMsg();
    this.props.onForgetPasswordClick();
    window.location.href = "/forget_password";
  }

  render() {
    const errors = this.props.loginView.errors;
    const server_errors = this.props.loginView.server_errors;
    const server_error_str = server_errors ? server_errors.map((error, key) =>
      <div className="error-img-div" key={key}>
        <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
      </div>
    ) : "";
    return (
      <div className="Login login-system">
        <div className="left white-background">

          <div className="sign-in-box">
            <img src="/img/logo.svg" alt="User Logo" className="logo"></img>
            {server_errors ?
              <div className="error-div">
                {server_error_str}
              </div> : ""}
            <h1>Sign in to Songbird</h1>
            <h5>Sign in using your account detail</h5>
            <input name="email" type="email" placeholder="Email Address" onChange={this.emailChange.bind(this)}></input>
            {errors && errors.email ?
              <div className="error-img-div float-right">
                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.email}
              </div> : ""}
            <input password="password" type="password" placeholder="Password" onChange={this.passwordChange.bind(this)}></input>
            <div className="button-div">
              <a href="#1" className="inline" onClick={this.onForgetPasswordClick.bind(this)}>Forgotten Password?</a>
              <button onClick={this.handleClick.bind(this)} className="inline">Sign In</button>
            </div>
          </div>
          <p className="copyright">© PCYC Queensland</p>
        </div>
        <div className="right red-background">
          <h1>Hello Friend</h1>
          <h5>You can register as a guest</h5>
          <button onClick={this.onRegisterClick.bind(this)} className="inline">Register</button>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
