import axios from 'axios';
import * as Constants from '../Constants';
import * as Env from '../env';

export const receiveOverviewData = (data, timeSelected, startDate, endDate) => ({
    type: Constants.RECEIVE_OVERVIEW_DATA,
    data: data,
    timeSelected: timeSelected,
    startDate: startDate,
    endDate: endDate
});

const fetchingData = () => ({
    type: Constants.FETCHING_START + '_overview'
});

// fetch overview data for dashboard
function fetchOverviewDataAPI(time_id, branch_id, startDate = null, endDate = null) {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        //dispatch(fetchingData())
        try {
            const response = await axios.get(
                Env.API + 'dashboard/overview/' +
                (time_id ? parseInt(time_id) + 1 : 1) +
                (branch_id ? '?branch_id=' + branch_id : '') +
                (startDate ? (branch_id ? '&startDate=' : '?startDate=') + startDate : '') +
                (endDate ? '&endDate=' + endDate : ''), axiosConfig);
            const data = response.data;
            return dispatch(receiveOverviewData(data, time_id, startDate, endDate));
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

export function fetchOverviewData(time_id = 0, branch_id = 0, startDate = null, endDate = null) {
    if (time_id === 5 && !startDate && !endDate) {
        return (dispatch) => {
            return dispatch(receiveOverviewData({ data: [] }, time_id));
        }
    } else {
        return (dispatch) => {
            return dispatch(fetchOverviewDataAPI(time_id, branch_id, startDate, endDate))
        }
    }
}

const receiveDaysSinceLastSession = (data) => ({
    type: Constants.RECEIVE_DAYS_SINCE_LAST_SESSION,
    date: data.data
});

// fetch overview data for dashboard
function fetchDaysSinceLastSessionAPI() {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        dispatch(fetchingData())
        try {
            const response = await axios.get(Env.API + 'dashboard/daysSinceLastSession', axiosConfig);
            const data = response.data;
            return dispatch(receiveDaysSinceLastSession(data));
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

export function fetchDaysSinceLastSession() {
    return (dispatch) => {
        return dispatch(fetchDaysSinceLastSessionAPI())
    }
}