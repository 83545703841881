import * as Constants from '../Constants';
import * as ENV from '../env';

const root = (state = Constants.rootInitialState, action) => {
    switch (action.type) {
        case Constants.PAGE_CHANGE:
        case Constants.PAGE_CHANGE_WITH_NO_STATE_CHANGE:
            return {
                isFetching: false,
                page: action.page,
                overlay: state.overlay
            }
        case Constants.FETCHING_START:
            return {
                isFetching: true,
                page: state.page,
                overlay: state.overlay
            }
        case Constants.FETCHING_END:
            return {
                isFetching: false,
                page: state.page,
                overlay: state.overlay
            }
        case Constants.TOGGLE_SELECT:
            return {
                isFetching: false,
                page: state.page,
                overlay: !state.overlay,
                view_type: action.view_type,
                id: action.id,
                id2: action.id2,
                id3: action.id3
            }
        case Constants.RECEIVE_ME:
        case Constants.RECEIVE_LOGIN:
            localStorage.setItem('user', JSON.stringify(action.user));
            window.location.href = "/dashboard";
            return state;
        case Constants.RECEIVE_LOGOUT:
            localStorage.removeItem('user');
            if (localStorage.getItem('desktop')) {
                localStorage.removeItem('desktop')
                window.location.href = ENV.MOBILE_APP + "?action=redirect&view=logout"
            } else {
                window.location.reload();
            }

            return state;

        case Constants.CHANGE_DASHBOARD_BRANCH:
            return {
                ...state,
                dashboard_branch: action.dashboard_branch
            }
        case Constants.SIZE_FOR_PRINT:
            return {
                ...state,
                resize: !state.resize
            }
        default:
            return {
                ...state,
                isFetching: false
            }
    }
}

export default root;