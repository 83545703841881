import React from 'react';

class PlaceholderOverviewBanner extends React.Component {
    render() {
        let bgStyle = {
            background: '#fff',
            position: 'relative'
        };

        return (
            <div className="overviewBanner" style={bgStyle}>
                { /* <img src={icon} alt='loading' style={thumbStyle} /> */}
                <div className="loaderContainer" style={{ top: '50px', left: '20px', width: '100%', maxWidth: '190px'}}>
                    <div className="circleLoad"/>
                    <div className="textLoad big quarter"/>
                    <div className="textLoad half"/>
                </div>
            </div>
        )
    }
}

export default PlaceholderOverviewBanner;
