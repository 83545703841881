import React from 'react';
import { connect } from "react-redux";
import { updateModal } from '../../action/user';
import { pageChange } from '../../action/view';
import { fetchGoodNewsList, fetchGoodNewsPDF } from '../../action/goodNews';
import { toggleSelect } from '../../action/view';
import * as Constants from '../../Constants';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    fetchGoodNewsList,
    pageChange,
    updateModal,
    toggleSelect
};
class BranchList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            str:"",
            link: []
        }
    }

    search(e) {
        this.setState({
            ...this.state,
            str: e.currentTarget.value
        })
        this.props.search(e.currentTarget.value)
    }
    
    handBranchChange(e) {
        e.preventDefault();
        this.props.fetchGoodNewsList(null, e.currentTarget.getAttribute('data-id'), this.state.str);
        this.setState({
            ...this.state,
            id: parseInt(e.currentTarget.getAttribute('data-id'))
        })
        this.props.onBranchChange(e.currentTarget.getAttribute('data-id'))
    }

    openTypePopup() {
        this.props.toggleSelect('popup');
    }

    onPageChange(e) {
        this.props.pageChange(e.currentTarget.getAttribute('data-page'));
        this.props.toggleSelect();
    }

    async export() {
        
        if (this.props.goodNewsView.query_count > 30){
            this.props.toggleSelect('popup');
            let data = await fetchGoodNewsPDF(this.state.id, this.state.str)
            this.setState({
                ...this.state,
                link: data
            })
        }else{
            window.open(
                Constants.API + 'report/newsPdf?token=' + JSON.parse(localStorage.getItem('user')).access_token + '&branch_id='+this.state.id + '&str='+this.state.str,
                '_blank' // <- This is what makes it open in a new window.
            );
        }
      }

      closePopup() {
        this.setState({
            ...this.state,
            link: []
        })
        this.props.toggleSelect();
      }

    render() {
        const branchList = this.props.branchView.branchList;
        let myCount = 0;

        branchList.map((branch, key) => {
            myCount += branch.my_news_count;
            return null;
        });
        const branches = branchList.map((branch, key) => {
            return (
                branch.news_count ?
                    <a href="#1" key={branch.id} data-id={branch.id} onClick={this.handBranchChange.bind(this)}>
                        <div className={"branch-name " + (this.state.id === branch.id ? "active" : "")}>
                            <div className="left">{branch.name}</div>
                            <span className="float-right">{branch.news_count}</span>
                        </div>
                    </a> : "");
        });
        return (
            <div className="branch-div float-left ab">
                <div className="search-field">
                    <input placeholder="Search" onChange={this.search.bind(this)}></input>
                    <img src="/img/search-icon.svg" alt="Search Icon" className="search-icon"></img>
                </div>
                <br />
                <a href="#1" onClick={this.handBranchChange.bind(this)} data-id="0">
                    <div className={"branch-name " + (this.state.id === 0 ? "active" : "")}>
                        All Social Impacts
                      <span className="float-right">{this.props.goodNewsView.total_count}</span>
                    </div>
                </a>
                <div className="single-branch">
                    {branches}
                </div>
                <a href="#1" onClick={this.handBranchChange.bind(this)} data-id="-1">
                    <div className={"branch-name " + (this.state.id === -1 ? "active" : "")}>
                        My Social Impacts
                      <span className="float-right">{myCount}</span>
                    </div>
                </a>
                <div className="white-red-button" onClick={this.export.bind(this)}>Export News</div>
                {this.props.root.overlay && this.props.root.view_type === 'popup' ?
                    this.state.link.length > 0 ?
                    <div className="type-popup export-news">
                        {
                            this.state.link.map((li, key) =>
                            <a href={li} rel="noopener noreferrer" target="_blank"><p>{li}</p></a>
                            )
                        } 
                        <br />  
                        <button  className="white-blue-button" onClick={this.closePopup.bind(this)} >close</button>
                    </div>
                    : 
                    <div className="type-popup export-news">
                        <p>You are exporting {this.props.goodNewsView.query_count} news, please be patient and don't leave the page until you see the download link. Try to use the filter before exporting the news to reduce the waiting time</p>
                    </div>
                    : ""}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BranchList);
