import React from 'react';
import { connect } from "react-redux";
import { } from '../../action/user';
import { selectSessionBranch, selectSessionActivity, getCustomAttributeSession } from '../../action/session';
import { toggleSelect } from '../../action/view';
import * as Constants from '../../Constants';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    selectSessionActivity,
    selectSessionBranch,
    getCustomAttributeSession,
    toggleSelect
};
class SelectActivity extends React.Component {

    constructor(props) {
        super(props);
        let type = this.props.type
        this.state = type ? {
            searchActivities: this.props.activityView.activityList.filter(function (activity) {
                return activity.type === type;
            }),
            activities: this.props.activityView.activityList.filter(function (activity) {
                return activity.type === type;
            }),
            sessionActivity: this.props.sessionView.session.activity_type ? this.props.sessionView.session.activity_type : {}
        } : {
            searchActivities: this.props.activityView.activityList,
            activities: this.props.activityView.activityList,
            sessionActivity: this.props.sessionView.session.activity_type ? this.props.sessionView.session.activity_type : {}
        }
    }

    toggleSelect(e) {
        e.preventDefault();
        this.props.toggleSelect();
    }

    selectActivity(e) {
        if (Object.keys(this.state.sessionActivity).length > 0) {
            this.props.selectSessionActivity(this.state.sessionActivity);

            let branch_id = '';
            if (this.props.sessionView.session.branch_id) {
                branch_id = this.props.sessionView.session.branch_id
            }
            this.props.getCustomAttributeSession(branch_id, this.state.sessionActivity.id, Constants.ATTACHED_TO_TEXT[1])
        }
        this.props.toggleSelect();
    }

    search(e) {
        let searchActivities = []
        for (var i = 0; i < this.state.activities.length; i++) {
            let activityName = this.state.activities[i].name;
            if (activityName.toLowerCase().includes(e.currentTarget.value.toLowerCase())) {
                searchActivities.push(this.state.activities[i]);
            }
        }
        this.setState({
            searchActivities: searchActivities,
            activities: this.state.activities,
            sessionActivity: this.state.sessionActivity
        });
    }

    toggleOneBranch(e) {
        let activityId = e.currentTarget.getAttribute('data-id');

        let sessionActivity = {};
        for (var i = 0; i < this.state.activities.length; i++) {
            if (this.state.activities[i].id === parseInt(activityId)) {
                sessionActivity = this.state.activities[i];
                break;
            }
        }
        this.setState({
            searchActivities: this.state.searchActivities,
            activities: this.state.activities,
            sessionActivity: sessionActivity
        });

        if (Object.keys(sessionActivity).length > 0) {
            this.props.selectSessionActivity(sessionActivity);

            let branch_id = '';
            if (this.props.sessionView.session.branch_id) {
                branch_id = this.props.sessionView.session.branch_id
            }
            this.props.getCustomAttributeSession(branch_id, sessionActivity.id, Constants.ATTACHED_TO_TEXT[1])
        }
        this.props.toggleSelect();
    }

    render() {
        const activities = this.state.searchActivities.map((activity, key) => {
            let activityClass = '';
            if (activity.id === this.state.sessionActivity.id) {
                activityClass = 'active';
            }

            return (
                <div className="item-name" key={activity.id} data-id={activity.id}>
                    {activity.image_url ? <div className="image-circle inline"><img className="img" src={activity.image_url} alt={activity.name} /></div>
                        : <div className="user-logo-div inline">
                            <div className="user-logo">
                                {activity.abbreviation}
                            </div>
                        </div>}
                    <span className="inline">{activity.name}</span>
                    <div className={"inline colordot" + (activity.require_instructor ? " amber" : "") + (activity.require_indemnification ? " red" : "")}></div>
                    <div className="float-right round-checkbox activity" data-id={activity.id} onClick={this.toggleOneBranch.bind(this)}>
                        <div className={activityClass}></div>
                    </div>
                </div>
            );
        });
        return (
            <div className="session select-div normal-box normal-box-margin-2 step-div">
                <div className="normal-margin-heading" >
                    <h1 className="clickable" onClick={this.toggleSelect.bind(this)}><img src="/img/arrow-left.svg" alt="Arrow Left" className="arrow-left"></img>Select Activity</h1>
                    <div className="search-field">
                        <input placeholder="Search Activities" onChange={this.search.bind(this)}></input>
                        <img src="/img/search-icon.svg" alt="Search Icon" className="search-icon"></img>
                    </div>
                </div>
                <div className="normal-margin" >
                    {activities}
                    <button className="red-white-button" onClick={this.selectActivity.bind(this)}>Select</button>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectActivity);
