import React from 'react';
import HeaderFilter from '../dashboard/HeaderFilter';
import TimeBar from '../dashboard/TimeBar';
import MyChart from '../dashboard/MyChart';
import PlaceholderPerformance from './placeholder/PlaceholderPerformance';
import { connect } from "react-redux";
import { fetchPerformanceData } from '../../action/performance';
import { getDateRange } from '../../utils/date';
import CustomDateRange from "./CustomDateRange";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    fetchPerformanceData
};

class Performance extends React.Component {

    constructor(props) {
        super(props);
        //this.props.fetchPerformanceData();
        this.handleOnClick = this.handleOnClick.bind(this);
        this.state = {
            startDate: '',
            endDate: ''
        }
    }

    handleOnClick(key, startDate = null, endDate = null) {
        this.props.fetchPerformanceData(key, this.props.root.dashboard_branch, startDate, endDate);
        if (startDate && endDate) {
            this.setState({ 
                startDate: startDate,
                endDate: endDate
            })
        }
    }

    render() {

        let timeData = this.props.performanceView.timeData;
        let chartData = this.props.performanceView.data;

        let chartType = this.props.performanceView.timeSelected;



        return (
            <div className="performance reltv">
                <div className={"header" + (chartType === 5 ? " active" : "")}>
                    <div className="headerTitle" style={{maxWidth: '350px'}}>
                        <h4>Performance: Attendance</h4>
                    </div>

                    <HeaderFilter keySelected={chartType} isDropdown={false} onClick={this.handleOnClick} />
                </div>
                {timeData.length === 0 && chartData.length === 0 ?
                    <PlaceholderPerformance />
                    :
                    <div className="performanceCharts">
                        {/* Display Total Info For Months */}
                        <TimeBar data={timeData} type={chartType} />

                        {/* Performance Charts */}
                        <MyChart data={chartData} type={chartType} />
                    </div>
                }
                {chartType === 5 ?
                    <CustomDateRange startDate={this.state.startDate} endDate={this.state.endDate}
                        handleOnClick={this.handleOnClick.bind(this)} position={'performance'}
                    ></CustomDateRange>
                    : <div className="report-card-date">{getDateRange(chartType)}</div>}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Performance);

