import React from 'react';
import { connect } from "react-redux";
import { fetchBranchList } from '../../action/branch';
import { fetchActivityList } from '../../action/activity';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    fetchBranchList,
    fetchActivityList
};

class SelectFilterSurvey extends React.Component {
    constructor(props) {
        super(props);

        this.filterList = this.filterList.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
        
        switch(this.props.type) {
            case 'branch_id':
                this.props.fetchBranchList();
                break;
            case 'activity_type_id':
                this.props.fetchActivityList();
                break;
            default:
                break;
        }

        this.state = {
            items: [],
            initialItems: [],
            selected: this.props.currentSelected
        }
    }

    filterList(e) {
        let updatedList = this.props.surveyView.branchList;

        if (this.props.type === 'activity_type_id') {
            updatedList = this.props.activityView.activityList;
        }
        
        updatedList = updatedList.filter(function (item) {

            try {
                return item.name.toLowerCase().search(
                    e.currentTarget.value.toLowerCase()) !== -1;
            } catch(e) {
                return false
            }
            
        });
        
        this.setState({ items: updatedList });
    }

    handleOnClick(e) {
        this.setState({ selected: parseInt(e.currentTarget.value)});

        this.props.onHandleUpdateForm(this.props.type, e.currentTarget.value);
    }

    render() {
        let list = this.props.surveyView.branchList;

        if (this.props.type === 'activity_type_id') {
            list = this.props.activityView.activityList;
        }

        if(this.state.items.length > 0) {
            list = this.state.items;
        }
        const items = list.map((item, key) => {

            let img = '';
            if (item.image_url !== undefined && item.image_url !== '' && item.image_url !== null) {
                img = <img alt={item.name} src={item.image_url} className="activityIcon" />
            }

            if ((item.type === 'direct' || this.props.type === 'branch_id') && !item.deleted_at) {
                return (
                    <li className="branch-name" data-category={item.name} key={key}>
                        <label className="container">
                            {img ? img 
                            : item.abbreviation ? <div className="user-logo-div inline activityIcon">
                            <div className="user-logo">
                              {item.abbreviation}
                            </div>
                          </div> : ""}
                            {item.name}
                            <input type="radio" id={item.name} name="branch" value={item.id} onChange={this.handleOnClick} checked={item.id === parseInt(this.state.selected)} />
                            <span className="checkmark"></span>
                        </label>
                    </li>
                )
            } else {
                return null
            }
            
        });


        return (
            <div className="branchList">
                <input type="text" className="form-control form-control-lg" placeholder="Type your search here..." onChange={this.filterList} />
                <div className="groupContainer">
                    <ul className="branch-group">
                        {items}
                    </ul>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectFilterSurvey);
