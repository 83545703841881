import * as Constants from '../Constants';
import axios from 'axios';

export const receiveAllChart = (data) => ({ type: Constants.RECEIVE_ALL_CHART, charts: data.charts });
export const receiveCreateChart = (data) => ({ type: Constants.RECEIVE_CREATE_CHART, charts: data.charts, message: data.message });

// fetch overview data for chart
function fetchAllChartAPI() {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        // dispatch(fetchingData())
        try {
            const response = await axios.get(
                Constants.API + 'dashboard/getallchart', axiosConfig);
            const data = response.data;
            return dispatch(receiveAllChart(data.data));
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

// delete overview data for chart
export function deleteChart(id) {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        // dispatch(fetchingData())
        try {
            await axios.get(
                Constants.API + 'dashboard/delete_attribute/' + id, axiosConfig);
            window.location.reload();
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

function createChartApi(chart) {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        try {
            const response = await axios.post(Constants.API + 'dashboard/createchart', chart, axiosConfig)
            const data = response.data;
            dispatch(receiveCreateChart(data.data));
            alert(data.data.message);
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

export function fetchAllChart() {
    return (dispatch) => {
        return dispatch(fetchAllChartAPI())
    }
}

export function createChart(display_by, attributes) {
    let chart = {
        'display_by': display_by,
        'attribute_id': attributes
    }
    return (dispatch) => {
        return dispatch(createChartApi(chart))
    }
}