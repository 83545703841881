import React from 'react';
import * as Constants from '../../Constants';

class HeaderFilter extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    handleOnClick(key) {
        this.props.onClick(key);
    }

    handleOnChange(e) {
        this.props.onClick(e.currentTarget.value, null, null, this.props.attributeId);
    }

    render() {
        if (this.props.isDropdown === true) {
            return (
                <div className="headerFilter">
                    <select name="dateFilter" className="dropdownFilter" value={this.props.keySelected} onChange={this.handleOnChange} >
                        {Constants.FILTER_LIST.map((filter, key) => {
                            return <option value={key} key={key}>{filter}</option>
                        })}
                    </select>

                </div>
            );
        } else {
            return (
                <div className="headerFilter reltv">
                    {Constants.FILTER_LIST.map((filter, key) => {
                        let isSelected = '';

                        if (key === this.props.keySelected) {
                            isSelected = 'selected';
                        }

                        return <p className={isSelected} key={key} data-key={key} onClick={this.handleOnClick.bind(null, key)}>{filter}</p>
                    })}
                </div>
            );
        }
    }
}

export default HeaderFilter;
