import React from 'react';
import { connect } from "react-redux";
import { pageChange } from '../../action/view';
import AddSurveyForm from './AddSurveyForm';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageChange
};

class Surveys extends React.Component {
    render() {
        return (
            <div className="AddSurvey">
                <AddSurveyForm/>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Surveys);
