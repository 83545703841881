import React from 'react';
import SelectFilterAttribute from './SelectFilterAttribute';
import ArrowNavigation from './ArrowNavigation';
import SelectAttribute from './SelectAttribute';
import * as Constants from '../../Constants';
import { getDateInAuFormat } from '../../utils/date';
import DatePicker from "react-datepicker";

class FormStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startShow: 1
        }
    }

    onHandleChange(e) {
        this.props.onHandleUpdateForm(e.currentTarget.getAttribute('type'), e.currentTarget.value);
    }

    onStartDateChange(date) {
        if (this.props.mainState.endDate !== '' && date > this.props.mainState.endDate) {
            this.props.onHandleUpdateForm('endDate', '');
        }

        this.props.onHandleUpdateForm('startDate', date);
        this.setState({
            startShow: 0
        });
    }

    onEndDateChange(date) {
        if (date < this.props.mainState.startDate) {
            this.props.onHandleUpdateForm('endDate', '');
        } else {
            this.props.onHandleUpdateForm('endDate', date);
        }
        this.setState({
            startShow: 0
        });
    }

    startInputClick(e) {
        this.setState({
            startShow: 1
        });
    }

    endInputClick(e) {
        this.setState({
            startShow: 0
        });
    }

    render() {
        const stepName = () => {
            return (
                <div className="stepName">{'Step ' + this.props.currentStep + ' / ' + Constants.MAX_CUSTOM_REPORT_STEP}</div>
            )
        }

        const stepDetail = (title, key, isFilter, isFirst, isLast) => {
            let filter = [];
            if (isFilter === true) {
                filter = <SelectFilterAttribute currentState={this.props.mainState} currentSelected={this.props.mainState[key]} onHandleUpdateForm={this.props.onHandleUpdateForm} type={key} />;
            } else {
                let list = Constants[key.toUpperCase()];

                if (list !== undefined) {
                    if (key.toUpperCase() === 'DATE_RANGE') {
                        const startDate = this.props.mainState.startDate || this.props.mainState.startDate !== '' ? getDateInAuFormat(this.props.mainState.startDate) : 'DD-MM-YYYY'
                        const endDate = this.props.mainState.endDate || this.props.mainState.endDate !== '' ? getDateInAuFormat(this.props.mainState.endDate) : 'DD-MM-YYYY'

                        filter = <div className="datepicker-div">
                            <div className="input-div">
                                <div className=" reltv">
                                    <label>From Date:</label>
                                    <br />
                                    <input type="text" readOnly className={"date-picker" + (this.state.startShow ? ' active' : '')}
                                        value={startDate}
                                        onClick={this.startInputClick.bind(this)}></input>
                                    <img src='/img/calendar.svg' alt="calendar" className="calendar-svg" />
                                </div>
                                <div className=" reltv">
                                    <label>To Date:</label>
                                    <br />
                                    <input type="text" readOnly className={"date-picker" + (!this.state.startShow ? ' active' : '')}
                                        value={endDate}
                                        onClick={this.endInputClick.bind(this)}></input>
                                    <img src='/img/calendar.svg' alt="calendar" className="calendar-svg" />
                                </div>
                            </div>

                            <div className={"start-datepicker custom-datepicker" + (this.state.startShow ? ' active' : '')}>
                                <DatePicker
                                    selected={this.props.mainState.startDate}
                                    onChange={this.onStartDateChange.bind(this)}
                                    selectsStart
                                    startDate={this.props.mainState.startDate}
                                    endDate={this.props.mainState.endDate}
                                    inline
                                />
                            </div>
                            <div className={"end-datepicker custom-datepicker" + (!this.state.startShow ? ' active' : '')}>
                                <DatePicker
                                    selected={this.props.mainState.endDate}
                                    onChange={this.onEndDateChange.bind(this)}
                                    selectsEnd
                                    startDate={this.props.mainState.startDate}
                                    endDate={this.props.mainState.endDate}
                                    inline
                                />
                            </div>
                        </div>
                    } else {
                        filter = <SelectAttribute isActiveImage={Constants[key.toUpperCase() + '_ACTIVE'] ? true : false} currentSelected={this.props.mainState[key]} data={Object.entries(list)} onHandleUpdateForm={this.props.onHandleUpdateForm} type={key} />;
                    }

                } else {
                    if (key === 'options') {
                        filter = <><input className="center" placeholder={title} onChange={this.onHandleChange.bind(this)} key={key + 'title'} type='title' name={key} style={{ marginTop: '17px', marginBottom: '15px' }} value={this.props.mainState['title'] ? this.props.mainState['title'] : ""} /><textarea placeholder="option 1, option 2, option 3" onChange={this.onHandleChange.bind(this)} key={key + 'options'} type='options' name={key} style={{ marginTop: '17px', marginBottom: '15px' }} value={this.props.mainState['options'] ? this.props.mainState['options'] : ""} /></>
                    } else if (key === 'title') {
                        filter = <input className="center" placeholder="Enter your question" onChange={this.onHandleChange.bind(this)} key={key} type={key} name={key} style={{ marginTop: '17px', marginBottom: '15px' }} value={this.props.mainState[key] ? this.props.mainState[key] : ""} />
                    } else {
                        filter = <textarea rows="10" placeholder={title} onChange={this.onHandleChange.bind(this)} key={key} type={key} name={key} style={{ marginTop: '17px', marginBottom: '15px' }} value={this.props.mainState[key] ? this.props.mainState[key] : ""} />
                    }
                }
            }

            return (
                <div className="stepContainer" key={key}>
                    {stepName()}
                    <div className="stepTitle">{title}</div>
                    {filter}
                    <ArrowNavigation isFirst={isFirst} isLast={isLast} stepType={key} onHandleNavigation={this.props.onHandleNavigation} />
                </div>
            )
        }

        let step = '';
        switch (this.props.currentStep) {
            case 1:
                step = stepDetail('Select the Club or Clubs?', 'branch_id', true, true, false);
                break;

            case 2:
                step = stepDetail('What is the date range?', 'date_range', false, false, false);
                break;

            case 3:
                step = stepDetail('Select the activity or activities?', 'activity_type_id', true, false, false);
                break;

            case 4:
                step = stepDetail('What attributes are you interested in?', 'attributes', true, false, true);
                break;

            default:
                break;
        }

        return step
    }
}

export default FormStep;
