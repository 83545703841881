import React from 'react';
import { connect } from "react-redux";
import { upsertSession } from '../../action/session';
import { updateWorkplanActivityField, upsertWorkplan } from '../../action/workplan';
import { hoverEnter, hoverLeave } from '../../action/user';
import { toggleSelect, updateField } from '../../action/view';
import * as Constants from '../../Constants';
import { getPeriods, formatDeliveryDate } from '../../utils/date';
import DatePicker from "react-datepicker";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  upsertSession,
  hoverEnter,
  hoverLeave,
  toggleSelect,
  updateField,
  updateWorkplanActivityField,
  upsertWorkplan
};
class EditWorkplanForm extends React.Component {

  constructor(props) {
    super(props);
    let start_date = this.props.workplanView.workplan.start_date
    let end_date = this.props.workplanView.workplan.end_date
    this.state = {
      startDate: new Date(start_date ? start_date : ''),
      endDate: new Date(end_date ? end_date : ''),
      period: getPeriods(this.props.workplanView.workplan.period),
      occurrences: false,
      delivery_duration: false,
      overhead_duration: false,
      venue: false,
      age: false,
      gender: false,
      cost: false,
      notes: false,
      ins_name: false,
      ins_contact: false,
      ins_email: false,
      ins_doc: false,
      ind_name: false,
      ind_contact: false,
      ind_email: false,
      ind_doc: false
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.workplanView.workplan.id !== prevProps.workplanView.workplan.id) {
      let start_date = this.props.workplanView.workplan.start_date
      let end_date = this.props.workplanView.workplan.end_date
      this.setState({
        ...this.state,
        period: getPeriods(this.props.workplanView.workplan.period),
        startDate: new Date(start_date ? start_date : ''),
        endDate: new Date(end_date ? end_date : ''),
      })
    }
  }

  onClickMore(e) {
    e.preventDefault();
    let moreVar = e.currentTarget.getAttribute('data-more');
    let state = {
      ...this.state,
    }
    state[moreVar] = !this.state[moreVar]
    this.setState(state)
  }

  updateTextField(e) {
    e.preventDefault();
    let value = e.currentTarget.value ? e.currentTarget.value : e.currentTarget.getAttribute('data-value')
    this.props.updateField(e.currentTarget.getAttribute('data-field'), Constants.WORKPLAN_VIEW, value);
  }

  updateWorkplanActivityField(e) {
    e.preventDefault();
    let value = e.currentTarget.value ? e.currentTarget.value : e.currentTarget.getAttribute('data-value')
    if (e.currentTarget.getAttribute('data-deleted')) {
      let currentWorkplanActivities = this.props.workplanView.workplan.work_plan_activity.filter((work_plan_activity) => work_plan_activity.activity_type_id === parseInt(e.currentTarget.getAttribute('data-id')))
      if (e.currentTarget.getAttribute('data-deleted') === 'true') {
        currentWorkplanActivities.forEach(currentWorkplanActivity => {
          currentWorkplanActivity.deleted_at = true
        });
      } else {
        currentWorkplanActivities.forEach(currentWorkplanActivity => {
          currentWorkplanActivity.deleted_at = false
        });
      }

    }
    this.props.updateWorkplanActivityField(e.currentTarget.getAttribute('data-field'), parseInt(e.currentTarget.getAttribute('data-id')), parseInt(e.currentTarget.getAttribute('data-order')), value);
  }

  updateNumberField(e) {
    e.preventDefault();
    let value = e.currentTarget.value ? e.currentTarget.value : e.currentTarget.getAttribute('data-value')
    if (!value) {
      value = 0;
    }
    this.props.updateField(e.currentTarget.getAttribute('data-field'), Constants.WORKPLAN_VIEW, parseFloat(value));
  }

  toggleSelect(e) {
    e.preventDefault();
    let view = e.currentTarget.getAttribute('data-id');
    if (view === "user") {
      this.props.toggleSelect(view, parseInt(e.currentTarget.getAttribute('data-user-id')), parseInt(e.currentTarget.getAttribute('data-activity-id')), parseInt(e.currentTarget.getAttribute('data-order')));
    } else {
      this.props.toggleSelect(view);
    }
  }

  selectStartDate() {
    document.getElementsByClassName('react-datepicker__input-container')[0].getElementsByTagName('input')[0].focus();
  }

  onStartDateChange(date, e) {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    this.setState({
      ...this.state,
      startDate: date,
    });
    var moment = require('moment-timezone');
    var local = moment.tz(date.toISOString(), "Australia/Brisbane");
    this.props.updateField('start_date', Constants.WORKPLAN_VIEW, local.format());
  }

  selectEndDate() {
    document.getElementsByClassName('react-datepicker__input-container')[1].getElementsByTagName('input')[0].focus();
  }

  onEndDateChange(date, e) {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }
    this.setState({
      ...this.state,
      endDate: date,
    });
    var moment = require('moment-timezone');
    var local = moment.tz(date.toISOString(), "Australia/Brisbane");
    this.props.updateField('end_date', Constants.WORKPLAN_VIEW, local.format());
  }

  upsertWorkplan(e) {
    e.preventDefault();
    this.props.upsertWorkplan(this.props.workplanView.workplan);
  }

  render() {
    const user = JSON.parse(localStorage.getItem('user')).user;
    const workplan = this.props.workplanView.workplan
    const errors = this.props.workplanView.errors;
    const server_errors = this.props.workplanView.server_errors;
    const server_error_str = server_errors ? server_errors.map((error, key) =>
      <div className="error-img-div" key={key}>
        <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
      </div>
    ) : "";

    let age_error_str = ""
    if (errors && errors.age) {
      age_error_str = errors.age.map((error, key) => {
        return (
          <div className="error-img-div float-right" key={key}>
            <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
          </div>
        )
      });
    }

    let gender_error_str = ""
    if (errors && errors.target_gender) {
      gender_error_str = errors.target_gender.map((error, key) => {
        return (
          <div className="error-img-div float-right" key={key}>
            <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
          </div>
        )
      });
    }

    let occurrence_error_str = ""
    if (errors && errors.occurrence) {
      occurrence_error_str = errors.occurrence.map((error, key) => {
        return (
          <div className="error-img-div float-right" key={key}>
            <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
          </div>
        )
      });
    }

    let delivery_duration_error_str = ""
    if (errors && errors.delivery_duration) {
      delivery_duration_error_str = errors.delivery_duration.map((error, key) => {
        return (
          <div className="error-img-div float-right" key={key}>
            <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
          </div>
        )
      });
    }

    let assign_officer_error_str = ""
    if (errors && errors.assign_officer) {
      assign_officer_error_str = errors.assign_officer.map((error, key) => {
        return (
          <div className="error-img-div float-right" key={key}>
            <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
          </div>
        )
      });
    }

    let images = [];
    let occurrences = [];
    let delivery_duration = [];
    let overhead_duration = [];
    let venue = [];
    let age = [];
    let gender = [];
    let cost = [];
    let notes = [];
    let ins_name = [];
    let ins_contact = [];
    let ins_email = [];
    let ins_doc = [];
    let ind_name = [];
    let ind_contact = [];
    let ind_email = [];
    let ind_doc = [];

    let activityArray = workplan.work_plan_activity.filter(function (activity) {
      if (!activity.deleted_at) {
        return !activity.deleted;
      }
    })

    const count = activityArray.length
    const moreStr = count > Constants.WORKPLAN_MORE ? " +" + (count - Constants.WORKPLAN_MORE) + " More" : ""

    let ins_count = 0
    let ind_count = 0

    for (let i = 0; i < activityArray.length; i++) {
      let work_plan_activity = activityArray[i];
      if (!work_plan_activity.deleted) {
        if (i < Constants.WORKPLAN_MORE) {
          work_plan_activity.activity_type.image_url ? images.push(
            <div className="image-circle inline" key={i}>
              <img className="img small overlap" src={work_plan_activity.activity_type.image_url} alt="tick"></img>
            </div>) :
            images.push(
              <div className="inline">
                <div className="user-logo small overlap img">
                  {work_plan_activity.activity_type.abbreviation}
                </div>
              </div>)
        }

        occurrences.push(
          <div className={"field " + (!(errors && errors.age) && !this.state.occurrences && i >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
            <label className="inline" >{work_plan_activity.activity_type.name}</label>
            <input className="inline occurrence" type="number" value={work_plan_activity.occurrence} data-id={work_plan_activity.activity_type_id}
              data-field="occurrence" data-order={work_plan_activity.order} onChange={this.updateWorkplanActivityField.bind(this)}
              disabled={user.role_id >= Constants.GUEST}></input>
            <div className={work_plan_activity.user ? "inline user-logo clickable" : "inline assign clickable"}
              data-id="user" data-user-id={work_plan_activity.user ? work_plan_activity.user.id : 0}
              data-activity-id={work_plan_activity.activity_type_id}
              data-order={work_plan_activity.order}
              onClick={user.role_id < Constants.GUEST ? this.toggleSelect.bind(this) : ""}
            >{work_plan_activity.user ? work_plan_activity.user.first_name.charAt(0) + work_plan_activity.user.last_name.charAt(0) : "Assign"}</div>
            <img src="/img/arrow.svg" alt="Arrow" className="arrow user inline"></img>
            <button className='clickable float-right' data-field="occurrence_delete" value={work_plan_activity.occurrence} data-id={work_plan_activity.activity_type_id} data-deleted={!work_plan_activity.deleted_at} onClick={this.updateWorkplanActivityField.bind(this)}>{work_plan_activity.deleted_at ? 'Restore' : 'Delete'}</button>
          </div>
        )

        delivery_duration.push(
          <div className={"field " + (!(errors && errors.age) && !this.state.delivery_duration && i >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
            <label className="inline" >{work_plan_activity.activity_type.name}</label>
            <input className="inline" type="number" value={work_plan_activity.delivery_duration}
              data-id={work_plan_activity.activity_type_id} data-field="delivery_duration" data-order={work_plan_activity.order}
              onChange={this.updateWorkplanActivityField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
          </div>
        )

        overhead_duration.push(
          <div className={"field " + (!this.state.overhead_duration && i >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
            <label className="inline" >{work_plan_activity.activity_type.name}</label>
            <input className="inline" type="number" value={work_plan_activity.overhead_duration}
              data-id={work_plan_activity.activity_type_id} data-field="overhead_duration" data-order={work_plan_activity.order}
              onChange={this.updateWorkplanActivityField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
          </div>
        )

        venue.push(
          <div className={"field " + (!this.state.venue && i >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
            <label className="inline" >{work_plan_activity.activity_type.name}</label>
            <input className="inline" type="text" value={work_plan_activity.venue}
              data-id={work_plan_activity.activity_type_id} data-field="venue" data-order={work_plan_activity.order}
              onChange={this.updateWorkplanActivityField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
          </div>
        )
        const ageHtml = Constants.AGE_ID.map((age2, key2) => {
          let resultClass = 'role-div inline age';

          if (work_plan_activity.age) {
            work_plan_activity.age.forEach(age => {
              if (age.name === age2) {
                resultClass = 'role-div inline age active';
              }
            });
          }

          if (age2 !== '') {
            return (
              <div className={resultClass}
                data-value={key2} data-id={work_plan_activity.activity_type_id} data-field="target_age" key={key2} data-order={work_plan_activity.order}
                onClick={user.role_id < Constants.GUEST ? this.updateWorkplanActivityField.bind(this) : ""}>{age2.replace(" Years", "").replace("Under ", "0-")}</div>
            )
          }
          return <></>
        });

        age.push(
          <div className={"field " + (!(errors && errors.age) && !this.state.age && i >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
            <label className="inline">{work_plan_activity.activity_type.name}</label><br />
            {ageHtml}
          </div>
        )

        gender.push(
          <div className={"field " + (!this.state.gender && i >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
            <label className="inline">{work_plan_activity.activity_type.name}</label>
            <br />
            <div className={work_plan_activity.target_gender === Constants.GENDER_ALL ? 'role-div inline gender active' : 'role-div inline gender'}
              data-value={Constants.GENDER_ALL} data-id={work_plan_activity.activity_type_id} data-field="target_gender" data-order={work_plan_activity.order}
              onClick={user.role_id < Constants.GUEST ? this.updateWorkplanActivityField.bind(this) : ""}>{Constants.GENDER_ALL}</div>
            <div className={work_plan_activity.target_gender === Constants.GENDER_MALE ? 'role-div inline gender active' : 'role-div inline gender'}
              data-value={Constants.GENDER_MALE} data-id={work_plan_activity.activity_type_id} data-field="target_gender" data-order={work_plan_activity.order}
              onClick={user.role_id < Constants.GUEST ? this.updateWorkplanActivityField.bind(this) : ""}>{Constants.GENDER_MALE}</div>
            <div className={work_plan_activity.target_gender === Constants.GENDER_FEMALE ? 'role-div inline gender active' : 'role-div inline gender'}
              data-value={Constants.GENDER_FEMALE} data-id={work_plan_activity.activity_type_id} data-field="target_gender" data-order={work_plan_activity.order}
              onClick={user.role_id < Constants.GUEST ? this.updateWorkplanActivityField.bind(this) : ""}>{Constants.GENDER_FEMALE}</div>
            <div className={work_plan_activity.target_gender === Constants.GENDER_OTHER ? 'role-div inline gender active' : 'role-div inline gender'}
              data-value={Constants.GENDER_OTHER} data-id={work_plan_activity.activity_type_id} data-field="target_gender" data-order={work_plan_activity.order}
              onClick={user.role_id < Constants.GUEST ? this.updateWorkplanActivityField.bind(this) : ""}>{Constants.GENDER_OTHER}</div>
          </div>
        )

        cost.push(
          <div className={"field " + (!this.state.cost && i >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
            <label className="inline" >{work_plan_activity.activity_type.name}</label>
            <input className="inline" type="text" value={work_plan_activity.cost ? work_plan_activity.cost : ""}
              data-id={work_plan_activity.activity_type_id} data-field="cost" data-order={work_plan_activity.order}
              onChange={this.updateWorkplanActivityField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
          </div>
        )

        notes.push(
          <div className={"field " + (!this.state.notes && i >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
            <label className="inline" >{work_plan_activity.activity_type.name}</label>
            <input className="inline" type="text" value={work_plan_activity.notes ? work_plan_activity.notes : ""}
              data-id={work_plan_activity.activity_type_id} data-field="notes" data-order={work_plan_activity.order}
              onChange={this.updateWorkplanActivityField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
          </div>
        )

        if (work_plan_activity.activity_type.require_instructor) {
          ins_name.push(
            <div className={"field " + (!this.state.ins_name && ins_count >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
              <label className="inline" >{work_plan_activity.activity_type.name}</label>
              <input className="inline" type="text" value={work_plan_activity.instructor ? work_plan_activity.instructor.name : ""}
                data-id={work_plan_activity.activity_type_id} data-field="ins_name" data-order={work_plan_activity.order}
                onChange={this.updateWorkplanActivityField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
            </div>
          )

          ins_contact.push(
            <div className={"field " + (!this.state.ins_contact && ins_count >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
              <label className="inline" >{work_plan_activity.activity_type.name}</label>
              <input className="inline" type="text" value={work_plan_activity.instructor ? work_plan_activity.instructor.contact_number : ""}
                data-id={work_plan_activity.activity_type_id} data-field="ins_contact_number" data-order={work_plan_activity.order}
                onChange={this.updateWorkplanActivityField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
            </div>
          )

          ins_email.push(
            <div className={"field " + (!this.state.ins_email && ins_count >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
              <label className="inline" >{work_plan_activity.activity_type.name}</label>
              <input className="inline" type="text" value={work_plan_activity.instructor ? work_plan_activity.instructor.email : ""}
                data-id={work_plan_activity.activity_type_id} data-field="ins_email" data-order={work_plan_activity.order}
                onChange={this.updateWorkplanActivityField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
            </div>
          )

          ins_doc.push(
            <div className={"field " + (!this.state.ins_doc && ins_count >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
              <label className="inline" >{work_plan_activity.activity_type.name}</label>
              <input className="inline" type="text" value={work_plan_activity.instructor ? work_plan_activity.instructor.document : ""}
                data-id={work_plan_activity.activity_type_id} data-field="ins_document" data-order={work_plan_activity.order}
                onChange={this.updateWorkplanActivityField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
            </div>
          )
          ins_count++;
        }

        if (work_plan_activity.activity_type.require_indemnification) {
          ind_name.push(
            <div className={"field " + (!this.state.ind_name && ind_count >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
              <label className="inline" >{work_plan_activity.activity_type.name}</label>
              <input className="inline" type="text" value={work_plan_activity.indemnification ? work_plan_activity.indemnification.name : ""}
                data-id={work_plan_activity.activity_type_id} data-field="ind_name" data-order={work_plan_activity.order}
                onChange={this.updateWorkplanActivityField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
            </div>
          )

          ind_contact.push(
            <div className={"field " + (!this.state.ind_contact && ind_count >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
              <label className="inline" >{work_plan_activity.activity_type.name}</label>
              <input className="inline" type="text" value={work_plan_activity.indemnification ? work_plan_activity.indemnification.contact_number : ""}
                data-id={work_plan_activity.activity_type_id} data-field="ind_contact_number" data-order={work_plan_activity.order}
                onChange={this.updateWorkplanActivityField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
            </div>
          )

          ind_email.push(
            <div className={"field " + (!this.state.ind_email && ind_count >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
              <label className="inline" >{work_plan_activity.activity_type.name}</label>
              <input className="inline" type="text" value={work_plan_activity.indemnification ? work_plan_activity.indemnification.email : ""}
                data-id={work_plan_activity.activity_type_id} data-field="ind_email" data-order={work_plan_activity.order}
                onChange={this.updateWorkplanActivityField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
            </div>
          )

          ind_doc.push(
            <div className={"field " + (!this.state.ind_doc && ind_count >= Constants.WORKPLAN_MORE ? "display-none" : "")} key={i} >
              <label className="inline" >{work_plan_activity.activity_type.name}</label>
              <input className="inline" type="text" value={work_plan_activity.indemnification ? work_plan_activity.indemnification.document : ""}
                data-id={work_plan_activity.activity_type_id} data-field="ind_document" data-order={work_plan_activity.order}
                onChange={this.updateWorkplanActivityField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
            </div>
          )
          ind_count++;
        }
      }
    }

    const ins_moreStr = ins_count > Constants.WORKPLAN_MORE ? " +" + (ins_count - Constants.WORKPLAN_MORE) + " More" : ""
    const ind_moreStr = ind_count > Constants.WORKPLAN_MORE ? " +" + (ind_count - Constants.WORKPLAN_MORE) + " More" : ""



    const periods = this.state.period.map((period, key) => {
      return (
        <div className={workplan.period === period ? 'role-div inline period active clickable' : 'role-div inline period clickable'}
          data-field="period" data-value={period} key={key}
          onClick={user.role_id < Constants.GUEST ? this.updateTextField.bind(this) : ""}>{period}</div>
      )
    });

    let start_date = workplan.start_date ? formatDeliveryDate(workplan.start_date) : formatDeliveryDate(Date.now());

    let end_date = workplan.end_date ? formatDeliveryDate(workplan.end_date) : formatDeliveryDate(Date.now());
    return (
      <div className="session upsert-one-element-div normal-box normal-box-margin float-left">
        <div className="upsert-one-element" >
          {server_errors ?
            <div className="error-div">
              {server_error_str}
            </div> : ""}
          <h1>Edit Program</h1>
          <div className="field-group">
            <div className="field with-arrow" data-id="branch" onClick={user.role_id < Constants.GUEST ? this.toggleSelect.bind(this) : ""}>
              <div className="inline">
                <label><span>Club</span></label>
                <div>{workplan.branch.name}</div>
              </div >
              <div className="inline arrow-div">
                <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
              </div>
              {errors && errors.branch ?
                <div className="error-img-div">
                  <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.branch}
                </div> : ""}
            </div>
          </div>
          <div className="field-group">
            <div className="field with-arrow" data-id="activity" onClick={user.role_id < Constants.GUEST ? this.toggleSelect.bind(this) : ""}>
              <div className="inline">
                <label><span>Activity</span></label><br />
                {images}&nbsp;&nbsp;{moreStr}
              </div>
              <div className="inline arrow-div">
                <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
              </div>
            </div>
            {errors && errors.activity ?
              <div className="error-img-div">
                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.activity}
              </div> : ""}
          </div>
          <div className="field-group">
            <div className="field">
              <label><span>Period</span></label>
              <div className="role">
                {periods}
              </div>
            </div>
            {errors && errors.period ?
              <div className="error-img-div">
                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.period}
              </div> : ""}
          </div>
          <div className="field-group">
            <div className="field with-arrow" onClick={user.role_id < Constants.GUEST ? this.selectStartDate.bind(this) : ""}>
              <div className="inline">
                <label><span>Start Date</span></label><br />
                <div>{start_date}</div>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.onStartDateChange.bind(this)}
                />
              </div>

              <div className="inline arrow-div">
                <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
              </div>
            </div>
          </div>

          <div className="field-group">
            <div className="field with-arrow" onClick={user.role_id < Constants.GUEST ? this.selectEndDate.bind(this) : ""}>
              <div className="inline">
                <label><span>End Date</span></label><br />
                <div>{end_date}</div>
                <DatePicker
                  selected={this.state.endDate}
                  onChange={this.onEndDateChange.bind(this)}
                />
              </div>
              <div className="inline arrow-div">
                <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
              </div>
            </div>
            {errors && errors.date ?
              <div className="error-img-div">
                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.date}
              </div> : ""}
          </div>
          <div className="field-group">
            <div className="field">
              <label><span>Occurrences</span></label>
            </div>
            {occurrences}
            {occurrence_error_str}
            {assign_officer_error_str}
            {errors && (errors.occurrence || errors.assign_officer) ? "" :
              <p className="center more clickable" data-more="occurrences" onClick={this.onClickMore.bind(this)}>{this.state.occurrences ? "Close" : moreStr}</p>}
          </div>

          <div className="field-group">
            <div className="field">
              <label><span>Delivery Duration</span></label>
            </div>
            {delivery_duration}
            {errors && errors.delivery_duration ? delivery_duration_error_str :
              <p className="center more clickable" data-more="delivery_duration" onClick={this.onClickMore.bind(this)}>{this.state.delivery_duration ? "Close" : moreStr}</p>}
          </div>

          <div className="field-group">
            <div className="field">
              <label><span>Coordination Duration</span></label>
            </div>
            {overhead_duration}
            <p className="center more clickable" data-more="overhead_duration" onClick={this.onClickMore.bind(this)}>{this.state.overhead_duration ? "Close" : moreStr}</p>
          </div>

          <div className="field-group">
            <div className="field">
              <label><span>Venue</span></label>
            </div>
            {venue}
            <p className="center more clickable" data-more="venue" onClick={this.onClickMore.bind(this)}>{this.state.venue ? "Close" : moreStr}</p>
          </div>

          <div className="field-group">
            <div className="field">
              <label><span>Targeted Age Range</span></label>
            </div>
            {age}
            {errors && errors.age ? age_error_str :
              <p className="center more clickable" data-more="age" onClick={this.onClickMore.bind(this)}>{this.state.age ? "Close" : moreStr}</p>}
          </div>

          <div className="field-group">
            <div className="field">
              <label><span>Targeted Gender</span></label>
            </div>
            {gender}
            {errors && errors.target_gender ? gender_error_str :
              <p className="center more clickable" data-more="gender" onClick={this.onClickMore.bind(this)}>{this.state.gender ? "Close" : moreStr}</p>}
          </div>

          <div className="field-group">
            <div className="field">
              <label><span>Additional Cost</span></label>
            </div>
            {cost}
            <p className="center more clickable" data-more="cost" onClick={this.onClickMore.bind(this)}>{this.state.cost ? "Close" : moreStr}</p>
          </div>

          <div className="field-group">
            <div className="field">
              <label><span>Additional Notes</span></label>
            </div>
            {notes}
            <p className="center more clickable" data-more="notes" onClick={this.onClickMore.bind(this)}>{this.state.notes ? "Close" : moreStr}</p>
          </div>

          <div className="field-group">
            <div className="field">
              <label><span>Insructor's Name</span></label>
            </div>
            {ins_name}
            <p className="center more clickable" data-more="ins_name" onClick={this.onClickMore.bind(this)}>{this.state.ins_name ? "Close" : ins_moreStr}</p>
          </div>

          <div className="field-group">
            <div className="field">
              <label><span>Insructor's Contact Number</span></label>
            </div>
            {ins_contact}
            <p className="center more clickable" data-more="ins_contact" onClick={this.onClickMore.bind(this)}>{this.state.ins_contact ? "Close" : ins_moreStr}</p>
          </div>

          <div className="field-group">
            <div className="field">
              <label><span>Insructor's Email</span></label>
            </div>
            {ins_email}
            <p className="center more clickable" data-more="ins_email" onClick={this.onClickMore.bind(this)}>{this.state.ins_email ? "Close" : ins_moreStr}</p>
          </div>

          <div className="field-group">
            <div className="field">
              <label><span>Instructor's Supporting Documentation Info</span></label>
            </div>
            {ins_doc}
            <p className="center more clickable" data-more="ins_doc" onClick={this.onClickMore.bind(this)}>{this.state.ins_doc ? "Close" : ins_moreStr}</p>
          </div>

          <div className="field-group">
            <div className="field">
              <label><span>Indemnification's Name</span></label>
            </div>
            {ind_name}
            <p className="center more clickable" data-more="ind_name" onClick={this.onClickMore.bind(this)}>{this.state.ind_name ? "Close" : ind_moreStr}</p>
          </div>

          <div className="field-group">
            <div className="field">
              <label><span>Indemnification's Contact Number</span></label>
            </div>
            {ind_contact}
            <p className="center more clickable" data-more="ind_contact" onClick={this.onClickMore.bind(this)}>{this.state.ind_contact ? "Close" : ind_moreStr}</p>
          </div>

          <div className="field-group">
            <div className="field">
              <label><span>Indemnification's Email</span></label>
            </div>
            {ind_email}
            <p className="center more clickable" data-more="ind_email" onClick={this.onClickMore.bind(this)}>{this.state.ind_email ? "Close" : ind_moreStr}</p>
          </div>

          <div className="field-group">
            <div className="field">
              <label><span>Indemnification's Supporting Documentation Info</span></label>
            </div>
            {ind_doc}
            <p className="center more clickable" data-more="ind_doc" onClick={this.onClickMore.bind(this)}>{this.state.ind_doc ? "Close" : ind_moreStr}</p>
          </div>
          {user.role_id < Constants.GUEST ?
            <button className="red-white-button not-ab" onClick={this.upsertWorkplan.bind(this)}>Update</button> : ""}
        </div>
      </div >
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditWorkplanForm);
