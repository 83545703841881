import React from 'react';
import { connect } from "react-redux";
import { fetchBranchList } from '../../action/branch';
import { fetchGoodNewsList, deleteAGoodNews } from '../../action/goodNews';
import BranchList from './BranchList';
import GoodNewsList from './GoodNewsList';
import CreateGoodNews from './CreateGoodNews';
import GoodNewsForm from './GoodNewsForm';
import SelectBranch from './SelectBranch';
import * as Constants from '../../Constants';


function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  fetchBranchList,
  fetchGoodNewsList,
  deleteAGoodNews
};

class GoodNews extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      branch_id: '',
      str: "",
      page: 1,
      endPage: false
    }
    this.props.fetchBranchList();
    this.props.fetchGoodNewsList();
  }

  loadMore() {
    let nextPage = this.state.page + 1;
    let currentCount = this.state.page * Constants.MAX_COUNT;
    let nextCount = nextPage * Constants.MAX_COUNT;

    if (this.state.endPage !== true) {
      if (nextCount < this.props.goodNewsView.total_count) {
        this.setState({
          ...this.state,
          page: nextPage
        });
      } else {
        nextCount = this.props.goodNewsView.total_count;
        this.setState({
          ...this.state,
          page: nextPage,
          endPage: true
        });
      }
      // fetch survey list again
      this.props.fetchGoodNewsList(null, this.state.branch_id, this.state.str, currentCount, Constants.MAX_COUNT);
    }
  }

  search(str) {
    this.setState({
      ...this.state,
      str: str
    })
    let nextPage = this.state.page + 1;
    let nextCount = nextPage * Constants.MAX_COUNT;
    this.props.fetchGoodNewsList(null, this.state.branch_id, str, 0, nextCount);
  }

  handleDelete(good_news_id) {
    // after delete, the survey list need to reload using the same data from the previous page
    this.props.deleteAGoodNews(good_news_id, this.state.branch_id, this.state.page, this.state.str);
  }

  onBranchChange(branch_id, str) {
    this.setState({
      str: str,
      branch_id: branch_id,
      page: 1,
      endPage: false
    })
  }

  render() {
    const user = JSON.parse(localStorage.getItem('user')).user;
    return (
      <div className="GoodNews">
        <BranchList onBranchChange={this.onBranchChange.bind(this)} search={this.search.bind(this)}></BranchList>
        <GoodNewsList loadMore={this.loadMore.bind(this)} handleDelete={this.handleDelete.bind(this)}></GoodNewsList>
        {user.role_id < Constants.GUEST ? 
        this.props.root.overlay ? 
          this.props.root.view_type === 'create' ? <GoodNewsForm action="create"></GoodNewsForm> : 
          this.props.root.view_type === 'update' ? <GoodNewsForm action="update" ></GoodNewsForm> : 
          this.props.root.view_type === 'createbranch' ? <SelectBranch action="create"></SelectBranch> : 
          this.props.root.view_type === 'updatebranch' ? <SelectBranch action="update"></SelectBranch> : 
          <CreateGoodNews></CreateGoodNews> : <CreateGoodNews></CreateGoodNews> : ""}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoodNews);
