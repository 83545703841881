import React, { PureComponent } from 'react';
//import SingleChart from "../dashboard/SingleChart";
import YearChart from "../dashboard/YearChart";

export const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length > 0) {
        return (
            <div className={"customTooltip" + (payload[0].payload.date ? " active" : "")}>
                {payload[0].payload.date ?
                    <div className="tooltipDate">{payload[0].payload.date}</div>

                    : ""}
                <div className="tooltipPart">
                    <h5>{payload[0].payload.total}</h5>
                    <span>Total</span>
                </div>
            </div>
        );
    }

    return null;
};

export class CustomizedAxisTick extends PureComponent {
    render() {
        const {
            x, y, payload
        } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={8.5} fontSize={12} textAnchor="middle" fill="#707070">{payload.value}</text>
            </g>
        );
    }
}

class MyChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        let data = this.props.data;
        // let chartType = this.props.type;
        //let margin = { top: 0, bottom: 0, left: -5.5, right: -6 };

        return (
            <div className="chart">
                <svg style={{ height: 0, width: 0, position: 'absolute' }}>
                    <linearGradient id="linear-gradient1" x1="0%" y1="100%" x2="0%" y2="0%" spreadMethod="pad">
                        <stop offset="0" stopColor="#0074BB" stopOpacity="100%" />
                        <stop offset="50%" stopColor="#1182c6" stopOpacity="100%" />
                        <stop offset="100%" stopColor="#2291d0" stopOpacity="100%" />
                    </linearGradient>
                    <linearGradient id="linear-gradient2" x1="0%" y1="100%" x2="0%" y2="0%" spreadMethod="pad">
                        <stop offset="0" stopColor="#2291d0" stopOpacity="100%" />
                        <stop offset="50%" stopColor="#329fda" stopOpacity="100%" />
                        <stop offset="100%" stopColor="#42AEE3" stopOpacity="100%" />
                    </linearGradient>
                </svg>

                {(() => {
                    return <YearChart data={data} />
                    // switch (chartType) {
                    //     case 0:
                    //         return <SingleChart data={data} margin={margin} isCartesianGrid={true} />
                    //     case 1:
                    //     case 2:
                    //     case 3:
                    //     case 4:
                    //     case 5:
                    //         return <YearChart data={data} />
                    //     default:
                    //         return null;
                    // }
                })()}

            </div>
        );
    }
}

export default MyChart;
