import React from 'react';
import Overview from '../dashboard/Overview';
import Performance from '../dashboard/Performance';
import ReportCard from '../dashboard/ReportCard';
import GoodNews from '../dashboard/GoodNews';
import { connect } from "react-redux";
import { fetchAllDashboard } from '../../action/view';
import { fetchAllChart } from '../../action/chart';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    fetchAllDashboard,
    fetchAllChart
};
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.props.fetchAllDashboard(0);
        this.props.fetchAllChart();
    }

    render() {
        return (
            <div className="Dashboard">
                {/* Overview */}
                <Overview />
                <hr />

                {/* Performance */}
                <Performance />

                {this.props.root.resize ?
                    <>
                        <div className="reportCartWrapper">
                            {/* Minute */}
                            <ReportCard type={0} unit="Minutes" hasRadialChart={true} isTotal={true} isLast={false} />

                            {/* Ethnicity */}
                            <ReportCard type={1} unit="" hasRadialChart={true} isTotal={false} isLast={false} />

                            {/* Ethnicity */}
                            <GoodNews />

                            {/* TopSports */}
                            <ReportCard type={2} unit="" hasRadialChart={false} isTotal={false} isLast={false} isPrintLast />
                        </div>
                        <div className="reportCartWrapper">
                            {/* AgeRange */}
                            <ReportCard type={3} unit="" hasRadialChart={false} isTotal={false} isLast={false} />

                            {/* Gender */}
                            <ReportCard type={4} unit="" hasRadialChart={true} isTotal={false} isLast={false} />
                            
                            {/* Custom Attribute */}
                            {
                                this.props.reportCardView.map((card, key) => {
                                    if(key > 4) {
                                        return <ReportCard key={key} attributeId={card.data[0].attribute_id} type={key} unit="" hasRadialChart={card.data[0].display_by === 'bars' ? false : true} isTotal={false} isLast={(key === this.props.reportCardView.length - 1) ? true : false} />
                                    } else {
                                        return null
                                    }
                                })
                            }

                        </div>
                    </>
                    :
                    <div className="reportCartWrapper">
                        {/* Minute */}
                        <ReportCard type={0} unit="Minutes" hasRadialChart={true} isTotal={true} isLast={false} />

                        {/* Ethnicity */}
                        <ReportCard type={1} unit="" hasRadialChart={true} isTotal={false} isLast={false} />

                        {/* Good News */}
                        <GoodNews />

                        {/* TopSports */}
                        <ReportCard type={2} unit="" hasRadialChart={false} isTotal={false} isLast={false} isPrintLast />

                        {/* AgeRange */}
                        <ReportCard type={3} unit="" hasRadialChart={false} isTotal={false} isLast={false} />

                        {/* Gender */}
                        <ReportCard type={4} unit="" hasRadialChart={true} isTotal={false} isLast={true} />

                        {/* Disability */}
                        <ReportCard type={5} unit="" hasRadialChart={true} isTotal={false} isLast={false} sort short/>

                        {/* Custom Attribute */}
                        {
                            this.props.reportCardView.map((card, key) => {
                                if(key > 5) {
                                    return <ReportCard isDeleteShown isPrintLast key={key} attributeId={card.data[0].attribute_id} type={key} unit="" hasRadialChart={card.data[0].display_by === 'bars' ? false : true} isTotal={false} isLast={(key - 1) % 3 === 0} />
                                } else {
                                    return null
                                }
                            })
                        }
                    </div>
                }
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);