import * as Constants from '../Constants';

const performanceView = (state = { timeData: [], data: [], timeSelected: 0 }, action) => {
    switch (action.type) {
        case Constants.RECEIVE_PERFORMANCE_DATA:
            return {
                timeData: action.data.data.summary ? action.data.data.summary : [],
                data: action.data.data.detail,
                timeSelected: action.timeSelected,
                startDate: action.startDate,
                endDate: action.endDate
            }
        case Constants.RECEIVE_ALL_DASHBOARD:
            return {
                timeData: action.data.data.performance.summary ? action.data.data.performance.summary : [],
                data: action.data.data.performance.detail,
                timeSelected: action.time_id,
                startDate: action.startDate,
                endDate: action.endDate
            }
        case Constants.FETCHING_START + '_performance':
            return {
                timeData: [],
                data: [],
                timeSelected: 0
            }
        case Constants.PAGE_CHANGE:
            return {
                ...state,
                timeSelected: 0
            }
        default:
            return state
    }
}

export default performanceView;