import React from 'react';
import HeaderFilter from '../dashboard/HeaderFilter';
import RadialChart from '../dashboard/RadialChart';
import VerticalChart from '../dashboard/VerticalChart';
import PlaceholderReportCard from './placeholder/PlaceholderReportCard';
import * as Constants from '../../Constants';
import { connect } from "react-redux";
import { fetchReportCardData } from '../../action/reportCard';
import { getDateRange } from '../../utils/date';
import CustomDateRange from "./CustomDateRange";
import Modal from 'react-responsive-modal';
import { deleteChart } from '../../action/chart';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    fetchReportCardData,
    deleteChart
};

class ReportCard extends React.Component {

    constructor(props) {
        super(props);
        //this.props.fetchReportCardData(this.props.type)
        this.handleOnClick = this.handleOnClick.bind(this);
        this.state = {
            key: 0,
            startDate: '',
            endDate: '',
            open: false,
            loading: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reportCardView !== this.props.reportCardView) {
            this.setState({
                loading: false
            })
        }
    }

    handleOnClick(key, startDate = null, endDate = null, attributeId = null) {
        this.setState({
            loading: true
        })
        this.props.fetchReportCardData(this.props.type, attributeId, parseInt(key), this.props.root.dashboard_branch, startDate, endDate);

        if (startDate && endDate) {
            this.setState({
                key: key,
                startDate: startDate,
                endDate: endDate
            });
        } else {
            this.setState({
                key: key,
                startDate: '',
                endDate: ''
            });
        }
    }

    onOpenModal() {
        this.setState({
            open: true
        })
    }

    onCloseModal() {
        this.setState({
            open: false
        })
    }

    handDelete(e) {
        this.props.deleteChart(e.target.getAttribute('data-id'));
        this.setState({
            open: false
        })
    }

    render() {
        let data = '';
        let timeSelected = 0;
        const user = JSON.parse(localStorage.getItem('user')).user;

        const propsData = this.props.reportCardView;

        propsData.map((entry) => {
            if (entry.typeCard === this.props.type) {
                if (entry.data !== undefined) {
                    data = entry.data;
                    timeSelected = entry.timeSelected;
                }
            }
            return null;
        })

        let total = 0;
        if (data !== '' && data !== undefined && data) {
            data.map((entry) => {
                total += parseInt(entry.total);
                return null;
            });
        }
        let title = '';
        if (data) {
            if (data.length > 0 && data[0].title) {
                title = data[0].title;
            } else {
                title = Constants.REPORT_CARD[this.props.type].title
            }
        } else {
            title = Constants.REPORT_CARD[this.props.type].title;
        }

        const isLast = this.props.isLast ? 'last' : '';
        const isPrintLast = this.props.isPrintLast ? ' print-last' : '';

        return (
            <div className={'reportCard reltv ' + isLast + isPrintLast + (data === '' ? ' placeholder-css' : '')} >
                {data === '' ?
                    <PlaceholderReportCard type={this.props.type} />
                    :
                    (() => {
                        if (!this.state.loading) {
                            return (
                                <div className="reportCardContainer">
                                    {/* Filter headers */}
                                    <div className="header">
                                        <div className="headerTitle">
                                            {title === 'Ethnicity' ? <h4>Participant Ethnicity</h4> : <h4>{title}</h4>}
                                        </div>

                                        <HeaderFilter attributeId={this.props.attributeId ? this.props.attributeId : ''} keySelected={timeSelected} isDropdown={true} onClick={this.handleOnClick} />
                                    </div>

                                    {data.length === 0 ? "No Data Found" : ""}
                                    {(() => {
                                        if (this.props.hasRadialChart === true) {
                                            return (
                                                <div className="radialChart">
                                                    {/* Minute Charts */}
                                                    <RadialChart data={data} />
                                                    {(() => {
                                                        if (this.props.isTotal === true) {
                                                            return (
                                                                <div className="totalCount">
                                                                    <h4>{total.toLocaleString()}</h4>
                                                                    <p>Total {this.props.unit}</p>
                                                                </div>
                                                            )
                                                        } else {
                                                            if (data && data.length <= 2) {
                                                                return (
                                                                    <div className="totalCount" style={{ bottom: '44px' }}>
                                                                        {
                                                                            data.map((entry, key) => {
                                                                                return (<div className="entryWrapper" key={key}>
                                                                                    <h4>{total ? Math.round(entry.total / total * 100) + '%' : '0%'}</h4>
                                                                                    <p>{entry.name}</p>
                                                                                </div>
                                                                                );
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            } else {
                                                                return (
                                                                    <div className="totalCount">
                                                                        <h4>{total.toLocaleString()}</h4>
                                                                        <p>Total {this.props.unit}</p>
                                                                    </div>
                                                                )
                                                            }

                                                        }
                                                    })()}
                                                </div>
                                            )
                                        }
                                    })()}

                                    <div className="verticalChart">
                                        {/* Vertical Minute Charts */}
                                        <VerticalChart data={data} sort={this.props.sort} short={this.props.short}/>

                                    </div>
                                    {user.role_id < Constants.GUEST && this.props.isDeleteShown ?
                                        <div className="report-card-date" style={{ left: '20px' }}>
                                            <button style={{ 'cursor': 'pointer' }} onClick={this.onOpenModal.bind(this)} data-id={this.props.attributeId}>Delete</button>
                                        </div>
                                        : ""}
                                    <div className="report-card-date">
                                        {getDateRange(timeSelected)}
                                    </div>

                                </div>
                            )
                        } else {
                            return (
                                <PlaceholderReportCard type={this.props.type} />
                            )
                        }
                    })()
                }

                <Modal open={this.state.open} onClose={this.onCloseModal.bind(this)} center>
                    <div className="userModal">
                        <p>Are you sure you want to delete this chart?</p>
                        <div className="center">
                            <button className="yesButton" data-id={this.props.attributeId} onClick={this.handDelete.bind(this)}>Yes</button>
                            <button className="cancelButton" onClick={this.onCloseModal.bind(this)}>Cancel</button>
                        </div>
                    </div>
                </Modal>

                {timeSelected === 5 ?

                    <CustomDateRange startDate={this.state.startDate} endDate={this.state.endDate}
                        handleOnClick={this.handleOnClick.bind(this)} position={'reportcard'} type={this.props.type}
                    ></CustomDateRange>
                    : ""}
            </div>
        )


    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportCard);
