import React from 'react';
import { connect } from "react-redux";
import * as Constants from '../../Constants';
import { pageChangeWithNoStateChange, pageChange } from '../../action/view';
import { Link } from "react-router-dom";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageChange,
    pageChangeWithNoStateChange
};
class AddSuccess extends React.Component {
    returnToSessiones(e) {
        this.props.pageChange(Constants.SESSIONS);
    }

    editSessionButton(e) {
        this.props.pageChangeWithNoStateChange(Constants.SESSIONS);
    }


    render() {
        return (
            <div className="add-user-div normal-box float-left">
                <div className="add-user normal-margin center">
                    <img alt="Pistive Vote" src="/img/positive-vote.svg" className="postive-vote"></img>
                    <p>{this.props.sessionView.message ? this.props.sessionView.message : ""}</p>
                    <Link to="/sessions" className="white-red-button" onClick={this.editSessionButton.bind(this)}>Edit Session</Link>
                    <br />
                    <Link to="/sessions" onClick={this.returnToSessiones.bind(this)}><img src="/img/arrow-left-red.svg" alt="Arrow Left" className="arrow"></img>Return to sessions</Link>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSuccess);
