import React from 'react';
import { connect } from "react-redux";
import { fetchBranchList } from '../../action/branch';
import { logout, fetchUserList, hoverEnter, hoverLeave, fetchAUser, deleteAUser, updateModal } from '../../action/user';
import {pageChange} from '../../action/view';
import Modal from 'react-responsive-modal';
import { withRouter } from "react-router-dom";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  fetchBranchList,
  fetchUserList,
  hoverEnter,
  hoverLeave,
  fetchAUser,
  deleteAUser,
  updateModal,
  pageChange,
  logout
};
class ManageUser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handHoverEnter(e) {
    e.preventDefault();
    this.props.hoverEnter(e.currentTarget.getAttribute('data-id'));
  }

  handHoverLeave(e) {
    e.preventDefault();
    this.props.hoverLeave();
  }

  handDelete(e) {
    e.preventDefault();
    this.onCloseModal();
    this.props.handleDelete(e.currentTarget.getAttribute('data-id'));
  }

  handleEdit(e) {
    e.preventDefault();
    if (this.props.userView.block.isBlocking){
      this.props.updateModal({ 
        isBlocking: true,
        blockOpen: true,
        block_id: e.currentTarget.getAttribute('data-id'),
        type: 'user'
      })
    }else{
      this.props.fetchAUser(e.currentTarget.getAttribute('data-id'))
    };
  }

  handleConfirmEdit(e) {
    e.preventDefault();
    let block = this.props.userView.block;
    if (block.type === "user"){
      this.props.fetchAUser(block.block_id);
    }else{
      if (block.block_id === 'Logout'){
        this.props.logout();
      }
      this.props.pageChange(block.block_id);
      this.props.history.push(block.to);
    }
  }

  onOpenModal = () => { this.setState({ open: true })};
  onCloseModal = () => { this.setState({ open: false})};

  onCloseBlockModal(e) {
    e.preventDefault();
    let block = this.props.userView.block;
    this.props.updateModal({ 
      blockOpen: false,
      block_id: block.block_id,
      isBlocking: block.isBlocking,
      type: block.type
    })
  }

  render() {
    let block = this.props.userView.block;
    const user = this.props.userView.user;
    const userList = this.props.userView.userList;
    const users = userList.map((user, key) => {
      return (
        <a href="#1" key={user.id} data-id={user.id}
          className={this.props.userView.user.id && this.props.userView.user.id === user.id ? "active" : ""}
          onMouseEnter={this.handHoverEnter.bind(this)}
          onMouseLeave={this.handHoverLeave.bind(this)}
          onClick={this.handleEdit.bind(this)}>
          <div className="list-div">
            <div className="user-logo-div inline">
              <div className="user-logo">
                {user.first_name.charAt(0) + user.last_name.charAt(0)}
              </div>
            </div>
            <div className="name inline">
              {user.first_name + ' ' + user.last_name}
              <br />
              <span>{user.role.name}</span>
            </div>
            <div className={this.props.userView.hover_id === user.id ? 'button-div inline float-right' : 'button-div inline float-right display-none'}>
              <button onClick={this.onOpenModal.bind(this)} data-id={user.id}>Delete</button>
              <button href="#1">Edit</button>
            </div>
            <div className={this.props.userView.hover_id === user.id ? 'arrow-div inline float-right display-none' : 'arrow-div inline float-right'}>
              <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
            </div>
          </div>
        </a>
      );
    });
    return (
      <div className="user-list-div normal-box normal-box-margin float-left">
        <div className="manage-list">
          <h1>Manage Users</h1>
          {users}
        </div>
        <Modal open={this.state.open} onClose={this.onCloseModal.bind(this)} center>
          <div className="userModal">
            <p>Are you sure you want to delete</p>
            <p>{user.first_name + ' ' + user.last_name}</p>
            <div className="center">
              <button className="yesButton" data-id={user.id} onClick={this.handDelete.bind(this)}>Yes</button>
              <button className="cancelButton" onClick={this.onCloseModal.bind(this)}>Cancel</button>
            </div>
          </div>
        </Modal>
        <Modal open={block.blockOpen} onClose={this.onCloseBlockModal.bind(this)} center>
          <div className="userModal">
            <p>You have unsaved change for {user.first_name + ' ' + user.last_name}</p>
            <p>Are you sure you want to navigate away?</p>
            <div className="center">
              <button className="yesButton" data-id={user.id} onClick={this.handleConfirmEdit.bind(this)}>Yes</button>
              <button className="cancelButton" onClick={this.onCloseBlockModal.bind(this)}>Cancel</button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageUser));
