import React from 'react';
import * as Constants from '../../Constants';

class OverviewBanner extends React.Component {
    constructor(props) {
        super(props);

        const data = this.props.data;

        if (data.length > 0) {
            this.state = {
                title: data[0].name,
                total: data[0].total + '/' + Constants.MAX_LEVEL,
                positive: data[0].positive,
                percentage: '',
                hasSide: true,
                currentSide: 0,
                icon: Constants.SURVEY_RATE[Math.floor(data[0].total)]
            };
        } else {
            this.state = {
                title: this.props.title,
                total: this.props.total,
                positive: this.props.positive,
                percentage: this.props.percentage,
                hasSide: false,
                currentSide: null,
                icon: this.props.icon
            };
        }

        // this.handleOnChange = this.handleOnChange.bind(this);
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props !== prevProps) {
            const data = this.props.data;
            if (data.length > 0) {
                this.setState({
                    title: data[0].name,
                    total: data[0].total + '/' + Constants.MAX_LEVEL,
                    positive: data[0].positive,
                    percentage: '',
                    hasSide: true,
                    currentSide: 0,
                    icon: Constants.SURVEY_RATE[Math.floor(data[0].total)]
                });
            } else {
                this.setState({
                    title: this.props.title,
                    total: this.props.total,
                    positive: this.props.positive,
                    percentage: this.props.percentage,
                    hasSide: false,
                    currentSide: null,
                    icon: this.props.icon
                });
            }
        }
      }

    handleOnChange(e) {
        // change the state
        const data = this.props.data;
        let checkboxValue = +e.currentTarget.checked;
        if (checkboxValue !== this.state.currentSide) {
            this.setState({
                title: data[checkboxValue].name,
                total: data[checkboxValue].total + '/' + Constants.MAX_LEVEL,
                positive: data[checkboxValue].positive,
                percentage: '',
                hasSide: true,
                currentSide: checkboxValue,
                icon: Constants.SURVEY_RATE[Math.floor(data[checkboxValue].total)]
            })
        }
    }



    render() {
        let background = 'linear-gradient(120deg, ' + this.props.colorStart + ', ' + this.props.colorEnd + ')';
        const icon = '/img/' + this.state.icon;

        let arrow = Constants.ARROWS[0];
        if (this.state.positive === false) {
            arrow = Constants.ARROWS[1];
        }

        let percentage = '';

        if (this.state.percentage !== '' && typeof this.state.percentage !== 'undefined') {
            percentage = this.state.percentage + '%';
        }

        let bgStyle = {
            background: background,
            position: 'relative'
        };

        let thumbStyle = {
            position: 'absolute',
            width: '120px',
            height: '120px',
            opacity: '0.1',
            top: '15px',
            right: '-20px'
        };

        let iconStyle = {
            height: '30px',
        };

        return (
            <div className="overviewBanner" style={bgStyle}>
                {(() => {
                    if(this.state.hasSide) {
                        return (
                            <label className="switch">
                                <input type="checkbox" onChange={this.handleOnChange.bind(this)}/>
                                <span className="slider round" />
                            </label>
                        )
                    } else {
                        return null
                    }
                })()}
                
                <img src={icon} alt={this.state.title} style={thumbStyle} />
                <div className="overviewInfo">
                    <img src={icon} alt={this.state.title} style={iconStyle} />

                    <div className="overviewNumber">
                        <h2>
                            <div className="inline overview-stats">{this.state.total.toLocaleString()}</div>
                            <div className="overview-arrow inline">{this.state.positive !== undefined ? arrow + ' ' + percentage : ''}</div>
                        </h2>
                    </div>
                    <p className="overview-item">{this.state.title}</p>
                </div>
            </div>
        )
    }
}

export default OverviewBanner;
