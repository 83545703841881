import React from 'react';
import * as Constants from '../Constants';

class Breadcrumbs extends React.Component {

    render() {
        return (
            <div className="Breadcrumbs">
                <div className="float-left">{this.props.page ? this.props.page : Constants.DASHBOARD }</div>
                {(() => {
                    if (this.props.page === Constants.ADD_SURVEY) {
                        return <div className="float-right">Home / {Constants.SURVEYS} / <span>{this.props.page}</span></div>
                    } else if (this.props.page === Constants.ADD_USER) {
                        return <div className="float-right">Home / {Constants.USERS} / <span>{this.props.page}</span></div>
                    } else {
                        return <div className="float-right">Home/ <span>{this.props.page ? this.props.page : Constants.DASHBOARD}</span></div>
                    }
                })()}
                
            </div>
        );
    }
}

export default Breadcrumbs;
