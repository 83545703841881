import React from 'react';
import { connect } from "react-redux";
import { pageChange } from '../../action/view';
import AddUserForm from './AddUserForm';
import AddSuccess from './AddSuccess';
import SelectBranch from './SelectBranch';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  pageChange
};

class Users extends React.Component {
  render() {
    return (
      <div className="AddUser">
        {
          this.props.userView.add_success ? <AddSuccess></AddSuccess> : <AddUserForm></AddUserForm>
        }
        {
          this.props.root.overlay ?  <SelectBranch></SelectBranch> : ""
        }
        
        
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
