import React from "react";
import { connect } from "react-redux";
import { fetchBranchList } from "../../action/branch";
import { fetchUserList } from "../../action/user";
import { fetchWorkplanList, deleteAWorkplan } from "../../action/workplan";
import { fetchActivityList } from "../../action/activity";
import * as Constants from "../../Constants";
import BranchList from "./BranchList";
import SelectBranch from "./SelectBranch";
import SelectActivity from "./SelectActivity";
import SelectUser from "./SelectUser";
import ManageWorkplan from "./ManageWorkplan";
import EditWorkplanForm from "./EditWorkplanForm";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  fetchBranchList,
  fetchWorkplanList,
  fetchActivityList,
  fetchUserList,
  deleteAWorkplan,
};
class Workplans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      branch_id: "",
      page: 1,
      endPage: false,
    };
    this.props.fetchBranchList();
    this.props.fetchWorkplanList();
    this.props.fetchActivityList();
    this.props.fetchUserList();
  }

  loadMore() {
    let nextPage = this.state.page + 1;
    let currentCount = this.state.page * Constants.MAX_COUNT;
    let nextCount = nextPage * Constants.MAX_COUNT;

    if (this.state.endPage !== true) {
      if (nextCount < this.props.workplanView.total_count) {
        this.setState({
          ...this.state,
          page: nextPage,
        });
      } else {
        nextCount = this.props.workplanView.total_count;
        this.setState({
          ...this.state,
          page: nextPage,
          endPage: true,
        });
      }
      // fetch survey list again
      this.props.fetchWorkplanList(
        this.state.branch_id,
        currentCount,
        Constants.MAX_COUNT
      );
    }
  }

  handleDelete(session_id) {
    // after delete, the survey list need to reload using the same data from the previous page
    this.props.deleteAWorkplan(
      session_id,
      this.state.branch_id,
      this.state.page
    );
  }

  onBranchChange(branch_id) {
    this.setState({
      branch_id: branch_id,
      page: 1,
      endPage: false,
    });
  }

  render() {
    return (
      <div className="Workplans">
        <BranchList
          onBranchChange={this.onBranchChange.bind(this)}
        ></BranchList>
        <ManageWorkplan
          loadMore={this.loadMore.bind(this)}
          handleDelete={this.handleDelete.bind(this)}
        ></ManageWorkplan>
        {this.props.workplanView.workplan.id ? (
          this.props.root.overlay ? (
            this.props.root.view_type === "branch" ? (
              <SelectBranch></SelectBranch>
            ) : this.props.root.view_type === "activity" ? (
              <SelectActivity type={Constants.TYPE_DIRECT}></SelectActivity>
            ) : this.props.root.view_type === "user" ? (
              <SelectUser></SelectUser>
            ) : (
              <EditWorkplanForm></EditWorkplanForm>
            )
          ) : (
            <EditWorkplanForm></EditWorkplanForm>
          )
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Workplans);
