import React from 'react';
import { connect } from "react-redux";
import { } from '../../action/user';
import { assignUserBranches } from '../../action/user';
import { toggleSelect } from '../../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    assignUserBranches,
    toggleSelect
};
class BranchList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchBranches: this.props.userView.branchList.filter(function(branch) {
                return branch.deleted_at === null;
              }),
            branches: this.props.userView.branchList.filter(function(branch) {
                return branch.deleted_at === null;
              }),
            userBranches: this.props.userView.user.branch ? this.props.userView.user.branch.filter(function(branch) {
                return !branch.before_admin;
              }) : []
        }
    }

    toggleSelect(e) {
        e.preventDefault();
        this.props.toggleSelect();
    }

    assignBranch(e) {
        this.props.assignUserBranches(this.state.userBranches);
        this.props.toggleSelect();
    }

    search(e){
        let searchBranches = []
        for (var i = 0; i < this.state.branches.length; i++) {
            let branchName =this.state.branches[i].name;
            if (branchName.toLowerCase().includes(e.currentTarget.value.toLowerCase())) {
                searchBranches.push(this.state.branches[i]);
            }
        }
        this.setState({
            searchBranches: searchBranches,
            branches: this.state.branches,
            userBranches: this.state.userBranches
        });
        
    }

    selectAllBranches() {
        this.setState({
            searchBranches: this.state.searchBranches,
            branches: this.props.userView.branchList,
            userBranches: this.state.searchBranches
        });
    }

    selectNoBranches() {
        this.setState({
            searchBranches: this.state.searchBranches,
            branches: this.props.userView.branchList,
            userBranches: []
        });
    }

    toggleOneBranch(e) {
        let branchId = e.currentTarget.getAttribute('data-id');

        let userBranches = [];
        let addClicked = true;
        for (var i = 0; i < this.state.userBranches.length; i++) {
            if (this.state.userBranches[i].id !== parseInt(branchId)) {
                userBranches.push(this.state.userBranches[i])
            }else{
                addClicked = false
            }
        }

        if (addClicked) {
            let branchName = "";
            for (var i2 = 0; i2 < this.state.branches.length; i2++) {
                if (this.state.branches[i2].id === parseInt(branchId)) {
                    branchName = this.state.branches[i2].name;
                    break;
                }
            }
            userBranches.push({
                id: parseInt(branchId),
                name: branchName
            })
        }
        this.setState({
            searchBranches: this.state.searchBranches,
            branches: this.props.userView.branchList,
            userBranches: userBranches
        });
    }

    render() {
        const branches = this.state.searchBranches.map((branch, key) => {
            let branchClass = '';
            for (var i = 0; i < this.state.userBranches.length; i++) {
                let userbranch =this.state.userBranches[i];
                if (userbranch.id === branch.id) {
                    branchClass = 'active';
                }
            }

            return (
                <div className="item-name" key={branch.id} data-id={branch.id}>
                    {branch.name}
                    <div className="float-right squre-checkbox" data-id={branch.id} onClick={this.toggleOneBranch.bind(this)}>
                        <div className={branchClass}><img src="/img/check.svg" alt="checkbox" className="check-img"></img></div>
                    </div>
                </div>
            );
        });
        return (
            <div className="select-div normal-box normal-box-margin float-left">
                <div className="normal-margin-heading" >
                    <h1 className="clickable" onClick={this.toggleSelect.bind(this)}><img src="/img/arrow-left.svg" alt="Arrow Left" className="arrow-left"></img>Select Club</h1>
                    <div className="search-field">
                        <input placeholder="Search Clubs" onChange={this.search.bind(this)}></input>
                        <img src="/img/search-icon.svg" alt="Search Icon" className="search-icon"></img>
                    </div>
                </div>
                <div className="normal-margin" >
                    <div className="field-group--mt8">
                        <div className="field--mt0">
                            <div className="role">
                                <div className={"role-div inline"} onClick={this.selectAllBranches.bind(this)}>Select All</div>
                                <div className={"role-div inline"} onClick={this.selectNoBranches.bind(this)}>Remove All</div>
                            </div>
                        </div>
                    </div>
                    {branches}
                    <button className="red-white-button" onClick={this.assignBranch.bind(this)}>Assign</button>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BranchList);
