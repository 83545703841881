import React from 'react';
import { connect } from "react-redux";
import { pageChange } from '../../action/view';
import LogSessionForm from './LogSessionForm';
import SelectBranch from './SelectBranch';
import SelectActivity from './SelectActivity';
import Step from './Step';
import AddSuccess from './AddSuccess';
import * as Constants from '../../Constants';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageChange
};

class AddSession extends React.Component {
    constructor(props) {
        super(props);
        this.state =  {
            type: this.props.type,
            done: [],
            editing: 0
        }
    }

    changeStep(id){
        this.setState({
            type: this.state.type,
            done: this.state.done,
            editing: id
        });
    }

    changeStepDone(done){
        this.setState({
            type: this.state.type,
            done: done,
            editing: this.state.editing
        });
    }

    render() {
        return (
            <div className="AddSession">
                {this.props.sessionView.add_success ? <AddSuccess></AddSuccess> : 
                <div>
                <Step step_count={this.state.type === Constants.TYPE_DIRECT ? 5 : 2} 
                editing={this.state.editing} 
                changeStep={this.changeStep.bind(this)}
                done={this.state.done}></Step>

                <LogSessionForm type={this.state.type} 
                changeStep={this.changeStep.bind(this)}
                changeStepDone={this.changeStepDone.bind(this)}/>

                {this.props.root.overlay ?  
                        this.props.root.view_type === 'branch' ? <SelectBranch></SelectBranch> : 
                        this.props.root.view_type === 'activity' ? <SelectActivity type={this.state.type}></SelectActivity> : 
                        "" : ""}
                        </div>
                }
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSession);
