import React from 'react';
import SelectFilterAttribute from './SelectFilterAttribute';
import ArrowNavigation from './ArrowNavigation';
import SelectAttribute from './SelectAttribute';
import * as Constants from '../../Constants';
import { connect } from "react-redux";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {

};

class FormStep extends React.Component {
    onHandleChange(e) {
        this.props.onHandleUpdateForm(e.currentTarget.getAttribute('type'), e.currentTarget.value);
    }

    render() {
        const stepName = () => {
            return (
                <div className="stepName">{'Step ' + this.props.currentStep + ' / ' + Constants.MAX_CHART_STEP}</div>
            )
        }

        const stepDetail = (title, key, isFilter, isFirst, isLast) => {
            let filter = [];
            if(isFilter === true) {
                filter = <SelectFilterAttribute currentState={this.props.mainState} currentSelected={this.props.mainState[key]} onHandleUpdateForm={this.props.onHandleUpdateForm} type={key} />;
            } else {
                let list = Constants[key.toUpperCase()];

                if(key === 'display_by') {
                    this.props.attributeView.attributeList.forEach(attr => {
                        if(this.props.mainState.attributes.includes(attr.id)) {
                            if(attr.attribute_type_id === 1) {
                                // remove donuts option
                                delete list['Donuts & Bars'];
                            }
                        }
                    });
                }

                if(list !== undefined) {
                    filter = <SelectAttribute isActiveImage={Constants[key.toUpperCase()+'_ACTIVE'] ? true : false} currentSelected={this.props.mainState[key]} data={Object.entries(list)} onHandleUpdateForm={this.props.onHandleUpdateForm} type={key}/>;
                } else {
                    if(key === 'options') {
                        filter = <><input className="center" placeholder={title} onChange={this.onHandleChange.bind(this)} key={key+'title'} type='title' name={key} style={{marginTop: '17px', marginBottom: '15px'}} value={this.props.mainState['title'] ? this.props.mainState['title'] : ""}/><textarea placeholder="option 1, option 2, option 3" onChange={this.onHandleChange.bind(this)} key={key+'options'} type='options' name={key} style={{marginTop: '17px', marginBottom: '15px'}} value={this.props.mainState['options'] ? this.props.mainState['options'] : ""}/></>
                    } else if(key === 'title') {
                        filter = <input className="center" placeholder="Enter your question" onChange={this.onHandleChange.bind(this)} key={key} type={key} name={key} style={{marginTop: '17px', marginBottom: '15px'}} value={this.props.mainState[key] ? this.props.mainState[key] : ""}/>
                    } else {
                        filter = <textarea rows="10" placeholder={title} onChange={this.onHandleChange.bind(this)} key={key} type={key} name={key} style={{marginTop: '17px', marginBottom: '15px'}} value={this.props.mainState[key] ? this.props.mainState[key] : ""}/>
                    }
                }
            }

            return (
                <div className="stepContainer" key={key}>
                    {stepName()}
                    <div className="stepTitle">{title}</div>
                    {filter}
                    <ArrowNavigation isFirst={isFirst} isLast={isLast} stepType={key} onHandleNavigation={this.props.onHandleNavigation} />
                </div>
            )
        }

        let step = '';
        switch (this.props.currentStep) {
            case 1:
                step = stepDetail('What activity are you interested in?', 'activity_type_id', true, true, false);
                break;
            
            case 2:
                step = stepDetail('What attributes are you interested in?', 'attributes', true, false, false);
                break;

            case 3:
                step = stepDetail('How should it be displayed?', 'display_by', false, false, true);
                break;

            default:
                break;
        }

        return step
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormStep);
