import axios from 'axios';
import * as Constants from '../Constants';
import * as Env from '../env';
import { getDateInUsFormat } from '../utils/date';

export const receiveReportCardData = (type, attributeId, data, timeSelected, startDate, endDate) => ({
    type: Constants.RECEIVE_REPORT_CARD_DATA,
    typeCard: parseInt(type),
    data: data,
    timeSelected: timeSelected,
    startDate: startDate,
    endDate: endDate,
    attributeId: attributeId
});

// const fetchingData = (type, timeSelected) => ({
//     type: Constants.FETCHING_START + '_reportCard',
//     timeSelected: timeSelected,
//     typeCard: parseInt(type)
// });

// fetch overview data for dashboard
function fetchReportCardDataAPI(type, attributeId, time_id, branch_id, startDate = null, endDate = null) {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        //dispatch(fetchingData(type, parseInt(time_id) + 1))
        try {
            let response = '';
            let time = parseInt(time_id) + 1;
            let branch = branch_id ? (type > 5 ? '&branch_id=' : '?branch_id=') + branch_id : ''
            let date = (startDate ? (branch_id || type > 5 ? '&startDate=' : '?startDate=') + getDateInUsFormat(startDate) : '') + (endDate ? '&endDate=' + getDateInUsFormat(endDate) : '')

            switch (type) {
                case 0:
                    response = await axios.get(Env.API + 'dashboard/hour/' + time + branch + date, axiosConfig);
                    break;

                case 1:
                    response = await axios.get(Env.API + 'dashboard/ethnicity/' + time + branch + date, axiosConfig);
                    break;

                case 2:
                    response = await axios.get(Env.API + 'dashboard/topsports/' + time + branch + date, axiosConfig);
                    break;

                case 3:
                    response = await axios.get(Env.API + 'dashboard/agerange/' + time + branch + date, axiosConfig);
                    break;

                case 4:
                    response = await axios.get(Env.API + 'dashboard/gender/' + time + branch + date, axiosConfig);
                    break;

                case 5:
                    response = await axios.get(Env.API + 'dashboard/disability/' + time + branch + date, axiosConfig);
                    break;

                default:
                    response = await axios.get(Env.API + 'dashboard/attribute/' + attributeId + '?date_id=' + time + branch + date, axiosConfig);
                    break;
            }

            const data = response.data;
            return dispatch(receiveReportCardData(type, attributeId, data, time_id, startDate, endDate));
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

export function fetchReportCardData(type, attributeId = null, time_id = 0, branch_id = 0, startDate = null, endDate = null) {
    if (time_id === 5 && !startDate && !endDate) {
        return (dispatch) => {
            return dispatch(receiveReportCardData(type, attributeId, { data: [] }, time_id));
        }
    } else {
        return (dispatch) => {
            return dispatch(fetchReportCardDataAPI(type, attributeId, time_id, branch_id, startDate, endDate))
        }
    }
}