import React from 'react';

class PlaceholderReportCard extends React.Component {
    render() {
        return (
            <div className="loaderContainer" style={{ padding: '30px 20px', maxWidth: '273px', width: '100%' }}>
                <div className="loaderRow">
                    <div className="loaderDiv one-fourth">
                        <div className="circleLoad" />
                    </div>
                    <div className="loaderDiv three-fourth">
                        <div className="textLoad big quarter" />
                        <div className="textLoad half" />
                    </div>
                </div>
                <div className="textLoad bigMargin"/>
                <div className="textLoad half bigMargin" />
                <div className="textLoad quarter bigMargin" />
            </div>
        )
    }
}

export default PlaceholderReportCard;
