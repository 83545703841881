import React from 'react';
import { connect } from "react-redux";
import { } from '../../action/user';
import { selectWorkplanActivity } from '../../action/workplan';
import { toggleSelect } from '../../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    selectWorkplanActivity,
    toggleSelect
};
class SelectActivity extends React.Component {

    constructor(props) {
        super(props);
        let type = this.props.type
        let work_plan_activity = this.props.workplanView.workplan.work_plan_activity ? this.props.workplanView.workplan.work_plan_activity : []
        let workplanActivities = []
        for (let i = 0; i < work_plan_activity.length; i++) {
            if (!work_plan_activity[i].deleted) {
                if (!work_plan_activity[i].deleted_at)
                    workplanActivities.push({
                        ...work_plan_activity[i].activity_type,
                        order: work_plan_activity[i].order
                    })
            }
        }
        this.state = type ? {
            searchActivities: this.props.activityView.activityList.filter(function (activity) {
                return activity.type === type;
            }),
            activities: this.props.activityView.activityList.filter(function (activity) {
                return activity.type === type;
            }),
            workplanActivities: workplanActivities
        } : {
            searchActivities: this.props.activityView.activityList,
            activities: this.props.activityView.activityList,
            workplanActivities: workplanActivities
        }
    }

    toggleSelect(e) {
        e.preventDefault();
        this.props.toggleSelect();
    }

    selectActivity(e) {
        if (this.state.workplanActivities.length > 0) {
            this.props.selectWorkplanActivity(this.state.workplanActivities);
            this.props.toggleSelect();
        } else {
            alert("At least one activity is required")
        }
    }

    search(e) {
        let searchActivities = []
        for (var i = 0; i < this.state.activities.length; i++) {
            let activityName = this.state.activities[i].name;
            if (activityName.toLowerCase().includes(e.currentTarget.value.toLowerCase())) {
                searchActivities.push(this.state.activities[i]);
            }
        }
        this.setState({
            searchActivities: searchActivities,
            activities: this.state.activities,
            workplanActivities: this.state.workplanActivities
        });
    }

    toggleOneBranch(e) {
        let activityId = e.currentTarget.getAttribute('data-id');

        let workplanActivities = [];
        let addClicked = true;
        for (var i = 0; i < this.state.workplanActivities.length; i++) {
            if (this.state.workplanActivities[i].id !== parseInt(activityId)) {
                workplanActivities.push(this.state.workplanActivities[i])
            } else {
                addClicked = false
            }
        }

        if (addClicked) {
            let activity = "";
            for (var i2 = 0; i2 < this.state.activities.length; i2++) {
                if (this.state.activities[i2].id === parseInt(activityId)) {
                    activity = this.state.activities[i2];
                    break;
                }
            }
            workplanActivities.push({
                ...activity,
                order: 1
            })
        }
        this.setState({
            searchActivities: this.state.searchActivities,
            activities: this.state.activities,
            workplanActivities: workplanActivities
        });
    }

    minus(e) {
        e.preventDefault();
        let count = parseInt(e.currentTarget.getAttribute('data-count'));

        let activityId = parseInt(e.currentTarget.getAttribute('data-id'));


        let activity = this.state.workplanActivities.filter(function (element) {
            return element.id !== activityId || element.order !== count;
        });

        this.setState({
            ...this.state,
            workplanActivities: activity
        });
    }

    add(e) {
        e.preventDefault();
        let activityId = parseInt(e.currentTarget.getAttribute('data-id'));
        let count = parseInt(e.currentTarget.getAttribute('data-count'));

        let activity = this.props.activityView.activityList.find(function (element) {
            return element.id === activityId;
        });

        let workplanActivities = this.state.workplanActivities
        workplanActivities.push({
            ...activity,
            order: count + 1
        })

        this.setState({
            ...this.state,
            workplanActivities: workplanActivities
        });
    }

    render() {
        const activities = this.state.searchActivities.map((activity, key) => {
            let activityClass = '';
            let count = 0;
            for (var i = 0; i < this.state.workplanActivities.length; i++) {
                let workplanActivity = this.state.workplanActivities[i];
                if (workplanActivity.id === activity.id) {
                    activityClass = 'active';
                    count++;
                }
            }

            return (
                <div className="item-name" key={activity.id} data-id={activity.id}>
                    {activity.image_url ? <div className="image-circle inline"><img className="img" src={activity.image_url} alt={activity.name} /></div>
                        : <div className="user-logo-div inline">
                            <div className="user-logo">
                                {activity.abbreviation}
                            </div>
                        </div>}
                    <span className="inline">{activity.name}</span>
                    <div className={"inline colordot" + (activity.require_instructor ? " amber" : "") + (activity.require_indemnification ? " red" : "")}></div>
                    <div className="float-right squre-checkbox activity" data-id={activity.id} onClick={this.toggleOneBranch.bind(this)}>
                        <div className={activityClass}><img src="/img/check.svg" alt="checkbox" className="check-img"></img></div>
                    </div>
                    {count ?
                        <div className="float-right addminus">
                            <div className="inline clickable" onClick={this.minus.bind(this)} data-id={activity.id} data-count={count}>-</div>
                            <div className="inline">{count}</div>
                            <div className="inline clickable" onClick={this.add.bind(this)} data-id={activity.id} data-count={count}>+</div>
                        </div> : ""}
                </div>
            );
        });
        return (
            <div className="session select-div normal-box normal-box-margin-2 step-div">
                <div className="normal-margin-heading" >
                    <h1 className="clickable" onClick={this.toggleSelect.bind(this)}><img src="/img/arrow-left.svg" alt="Arrow Left" className="arrow-left"></img>Select Activity</h1>
                    <div className="search-field">
                        <input placeholder="Search Activities" onChange={this.search.bind(this)}></input>
                        <img src="/img/search-icon.svg" alt="Search Icon" className="search-icon"></img>
                    </div>
                </div>
                <div className="normal-margin" >
                    {activities}
                    <button className="red-white-button" onClick={this.selectActivity.bind(this)}>Select</button>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectActivity);
