import * as Constants from '../Constants';

const goodNewsView = (state = Constants.goodNewsViewInitialState, action) => {
    let goodNews = {};
    switch (action.type) {
        case Constants.RECEIVE_GOOD_NEWS_LIST:
            return {
                goodNewsList: [...state.goodNewsList, ...action.goodNewsList],
                goodNews: state.goodNews,
                total_count: action.total_count,
                query_count: action.query_count
            }
        case Constants.RECEIVE_GOOD_NEWS_LIST_BRANCH:
            return {
                goodNewsList: action.goodNewsList,
                goodNews: state.goodNews,
                total_count: action.total_count,
                query_count: action.query_count
            }
        case Constants.FETCH_A_GOOD_NEWS:
            let returnGoodNews = {};
            for (var i = 0; i < state.goodNewsList.length; i++) {
                goodNews = state.goodNewsList[i];
                if (goodNews.id === parseInt(action.id)) {
                    returnGoodNews = goodNews;
                    break;
                }
            }
            return {
                goodNewsList: state.goodNewsList,
                goodNews: {
                    ...returnGoodNews
                },
                total_count: state.total_count
            }
        case Constants.UPDATE_CUSTOM_FIELD:
            if (action.view === Constants.GOOD_NEWS_VIEW) {
                let goodNews = state.goodNews;
                let custom_attributes = goodNews.custom_attributes;
                let affectedAttribute = {};

                if(custom_attributes && custom_attributes.length > 0) {
                    affectedAttribute = custom_attributes.find(x => x.id ===  parseInt(action.field));

                    if(affectedAttribute.answer && affectedAttribute.answer.length > 0 && affectedAttribute.attribute_type_id === 1) {
                        if(affectedAttribute.answer.includes(parseInt(action.data))) {
                            affectedAttribute.answer = affectedAttribute.answer.filter((item) => item !== parseInt(action.data));
                        } else {
                            affectedAttribute.answer.push(parseInt(action.data));
                        }
                    } else {
                        if(affectedAttribute.attribute_type_id === 4) {
                            affectedAttribute.answer = [action.data];
                        } else {
                            affectedAttribute.answer = [parseInt(action.data)];
                        }
                    }
                } else {
                    affectedAttribute = goodNews.attribute_goodnews.find(x => x.id ===  parseInt(action.field));
                    let currentAnswer = [];
                    switch (affectedAttribute.attribute_type_id) {
                        case 1:
                        case 2:
                            if(affectedAttribute.pivot.option_select_answer) {
                                currentAnswer = affectedAttribute.pivot.option_select_answer.split(',').map(Number);
                            }
                            
                            break;

                        case 3:
                            if(affectedAttribute.pivot.true_false_answer) {
                                currentAnswer = [parseInt(affectedAttribute.pivot.true_false_answer)];
                            }
                            break;

                        case 4:
                            if(affectedAttribute.pivot.free_text_answer) {
                                currentAnswer = [affectedAttribute.pivot.free_text_answer];
                            }
                            break;
                    
                        default:
                            if(affectedAttribute.pivot.free_text_answer) {
                                currentAnswer = [affectedAttribute.pivot.free_text_answer];
                            }
                            break;
                    }

                    if(currentAnswer && currentAnswer.length > 0 && affectedAttribute.attribute_type_id === 1) {
                        if(currentAnswer.includes(parseInt(action.data))) {
                            currentAnswer = currentAnswer.filter((item) => item !== parseInt(action.data));
                        } else {
                            currentAnswer.push(parseInt(action.data));
                        }
                    } else {
                        if(affectedAttribute.attribute_type_id === 4) {
                            currentAnswer = [action.data];
                        } else {
                            currentAnswer = [parseInt(action.data)];
                        }
                    }

                    switch (affectedAttribute.attribute_type_id) {
                        case 1:
                        case 2:
                            affectedAttribute.pivot.option_select_answer = currentAnswer.join(',');
                            
                            break;

                        case 3:
                            affectedAttribute.pivot.true_false_answer = currentAnswer.join('');
                            break;

                        case 4:
                            affectedAttribute.pivot.free_text_answer = currentAnswer.join('');
                            break;
                    
                        default:
                            affectedAttribute.pivot.free_text_answer = currentAnswer.join('');
                            break;
                    }
                }

                return {
                    goodNewsList: state.goodNewsList,
                    goodNews: goodNews,
                    total_count: state.total_count
                }
            }
            return state;
        case Constants.GET_GOODNEWS_CUSTOM_ATTRIBUTE:
            goodNews = state.goodNews;
            goodNews.custom_attributes = action.attributes;

            return {
                goodNewsList: state.goodNewsList,
                goodNews: goodNews,
                total_count: state.total_count,
                query_count: state.query_count
            }
        case Constants.SELECT_GOOD_NEWS_BRANCH:
            return {
                goodNewsList: state.goodNewsList,
                goodNews: {
                    ...state.goodNews,
                    branch: {
                        id: action.branch.id,
                        name: action.branch.name
                    },
                    branch_id: action.branch.id
                },
                total_count: state.total_count
            }
        case Constants.CLOSE_IMAGE:
            if (action.view === Constants.GOOD_NEWS_VIEW) {
                return {
                    goodNewsList: state.goodNewsList,
                    goodNews: {
                        ...state.goodNews,
                        image_url: null,
                        image_base_64: null
                    }
                }
            } else {
                return state;
            }
        case Constants.UPDATE_PREVIEW_FILE:
            if (action.view === Constants.GOOD_NEWS_VIEW) {
                return {
                    goodNewsList: state.goodNewsList,
                    goodNews: {
                        ...state.goodNews,
                        image_base_64: action.result
                    }
                }
            } else {
                return state;
            }

        case Constants.UPDATE_FIELD:
            if (action.view === Constants.GOOD_NEWS_VIEW) {
                goodNews = {
                    ...state.goodNews,
                }
                goodNews[action.field] = action.data;
                return {
                    goodNewsList: state.goodNewsList,
                    goodNews: goodNews,
                    total_count: state.total_count
                }
            }
            return state;

            // case Constants.RECEIVE_UPDATE_SESSION:
            //     for (var i2 = 0; i2 < state.sessionList.length; i2++) {
            //         session = state.sessionList[i2];
            //         if (session.id === parseInt(action.session.id)) {
            //             state.sessionList[i2] = action.session
            //             break;
            //         }
            //     }
            //     return {
            //         sessionList: state.sessionList,
            //         session: action.session,
            //         total_count: state.total_count
            //     }
            // case Constants.PAGE_CHANGE:
            //     return {
            //         sessionList: state.sessionList,
            //         session: {},
            //         total_count: state.total_count
            //     }
        case Constants.SHOW_INLINE_ERROR_MSG:
            return {
                goodNewsList: state.goodNewsList,
                goodNews: state.goodNews,
                errors: action.errors,
                total_count: state.total_count
            }

        case Constants.SHOW_SERVER_ERROR_MSG:
            return {
                goodNewsList: state.goodNewsList,
                goodNews: state.goodNews,
                server_errors: action.errors,
                total_count: state.total_count
            }
        case Constants.RECEIVE_CREATE_GOOD_NEWS:
            return {
                add_success: true,
                message: action.message,
                goodNewsList: state.goodNewsList,
                goodNews: {}
            }
        case Constants.RECEIVE_DELETE_A_GOOD_NEWS:
            return {
                goodNewsList: state.goodNewsList.filter(function(goodNews) {
                    return goodNews.id !== parseInt(action.id);
                }),
                goodNews: state.goodNews,
                total_count: state.total_count - 1
            }
        case Constants.TOGGLE_SELECT:
            if (action.view_type === 'create' && !action.id) {
                return {
                    goodNewsList: state.goodNewsList,
                    goodNews: {},
                    total_count: state.total_count
                }
            } else {
                return state;
            }
        default:
            return state;
    }

}

export default goodNewsView;