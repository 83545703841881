import React from 'react';
import { connect } from "react-redux";
import { fetchABranch, deleteABranch } from '../../action/branch';
import { pageChange } from '../../action/view';
import { hoverEnter, hoverLeave } from '../../action/user';
import * as Constants from '../../Constants';
import Modal from 'react-responsive-modal';
import { Link } from "react-router-dom";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  pageChange,
  hoverEnter,
  hoverLeave,
  fetchABranch,
  deleteABranch
};
class ManageBranch extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handHoverEnter(e) {
    e.preventDefault();
    this.props.hoverEnter(e.currentTarget.getAttribute('data-id'));
  }

  handHoverLeave(e) {
    e.preventDefault();
    this.props.hoverLeave();
  }

  handleEdit(e) {
    e.preventDefault();
    this.props.fetchABranch(e.currentTarget.getAttribute('data-id'))
  }

  onPageChange() {
    this.props.pageChange(Constants.ADD_BRANCH);
  }

  onOpenModal = () => { this.setState({ open: true })};
  onCloseModal = () => { this.setState({ open: false})};

  handDelete(e) {
    e.preventDefault();
    this.props.deleteABranch(e.currentTarget.getAttribute('data-id'));
    this.onCloseModal();
  }
  
  render() {
    const branch = this.props.branchView.branch;
    const branchList = this.props.branchView.branchList;
    
    const branches = branchList.map((branch, key) => {
    return (
      <a href="#1" key={branch.id} data-id={branch.id}
          className={this.props.branchView.branch.id && this.props.branchView.branch.id === branch.id ? "active" : ""}
          onMouseEnter={this.handHoverEnter.bind(this)}
          onMouseLeave={this.handHoverLeave.bind(this)}
          onClick={this.handleEdit.bind(this)}>
        <div className="list-div">
          <div className="name inline">
            {branch.club + " - " + branch.program}
            <br />
            <span>{branch.users_count+ ' ' + (branch.users_count > 1 ? 'Users' : 'User')}</span>
          </div>
          <div className={this.props.userView.hover_id === branch.id ? 'button-div inline float-right' : 'button-div inline float-right display-none'}>
              <button onClick={this.onOpenModal.bind(this)} data-id={branch.id}>Delete</button>
              <button href="#1">Edit</button>
            </div>
            <div className={this.props.userView.hover_id === branch.id ? 'arrow-div inline float-right display-none' : 'arrow-div inline float-right'}>
              <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
            </div>
        </div>
      </a>
    );
  });
    return (
      <div className="manage-div normal-box float-left">
        <div className="manage-list" >
          <h1>Manage Branches</h1>
          <Link to="/add/branches" className="white-red-button" onClick={this.onPageChange.bind(this)}>{Constants.ADD_BRANCH}</Link>
          {branches}
        </div>
        <Modal open={this.state.open} onClose={this.onCloseModal.bind(this)} center>
          <div className="userModal">
            <p>Are you sure you want to delete</p>
            <p>{branch.name}</p>
            <div className="center">
              <button className="yesButton" data-id={branch.id} onClick={this.handDelete.bind(this)}>Yes</button>
              <button className="cancelButton" onClick={this.onCloseModal.bind(this)}>Cancel</button>
            </div>
          </div>
        </Modal>
      </div>
      
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageBranch);
