import React from 'react';
import { connect } from "react-redux";
import { pageChange } from '../../action/view';
import { fetchWorkplanList } from '../../action/workplan';
import * as Constants from '../../Constants';
import { toggleSelect } from '../../action/view';
import { Link } from "react-router-dom";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    fetchWorkplanList,
    pageChange,
    toggleSelect
};
class BranchList extends React.Component {

    constructor(props) {
        super(props);
        this.state =  {
            id: 0
        }
    }

    handBranchChange(e) {
        e.preventDefault();
        this.props.fetchWorkplanList(e.currentTarget.getAttribute('data-id'));
        this.setState({
            id:parseInt(e.currentTarget.getAttribute('data-id'))
        })
        this.props.onBranchChange(e.currentTarget.getAttribute('data-id'))
    }

    onPageChange(e) {
        this.props.pageChange(Constants.ADD_WORKPLAN);
    }

    render() {
        const user = JSON.parse(localStorage.getItem('user')).user;
        const branchList = this.props.branchView.branchList;
        let myCount = 0;

        branchList.map((branch, key) => {
            myCount += branch.my_workplan_count;
            return null;
        });
        const branches = branchList.map((branch, key) => {
            return (
                branch.workplan_count ?
                    <a href="#1" key={branch.id} data-id={branch.id} onClick={this.handBranchChange.bind(this)}>
                        <div className={"branch-name " + (this.state.id === branch.id ? "active" : "")}>
                            <div className="left">{branch.name}</div>
                            <span className="float-right">{branch.workplan_count}</span>
                        </div>
                    </a> : "");
        });
        return (
            <div className="branch-div float-left ab">
                <a href="#1" onClick={this.handBranchChange.bind(this)} data-id="0">
                    <div className={"branch-name " + (this.state.id === 0 ? "active" : "")}>
                        All Program
                      <span className="float-right">{this.props.workplanView.total_count}</span>
                    </div>
                </a>
                <div className="single-branch">
                    {branches}
                </div>
                <a href="#1" onClick={this.handBranchChange.bind(this)} data-id="-1">
                    <div className={"branch-name " + (this.state.id === -1 ? "active" : "")}>
                        My Program
                      <span className="float-right">{myCount}</span>
                    </div>
                </a>
                {user.role_id < Constants.GUEST ? 
                <Link to="/add/workplans" className="white-red-button" onClick={this.onPageChange.bind(this)}>Add New</Link> : ""}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BranchList);
