import axios from 'axios';
import * as Constants from '../Constants';
import * as Env from '../env';
import { getDateInUsFormat } from '../utils/date';

export const receivePerformanceData = (data, timeSelected, startDate, endDate) => ({
    type: Constants.RECEIVE_PERFORMANCE_DATA,
    data: data,
    timeSelected: timeSelected,
    startDate: startDate,
    endDate: endDate
});

// const fetchingData = () => ({
//     type: Constants.FETCHING_START + '_performance'
// });

// fetch overview data for dashboard
function fetchPerformanceDataAPI(time_id, branch_id, startDate, endDate) {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        //dispatch(fetchingData())
        try {
            const type = parseInt(time_id) + 1;
            const response = await axios.get(Env.API + 'dashboard/performance/' + type +
                (branch_id ? '?branch_id=' + branch_id : '') +
                (startDate ? (branch_id ? '&startDate=' : '?startDate=') + getDateInUsFormat(startDate) : '') +
                (endDate ? '&endDate=' + getDateInUsFormat(endDate) : ''), axiosConfig);

            const data = response.data;
            return dispatch(receivePerformanceData(data, time_id, startDate, endDate));
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

export function fetchPerformanceData(time_id = 0, branch_id = 0, startDate = null, endDate = null) {
    if (time_id === 5 && !startDate && !endDate) {
        return (dispatch) => {
            return dispatch(receivePerformanceData({ data: { summary: [], detail: [] } }, time_id));
        }
    } else {
        return (dispatch) => {
            return dispatch(fetchPerformanceDataAPI(time_id, branch_id, startDate, endDate))
        }
    }
}