import React from 'react';
import SelectFilterSurvey from './SelectFilterSurvey';
import ArrowNavigation from './ArrowNavigation';
import SelectSurvey from './SelectSurvey';
import * as Constants from '../../Constants';
import { getCustomAttributeSurvey } from '../../action/survey';
import { connect } from "react-redux";
import { updateAttributeField } from '../../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    getCustomAttributeSurvey,
    updateAttributeField
};

class FormStep extends React.Component {
    onHandleChange(e) {
        this.props.onHandleUpdateForm(e.currentTarget.getAttribute('type'), e.currentTarget.value);
    }

    updateSelectAttribute(e) {
        e.preventDefault();
        let field = e.currentTarget.getAttribute('data-field');
        let data = e.currentTarget.getAttribute('data-id');
    
        if(data) {
            this.props.updateAttributeField(field, Constants.SURVEY_VIEW, data);
        } else {
            this.props.updateAttributeField(field, Constants.SURVEY_VIEW, e.currentTarget.value);
        }
    }

    renderSwitch(attribute) {
        switch(attribute.attribute_type_id) {
          case 1: // multi select
            return (
              <div className="role attributes">
                {
                  attribute.attribute_options.map((option, key) => {
                    return (
                      <div className={"role-div " + (attribute.answer && attribute.answer.includes(option.id) ? 'active' : '')} key={key} onClick={this.updateSelectAttribute.bind(this)} data-id={option.id} data-field={attribute.id} data-type="Int">{option.option}</div>
                    )
                  })
                }
              </div>
            );
          case 2: // single select
            return (
              <div className="role attributes">
                {
                  attribute.attribute_options.map((option, key) => {
                    return (
                      <div className={"role-div " + (attribute.answer && attribute.answer.includes(option.id) ? 'active' : '')} key={key} onClick={this.updateSelectAttribute.bind(this)} data-id={option.id} data-field={attribute.id} data-type="Int">{option.option}</div>
                    )
                  })
                }
              </div>
            );
          case 3: // yes no question
            return (
              <div className="role attributes">
                <div className={"role-div " + (attribute.answer && attribute.answer.includes(1) ? 'active' : '')} data-id="1" onClick={this.updateSelectAttribute.bind(this)} data-field={attribute.id} data-type="Int">Yes</div>
                <div className={"role-div " + (attribute.answer && attribute.answer.includes(0) ? 'active' : '')} data-id="0" onClick={this.updateSelectAttribute.bind(this)} data-field={attribute.id} data-type="Int">No</div>
              </div>
            );
          case 4:
            return (
              <input className="narrow" data-field={attribute.id}
                      value={attribute.answer ? attribute.answer[0] : ''} onChange={this.updateSelectAttribute.bind(this)}
                      data-step={this.props.type === Constants.TYPE_DIRECT ? 4 : 1}></input>
            );
          default:
            return '';
        }
    }

    render() {
        let maxStep = Constants.MAX_SURVEY_STEP;
        if(this.props.surveyView.custom_attributes.length <= 0) {
            maxStep = maxStep - 1;
        }

        const stepName = () => {
            return (
                <div className="stepName">{'Step ' + this.props.currentStep + ' / ' + maxStep}</div>
            )
        }

        const stepDetail = (title, key, isFilter, isFirst, isLast) => {
            let filter = [];
            if(isFilter === true) {
                filter = <SelectFilterSurvey currentSelected={this.props.mainState[key]} onHandleUpdateForm={this.props.onHandleUpdateForm} type={key} />;
            } else {
                let list = Constants[key.toUpperCase()];

                if(list !== undefined) {
                    filter = <SelectSurvey currentSelected={this.props.mainState[key]} data={Object.entries(list)} onHandleUpdateForm={this.props.onHandleUpdateForm} type={key}/>;
                } else {
                    if(key === 'custom_attributes') {
                        let custom_attributes = this.props.surveyView.custom_attributes;
                        if(custom_attributes && custom_attributes.length > 0) {
                            filter = <div className="upsert-one-element">
                                {custom_attributes.map((attribute, key) => {
                                    return (<div className="field-group" key={key}>
                                    <div className="field">
                                        <label><span>{attribute.title}</span></label>
                                        {this.renderSwitch(attribute)}
                                    </div>
                                    </div>)
                                })}
                            </div>
                        } else {
                            filter = '';
                        }
                    } else {
                        filter = <textarea rows="10" placeholder={title} onChange={this.onHandleChange.bind(this)} key={key} type={key} name={key} style={{marginTop: '17px', marginBottom: '15px'}} value={this.props.mainState[key] ? this.props.mainState[key] : ""}/>
                    }
                }
            }

            return (
                <div className="stepContainer" key={key}>
                    {stepName()}
                    <div className="stepTitle">{title}</div>
                    {filter}
                    <ArrowNavigation isFirst={isFirst} isLast={isLast} stepType={key} onHandleNavigation={this.props.onHandleNavigation} />
                </div>
            )
        }

        let step = '';
        switch (this.props.currentStep) {
            case 1:
                step = stepDetail('What Club?', 'branch_id', true, true, false);
                break;
            
            case 2:
                step = stepDetail('What Activity?', 'activity_type_id', true, false, false);
                break;

            case 3:
                if(maxStep === 3) {
                    step = stepDetail('Rate the session', 'satisfaction', false, false, true);
                } else {
                    step = stepDetail('Rate the session', 'satisfaction', false, false, false);
                }
                
                break;

            case 4:
                if(maxStep === 3) {
                    step = ''
                } else {
                    step = stepDetail('Custom Attribute(s)', 'custom_attributes', false, false, true);
                }
                
                break;
        
            default:
                break;
        }

        return step
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormStep);
