import React from 'react';
import { connect } from "react-redux";
import { toggleSelect } from '../../action/view';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  toggleSelect,
};

class CreateGoodNews extends React.Component {
  
  toggleSelect(e){
    this.props.toggleSelect('create');
  }

  render() {
    const user = JSON.parse(localStorage.getItem('user')).user;
    return (
      <div className="goodnews upsert-one-element-div normal-box normal-box-margin float-left clickable" onClick={this.toggleSelect.bind(this)}>
        <div className="upsert-one-element" >
          <h1>Create Social Impacts</h1>
          <div className="user-logo-div">
            <div className="user-logo inline">{user.first_name.charAt(0) + user.last_name.charAt(0)}</div>
            <p className="inline share">Share the Social Impact…</p>
          </div>
          <div className="photo">
            <img src="/img/photo.svg" className="photo-icon inline" alt="Icon"></img>
            <h1 className="inline">Photo</h1>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateGoodNews);
