import React from 'react';
import { connect } from "react-redux";
import { pageChange } from '../../action/view';
import AddActivityForm from './AddActivityForm';
import AddSuccess from './AddSuccess';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  pageChange
};

class AddBranch extends React.Component {
  render() {
    return (
      <div className="AddActivity">
        {
          this.props.activityView.add_success ? <AddSuccess></AddSuccess> : <AddActivityForm></AddActivityForm>
        }
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBranch);
