import React from 'react';
import * as Constants from '../Constants';
import * as ENV from '../env';
import { pageChange } from '../action/view';
import { logout, updateModal } from '../action/user';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { fetchBranchList } from '../action/branch';
import { changeDashboardBranch, fetchAllDashboard } from '../action/view';


function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    logout,
    pageChange,
    updateModal,
    fetchBranchList,
    changeDashboardBranch,
    fetchAllDashboard
};
class Nav extends React.Component {

    constructor(props) {
        super(props);
        if (this.props.root.page === Constants.DASHBOARD && !this.props.branchView.branchList.length) {
            this.props.fetchBranchList();
        }
    }

    handleClick(e) {
        e.preventDefault();
        let page = e.target.getAttribute('data-page');
        let to = e.currentTarget.getAttribute('href');
        if (this.props.userView.block.isBlocking && this.props.root.page === Constants.USERS) {
            this.props.updateModal({
                isBlocking: true,
                blockOpen: true,
                block_id: page,
                type: 'page',
                to: to
            })
        } else {
            if (page === 'Logout') {
                this.props.logout();
                return;
            }
            this.props.pageChange(page);
            this.props.history.push(to);
        };
    }

    mobileLink() {
        localStorage.removeItem('desktop')
        window.location.href = ENV.MOBILE_APP;
    }

    branchChange(e) {
        this.props.changeDashboardBranch(parseInt(e.currentTarget.value))
        this.props.fetchAllDashboard(0, parseInt(e.currentTarget.value))
    }


    render() {
        const user = JSON.parse(localStorage.getItem('user')).user;

        let branches = this.props.root.page === Constants.DASHBOARD ? this.props.branchView.branchList.map((branch, key) => {
            return (
                <option key={key} value={branch.id}>{branch.name}</option>
            );
        }) : "";
        return (
            <div className="Nav">
                <img src="/img/logo.svg" alt="User Logo"></img>
                <div className="user-logo-div">
                    <div className="user-logo">{user.first_name.charAt(0) + user.last_name.charAt(0)}</div>
                    {/* <div className="nav-red-dot">99+</div> */}
                </div>
                <h1>Hello {user.first_name}!</h1>
                <p>{user.email}</p>
                {this.props.root.page === Constants.DASHBOARD || this.props.root.page === undefined ?
                    <p>Location:
                        <select className="location-selector" onChange={this.branchChange.bind(this)}>
                            <option value={0}>All</option>
                            {branches}
                        </select>
                    </p> : ""}
                <div className='nav-item-parent'>
                    <Link to="/dashboard" onClick={this.handleClick.bind(this)}>
                        <div className={this.props.root.page === Constants.DASHBOARD ? 'nav-item active' : 'nav-item'}
                            data-page={Constants.DASHBOARD}>{Constants.DASHBOARD}

                        </div>
                    </Link>
                    {user.role_id <= Constants.BRANCH_OFFICER ?
                        <Link to="/add/charts" onClick={this.handleClick.bind(this)} className="anchor-nav-item-child">
                            <div className={'nav-item-child'}
                                data-page={Constants.DASHBOARD}><span>+</span><span className="child-text">{Constants.ADD_CHART}</span></div>
                        </Link> : ""}
                </div>
                {user.role_id < Constants.BRANCH_MANAGER ?
                    <Link to="/activities" onClick={this.handleClick.bind(this)}>
                        <div className={this.props.root.page === Constants.ACTIVITIES ? 'nav-item active' : 'nav-item'}
                            data-page={Constants.ACTIVITIES}>{Constants.ACTIVITIES}</div>
                    </Link> : ""}
                <Link to="/sessions" onClick={this.handleClick.bind(this)}>
                    <div className={this.props.root.page === Constants.SESSIONS ? 'nav-item active' : 'nav-item'}
                        data-page={Constants.SESSIONS}>{Constants.SESSIONS}</div>
                </Link>
                {user.role_id < Constants.BRANCH_OFFICER ?
                    <Link to="/workplans" onClick={this.handleClick.bind(this)}>
                        <div className={this.props.root.page === Constants.WORKPLANS ? 'nav-item active' : 'nav-item'}
                            data-page={Constants.WORKPLANS}>{Constants.WORKPLANS}</div>
                    </Link> : ""}
                {user.role_id < Constants.BRANCH_OFFICER ?
                    <Link to="/users" onClick={this.handleClick.bind(this)}>
                        <div className={this.props.root.page === Constants.USERS ? 'nav-item active' : 'nav-item'}
                            data-page={Constants.USERS}>{Constants.USERS}</div>
                    </Link> : ""}
                <Link to="/good_news" onClick={this.handleClick.bind(this)}>
                    <div className={this.props.root.page === Constants.GOOD_NEWS ? 'nav-item active' : 'nav-item'}
                        data-page={Constants.GOOD_NEWS}>{Constants.GOOD_NEWS}</div>
                </Link>
                {user.role_id < Constants.BRANCH_MANAGER ?
                    <Link to="/branches" onClick={this.handleClick.bind(this)}>
                        <div className={this.props.root.page === Constants.BRANCHES ? 'nav-item active' : 'nav-item'}
                            data-page={Constants.BRANCHES}>{Constants.BRANCHES}</div>
                    </Link> : ""}
                <Link to="/surveys" onClick={this.handleClick.bind(this)}>
                    <div className={this.props.root.page === Constants.SURVEYS ? 'nav-item active' : 'nav-item'}
                        data-page={Constants.SURVEYS}>{Constants.SURVEYS}</div>
                </Link>
                {user.role_id <= Constants.SUPER_ADMIN ?
                    <>
                        <div className='nav-item-parent'>
                            <Link to="/attributes" onClick={this.handleClick.bind(this)}>
                                <div className={this.props.root.page === Constants.ATTRIBUTES ? 'nav-item active' : 'nav-item'}
                                    data-page={Constants.ATTRIBUTES}>{Constants.ATTRIBUTES}
                                </div>
                            </Link>
                            <Link to="/add/attributes" onClick={this.handleClick.bind(this)} className="anchor-nav-item-child">
                                <div className={'nav-item-child'}
                                    data-page={Constants.ATTRIBUTES}><span>+</span><span className="child-text">{Constants.ADD_ATTRIBUTE}</span></div>
                            </Link>
                        </div>
                    </>
                    : ""}

                {user.role_id <= Constants.ADMIN ?
                    <Link to="/custom_reports" onClick={this.handleClick.bind(this)}>
                        <div className={this.props.root.page === Constants.CUSTOM_REPORTS ? 'nav-item active' : 'nav-item'} data-page={Constants.CUSTOM_REPORTS}>{Constants.CUSTOM_REPORTS}
                        </div>
                    </Link>
                    : ''}

                {localStorage.getItem('desktop') ?
                    <a href="#8" onClick={this.mobileLink.bind(this)}>
                        <div className='nav-item'>Request Mobile Link</div>
                    </a> : ""}
                <a href="#8" onClick={this.handleClick.bind(this)}>
                    <div className='nav-item' data-page='Logout'>Log Out</div>
                </a>
            </div>
        );
    }
}
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Nav));
