import React from 'react';
import { connect } from "react-redux";
import { fetchUserList, updateModal } from '../../action/user';
import { pageChange, } from '../../action/view';
import * as Constants from '../../Constants';
import { Link } from "react-router-dom";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    fetchUserList,
    pageChange,
    updateModal
};
class BranchList extends React.Component {

    constructor(props) {
        super(props);
        this.state =  {
            id: 0
        }
    }
    
    handBranchChange(e) {
        e.preventDefault();
        this.props.fetchUserList(e.currentTarget.getAttribute('data-id'));
        this.setState({
            id:parseInt(e.currentTarget.getAttribute('data-id'))
        })
        this.props.onBranchChange(e.currentTarget.getAttribute('data-id'))
    }

    onPageChange(e) {
        if (this.props.userView.block.isBlocking){
            e.preventDefault();
            this.props.updateModal({ 
                isBlocking: true,
                blockOpen: true,
                block_id: Constants.ADD_USER,
                type: 'page',
                to: '/add/users'
            });
        }else{
            this.props.pageChange(Constants.ADD_USER);
        };
    }

    render() {
        const branchList = this.props.userView.branchList;
        const branches = branchList.map((branch, key) => {
            return (
            branch.users_count && !branch.deleted_at ?
                <a href="#1" key={branch.id} data-id={branch.id} onClick={this.handBranchChange.bind(this)}>
                    <div  className={"branch-name " + (this.state.id === branch.id ? "active" : "")}>
                        <div className="left">{branch.name}</div>
                        <span className="float-right">{branch.users_count}</span>
                    </div>
                </a> : "");
        });
        return (
            <div className="branch-div float-left">
                <a href="#1" onClick={this.handBranchChange.bind(this)} data-id="0">
                    <div className={"branch-name " + (this.state.id === 0 ? "active" : "")}>
                        All Users
                      <span className="float-right">{this.props.userView.total_count}</span>
                    </div>
                </a>
                <div className="single-branch">
                    {branches}
                </div>
                <Link to="/add/users" className="white-red-button" onClick={this.onPageChange.bind(this)}>Add New</Link>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BranchList);
