import * as Constants from '../Constants';

const chartView = (state = Constants.chartViewInitialState, action) => {
    switch (action.type) {
        case Constants.RECEIVE_ALL_CHART:
            return {
                chartList: action.charts,
                message: action.message
            }
        case Constants.RECEIVE_CREATE_CHART:
            return {
                chartList: action.charts,
                message: action.message,
                add_success: true
            }
        default:
            return state;
    }
}

export default chartView;