import axios from 'axios';
// import { fetchingStart } from './view';
import { fetchBranchList } from './branch';
import * as Constants from '../Constants';

export const receiveSurveyList = (data) => ({
    type: Constants.RECEIVE_SURVEY_LIST,
    surveyList: data.data.survey,
    total_count: data.data.total_count
});

export const receiveSurveyListBranch = (data) => ({
    type: Constants.RECEIVE_SURVEY_LIST_BRANCH,
    surveyList: data.data.survey,
    total_count: data.data.total_count
});

export const hoverEnter = (hover_id) => ({
    type: Constants.HOVER_ENTER,
    hover_id: hover_id
});
export const hoverLeave = () => ({ type: Constants.HOVER_LEAVE });

export const showServerErrorMsg = (errors) => ({
    type: Constants.SHOW_SERVER_ERROR_MSG,
    errors: errors.error.messages,
});

export const updateModal = (block) => ({
    type: Constants.UPDATE_MODAL,
    block: block
});

export const fetchSurvey = (data) => ({
    type: Constants.FETCH_SURVEY,
    survey: data
});

export const receiveCreateSurvey = (data) => ({
    type: Constants.RECEIVE_CREATE_SURVEY,
    survey: data.data.survey,
    message: data.data.message
});

export const receiveDeleteASurvey = (data) => ({
    type: Constants.RECEIVE_DELETE_A_SURVEY,
    survey: data
});

export const getCustomAttribute = (attributes) => ({
    type: Constants.GET_SURVEY_CUSTOM_ATTRIBUTE,
    custom_attributes: attributes
})

export const removeErrorMsg = () => ({ type: Constants.REMOVE_ERROR_MSG });

export function getCustomAttributeSurvey(branch_id, activity_id, attached_to) {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        try {
            // dispatch(fetchingStart())
            let response = await axios.get(Constants.API + 'attribute/get?attached_to=' + attached_to + '&branches=' + branch_id + '&activities=' + activity_id, axiosConfig)
            const data = response.data.data;
            dispatch(getCustomAttribute(data.attributes));
        } catch (error) {
            if (error.response.data.error.code === 1) {
                localStorage.removeItem('user');
                window.location.reload();
            }
            dispatch(showServerErrorMsg(error.response.data))

        }
    }
}

function fetchSurveyListAPI(branch_id = 0, start = 0, count = Constants.MAX_COUNT) {

    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        // dispatch(fetchingStart())
        try {
            let user_id = '';

            if (branch_id === "-1") {
                user_id = JSON.parse(localStorage.getItem('user')).user.id;
                branch_id = '';
            }

            const response = await axios.get(Constants.API + 'survey/list?start=' + start + '&count=' + count + (branch_id ? '&branch_id=' + branch_id : '') + (user_id ? '&user_id=' + user_id : ''), axiosConfig);
            const data = response.data;
            if(start === 0) {
                return dispatch(receiveSurveyListBranch(data));
            } else {
                return dispatch(receiveSurveyList(data));
            }
            
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

function createSurveyAPI(survey) {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        // dispatch(fetchingStart())
        try {
            const response = await axios.post(Constants.API + 'survey/create', survey, axiosConfig);
            const data = response.data;
            return dispatch(receiveCreateSurvey(data));
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
            dispatch(showServerErrorMsg(error.response.data));
        }
    }
}

function deleteASurveyAPI(survey_id, branch_id, page) {

    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        // dispatch(fetchingStart())
        try {
            const response = await axios.get(Constants.API + 'survey/delete/' + survey_id, axiosConfig);
            const data = response.data;
            dispatch(receiveDeleteASurvey(data));
            dispatch(fetchSurveyListAPI(branch_id, 0, page * Constants.MAX_COUNT));
            dispatch(fetchBranchList());
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

export function deleteASurvey(survey_id = 0, branch_id = 0, page = 1) {
    return (dispatch) => {
        return dispatch(deleteASurveyAPI(survey_id, branch_id, page))
    }
}


export function fetchSurveyList(branch_id = 0, start = 0, count = 20) {
    return (dispatch) => {
        return dispatch(fetchSurveyListAPI(branch_id, start, count))
    }
}

export function createSurvey(data) {
    return (dispatch) => {
        return dispatch(createSurveyAPI(data))
    }
}