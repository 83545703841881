import * as Constants from '../Constants';

const userView = (state = Constants.userViewInitialState, action) => {
    let user = state.user;
    switch (action.type) {
        case Constants.RECEIVE_BRANCH_LIST:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: action.branchList,
                userList: state.userList,
                user: state.user
            }
        case Constants.RECEIVE_USER_LIST:
            return {
                add_success: false,
                block: state.block,
                total_count: action.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                userList: action.userList,
                user: state.user
            }
        case Constants.HOVER_ENTER:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: parseInt(action.hover_id),
                branchList: state.branchList,
                userList: state.userList,
                user: state.user
            }
        case Constants.HOVER_LEAVE:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: 0,
                branchList: state.branchList,
                userList: state.userList,
                user: state.user
            }
        case Constants.RECEIVE_A_USER:
            return {
                add_success: false,
                block: {
                    isBlocking: false,
                    blockOpen: false,
                    block_id: 0
                },
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                userList: state.userList,
                user: action.user
            }
        case Constants.RECEIVE_DELETE_A_USER:
            return {
                add_success: false,
                block: state.block,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                userList: state.userList,
                user: {}
            }
        case Constants.CHANGE_FIRST_NAME:
            user.first_name = action.first_name;
            return {
                add_success: false,
                block: {
                    isBlocking: true,
                    blockOpen: false,
                    block_id: 0
                },
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                userList: state.userList,
                user: user
            }
        case Constants.CHANGE_LAST_NAME:
            user.last_name = action.last_name;
            return {
                add_success: false,
                block: {
                    isBlocking: true,
                    blockOpen: false,
                    block_id: 0
                },
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                userList: state.userList,
                user: user
            }
        case Constants.CHANGE_EMAIL:
            user.email = action.email;
            return {
                add_success: false,
                block: {
                    isBlocking: true,
                    blockOpen: false,
                    block_id: 0
                },
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                userList: state.userList,
                user: user
            }
        case Constants.CHANGE_PHONE:
            user.phone = action.phone;
            return {
                add_success: false,
                block: {
                    isBlocking: true,
                    blockOpen: false,
                    block_id: 0
                },
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                userList: state.userList,
                user: user
            }
        case Constants.CHANGE_PASSWORD:
            user.password = action.password;
            return {
                add_success: false,
                block: {
                    isBlocking: true,
                    blockOpen: false,
                    block_id: 0
                },
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                userList: state.userList,
                user: user
            }
        case Constants.ASSIGN_USER_BRANCHES:
            user.branch = action.branches;
            user.all_branches = action.all;
            return {
                add_success: false,
                block: {
                    isBlocking: true,
                    blockOpen: false,
                    block_id: 0
                },
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                userList: state.userList,
                user: user
            }
        case Constants.CHANGE_ROLE:
            user.role_id = parseInt(action.role_id);
            return {
                add_success: false,
                block: {
                    isBlocking: true,
                    blockOpen: false,
                    block_id: 0
                },
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                userList: state.userList,
                user: user
            }
        case Constants.RECEIVE_UPDATE_USER:
            let userList = state.userList;
            for (let i = 0; i < userList.length; i++) {
                if (userList[i].id === action.user.id) {
                    userList[i] = action.user
                    break;
                }
            }
            return {
                add_success: false,
                block: {
                    isBlocking: false,
                    blockOpen: false,
                    block_id: 0
                },
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                userList: userList,
                user: action.user
            }
        case Constants.RECEIVE_CREATE_USER:
            return {
                add_success: true,
                block: state.block,
                message: action.message,
                total_count: state.total_count,
                hover_id: action.user.id,
                branchList: state.branchList,
                userList: state.userList,
                user: action.user
            }
        case Constants.SHOW_INLINE_ERROR_MSG:
            return {
                add_success: false,
                block: state.block,
                errors: action.errors,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                userList: state.userList,
                user: state.user
            }

        case Constants.SHOW_SERVER_ERROR_MSG:
            return {
                add_success: false,
                block: state.block,
                server_errors: action.errors,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                userList: state.userList,
                user: state.user
            }
        case Constants.PAGE_CHANGE:
            return {
                add_success: false,
                total_count: state.total_count,
                block: {
                    isBlocking: false,
                    blockOpen: false,
                    block_id: 0
                },
                message: "",
                hover_id: 0,
                branchList: state.branchList,
                userList: state.userList,
                user: {}
            }
        case Constants.UPDATE_MODAL:
            return {
                add_success: false,
                block: action.block,
                server_errors: state.errors,
                total_count: state.total_count,
                hover_id: state.hover_id,
                branchList: state.branchList,
                userList: state.userList,
                user: state.user
            }
        default:
            return state;
    }
}

export default userView;