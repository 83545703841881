import React from 'react';
import { connect } from "react-redux";
import * as Constants from '../../Constants';
import { pageChange } from '../../action/view';
import { Link } from "react-router-dom";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageChange
};
class AddSuccess extends React.Component {
    returnToSurveys(e) {
        this.props.pageChange(Constants.SURVEYS);
    }

    logAnother(e) {
        this.props.onLogAnother();
    }

    render() {
        return (
            <div className="add-user-div normal-box float-left">
                <div className="add-user normal-margin center">
                    <img alt="Pistive Vote" src="/img/positive-vote.svg" className="postive-vote"></img>
                    <p>The survey has been logged.</p>
                    <Link to="/add/surveys" className="white-red-button" onClick={this.logAnother.bind(this)}>Log Another</Link>
                        <br/>
                    <Link to="/surveys" onClick={this.returnToSurveys.bind(this)}><img src="/img/arrow-left-red.svg" alt="Arrow Left" className="arrow"></img>Return to Surveys</Link>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSuccess);
