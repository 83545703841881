import React from 'react';
import { connect } from "react-redux";
import { fetchAnActivity, deleteAnActivity } from '../../action/activity';
import { pageChange } from '../../action/view';
import { hoverEnter, hoverLeave } from '../../action/user';
import * as Constants from '../../Constants';
import Modal from 'react-responsive-modal';
import { Link } from "react-router-dom";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  pageChange,
  hoverEnter,
  hoverLeave,
  fetchAnActivity,
  deleteAnActivity
};
class ManageActivity extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchActivities: this.props.activityView.activityList,
      open: false,
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.activityView.activityList !== prevProps.activityView.activityList) {
      this.setState({
        ...this.state,
        searchActivities: this.props.activityView.activityList,
      })
    }
  }

  handHoverEnter(e) {
    e.preventDefault();
    this.props.hoverEnter(e.currentTarget.getAttribute('data-id'));
  }

  handHoverLeave(e) {
    e.preventDefault();
    this.props.hoverLeave();
  }

  handleEdit(e) {
    e.preventDefault();
    this.props.fetchAnActivity(e.currentTarget.getAttribute('data-id'))
  }

  onPageChange() {
    this.props.pageChange(Constants.ADD_ACTIVITY);
  }

  onOpenModal = () => { this.setState({ open: true }) };
  onCloseModal = () => { this.setState({ open: false }) };

  handDelete(e) {
    e.preventDefault();
    this.props.deleteAnActivity(e.currentTarget.getAttribute('data-id'));
    this.onCloseModal();
  }

  search(e) {
    this.setState({
      ...this.state,
      searchActivities: this.props.activityView.activityList.filter(function (activity) {
        return activity.name.toLowerCase().includes(e.currentTarget.value.toLowerCase())
      })
    })
  }

  render() {
    const activity = this.props.activityView.activity;
    const activityList = this.state.searchActivities;
    const activities = activityList.map((activity, key) => {
      return (
        <a href="#1" key={activity.id} data-id={activity.id}
          className={this.props.activityView.activity.id && this.props.activityView.activity.id === activity.id ? "active" : ""}
          onMouseEnter={this.handHoverEnter.bind(this)}
          onMouseLeave={this.handHoverLeave.bind(this)}
          onClick={this.handleEdit.bind(this)}>
          <div className="list-div">
            {activity.image_url ? <div className="image-circle inline"><img className="img" src={activity.image_url} alt={activity.name} /></div>
              : <div className="user-logo-div inline">
                <div className="user-logo">
                  {activity.abbreviation}
                </div>
              </div>}
            <div className="name inline">
              {activity.name}
              <br />
              <span>{activity.type}</span>
            </div>
            <div className={this.props.userView.hover_id === activity.id ? 'button-div inline float-right' : 'button-div inline float-right display-none'}>
              <button onClick={this.onOpenModal.bind(this)} data-id={activity.id}>Delete</button>
              <button href="#1">Edit</button>
            </div>
            <div className={this.props.userView.hover_id === activity.id ? 'arrow-div inline float-right display-none' : 'arrow-div inline float-right'}>
              <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
            </div>
          </div>
        </a>
      );
    });
    return (
      <div>
        <div className="search-field activity-search">
          <input placeholder="Search Activities" onChange={this.search.bind(this)}></input>
          <img src="/img/search-icon.svg" alt="Search Icon" className="search-icon"></img>
        </div>
        <div className="manage-div normal-box float-left">
          <div className="manage-list" >
            <h1>Manage Activities</h1>
            <Link to="/add/activities" className="white-red-button" onClick={this.onPageChange.bind(this)}>{Constants.ADD_ACTIVITY}</Link>
            {activities}
          </div>
          <Modal open={this.state.open} onClose={this.onCloseModal.bind(this)} center>
            <div className="userModal">
              <p>Are you sure you want to delete</p>
              <p>{activity.name}</p>
              <div className="center">
                <button className="yesButton" data-id={activity.id} onClick={this.handDelete.bind(this)}>Yes</button>
                <button className="cancelButton" onClick={this.onCloseModal.bind(this)}>Cancel</button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageActivity);
