import React from 'react';
import { connect } from "react-redux";
import * as Constants from '../../Constants';
import { pageChangeWithNoStateChange, pageChange } from '../../action/view';
import { Link } from "react-router-dom";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageChange,
    pageChangeWithNoStateChange
};
class EditUser extends React.Component {
    returnToUsers(e){
        this.props.pageChange(Constants.USERS);
    }

    editUserButton(e){
        this.props.pageChangeWithNoStateChange(Constants.USERS);
    }

    render() {
        return (
            <div className="add-user-div normal-box float-left">
                <div className="add-user normal-margin center">
                    <img alt="Pistive Vote" src="/img/positive-vote.svg" className="postive-vote"></img>
                    <p>{this.props.userView.message ? this.props.userView.message: ""}</p>
                    <Link to="/users" className="white-red-button" onClick={this.editUserButton.bind(this)}>Edit User</Link>
                    <br/>
                    <Link to="/users" onClick={this.returnToUsers.bind(this)}><img src="/img/arrow-left-red.svg" alt="Arrow Left" className="arrow"></img>Return to users</Link>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditUser);
