import React from 'react';
import { connect } from "react-redux";
import Nav from './view/Nav';
import ExportPopup from './view/ExportPopup';
import Breadcrumbs from './view/Breadcrumbs'
import Dashboard from './view/dashboard/Dashboard'
import Activities from './view/activity/Activities';
import AddActivity from './view/activity/AddActivity';
import Sessions from './view/session/Sessions';
import AddSession from './view/session/AddSession';
import Workplans from './view/workplan/Workplans'
import AddWorkplan from './view/workplan/AddWorkplan'
import Users from './view/user/Users'
import AddUser from './view/user/AddUser'
import GoodNews from './view/goodnews/GoodNews'
import Branches from './view/branch/Branches'
import AddBranch from './view/branch/AddBranch'
import Surveys from './view/surveys/Surveys'
import AddSurvey from './view/surveys/AddSurvey'
import Attributes from './view/attributes/Attributes'
import AddAttribute from './view/attributes/AddAttribute'
import CustomReports from './view/custom_reports/CustomReports'
import AddChart from './view/dashboard/AddChart'
import Login from './view/login/Login'
import Signup from './view/login/Signup'
import ResetPassword from './view/login/ResetPassword'
import ForgetPassword from './view/login/ForgetPassword'
import * as Constants from './Constants';
import './sass/main.scss';
import queryString from 'query-string';
import { Switch, BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import {isMobile} from 'react-device-detect';
import * as ENV from './env';
import { getMe } from './action/user';
import { pageChange } from './action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    getMe,
    pageChange
};


class App extends React.Component {

    constructor(props) {
        super(props);
        let url = window.location.href;
        let params = queryString.parse(url);
        let current_url = Object.keys(params)[0].replace(ENV.APP, '');
        let url_array = current_url.split('/').filter(function(el){return el !== ''}).join('-')
        this.props.pageChange(Constants.PAGE_URL[url_array])

        if (isMobile && params.view !== 'desktop' && !localStorage.getItem('desktop') ) {
            document.location = ENV.MOBILE_APP
        }
        
        if (params.view === 'desktop'){
            localStorage.setItem('desktop', 1);
            
            if (!localStorage.getItem('user')){
                this.props.getMe(params.access_token);
            }
        }
       
        this.state = {
            page: params.token ? Constants.RESET_PASSWORD : Constants.LOGIN,
        }
    }

    handleRegisterClick() {
        this.setState({
            page: Constants.SIGNUP
        })
    }

    handleSignInClick() {
        this.setState({
            page: Constants.LOGIN
        })
    }

    handleForgetPasswordClick() {
        this.setState({
            page: Constants.FORGET_PASSWORD
        })
    }


    render() {
        return (
            <Router>
                <div>
                    <div className={this.props.root.overlay ? "overlay active" : "overlay"}></div>
                    {this.props.root.isFetching ?
                    <img src="/img/Rolling.svg" className="loading-img" alt="Loading"></img> : "" }
                    <div className={this.props.root.isFetching ? "overlay active" : "overlay"}></div>
                    {localStorage.getItem('user') ?
                        <div className="App appcontainer" >
                            <Nav></Nav>
                            <div className="Main" >
                                <Breadcrumbs page={this.props.root.page}> </Breadcrumbs>
                                <Switch>
                                    <Route path="/dashboard/" component={Dashboard} />
                                    <Route path="/activities/" component={Activities} />
                                    <Route path="/add/activities/" component={AddActivity} />
                                    <Route path="/sessions/" component={Sessions} />
                                    <Route path="/add/sessions/direct/" render={props => <AddSession {...props} type={Constants.TYPE_DIRECT} />} />
                                    <Route path="/add/sessions/indirect/" render={props => <AddSession {...props} type={Constants.TYPE_INDIRECT} />} />
                                    <Route path="/workplans/" component={Workplans} />
                                    <Route path="/add/workplans/" component={AddWorkplan} />
                                    <Route path="/users/" component={Users} />
                                    <Route path="/add/users/" component={AddUser} />
                                    <Route path="/good_news/" component={GoodNews} />
                                    <Route path="/branches/" component={Branches} />
                                    <Route path="/add/branches/" component={AddBranch} />
                                    <Route path="/surveys/" component={Surveys} />
                                    <Route path="/add/surveys/" component={AddSurvey} />
                                    <Route path="/attributes/" component={Attributes} />
                                    <Route path="/add/attributes/" component={AddAttribute} />
                                    <Route path="/add/charts/" component={AddChart} />
                                    <Route path="/custom_reports/" component={CustomReports} />
                                    <Redirect to="/dashboard" />
                                </Switch>
                                {this.props.root.overlay && this.props.root.view_type === 'export' ?
                                    <ExportPopup></ExportPopup>
                                    : ""}
                            </div>
                        </div> :
                        <div>
                            <Switch>
                                <Route path="/login" render={props => <Login {...props} onRegisterClick={this.handleRegisterClick.bind(this)} onForgetPasswordClick={this.handleForgetPasswordClick.bind(this)} />} />
                                <Route path="/signup/" render={props => <Signup {...props} onSignInClick={this.handleSignInClick.bind(this)} />} />
                                <Route path="/forget_password/" render={props => <ForgetPassword {...props} onSignInClick={this.handleSignInClick.bind(this)} />} />
                                <Route path="/reset_password/" component={ResetPassword} />
                                <Redirect to="/login" />
                            </Switch>
                        </div>}
                </div>
            </Router>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);


