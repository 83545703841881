import React from 'react';
import { connect } from "react-redux";
import ManageBranch from './ManageBranch';
import EditBranchForm from './EditBranchForm';
import SelectUser from './SelectUser';
import { fetchBranchList } from '../../action/branch';
import { fetchUserList } from '../../action/user';

function mapStateToProps(state) {
    return state;
  }
  
  const mapDispatchToProps = {
    fetchBranchList,
    fetchUserList
  };
class Branches extends React.Component {

    constructor(props) {
        super(props);
        this.props.fetchBranchList();
        this.props.fetchUserList();
    }
    render() {
        return (
            <div className="Branches">
                <ManageBranch></ManageBranch>
                {this.props.branchView.branch.id ? 
                    this.props.root.overlay ?  
                    <SelectUser></SelectUser> : 
                    <EditBranchForm></EditBranchForm>
                : ""}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Branches);
