import React from 'react';
import { connect } from "react-redux";
import { fetchAGoodNews } from '../../action/goodNews';
import Modal from 'react-responsive-modal';
import { timeDifference } from '../../utils/date';
import { toggleSelect } from '../../action/view';
import * as Constants from '../../Constants';
import { selectGoodNewsBranch, getCustomAttributeGoodNews } from '../../action/goodNews';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  fetchAGoodNews,
  toggleSelect,
  selectGoodNewsBranch,
  getCustomAttributeGoodNews
};
class GoodNewsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      editBox: 0
    };
  }

  toggleEditBox(e) {
    this.setState({
      open: this.state.open,
      editBox: parseInt(e.currentTarget.getAttribute('data-id'))
    })

  }

  handleClick(e) {
    if(!e.target.getAttribute('data-type')){
      this.setState({
          open: this.state.open,
          editBox: 0
        })
    }
  }

  componentDidMount() {
    // Detect when scrolled to bottom.
    this.refs.scroll.addEventListener("scroll", () => {
      if (
        this.refs.scroll.scrollTop + this.refs.scroll.clientHeight >=
        this.refs.scroll.scrollHeight
      ) {
        this.props.loadMore();
      }
    });

    document.addEventListener("mousedown", this.handleClick.bind(this));


  }

  componentWillUnmount() {
    // return function to be called when unmounted
    document.removeEventListener("mousedown", this.handleClick.bind(this));
  }

  handDelete(e) {
    e.preventDefault();
    this.props.handleDelete(this.state.id);
    this.onCloseModal();
  }

  handleEdit(e) {
    e.preventDefault();
    this.props.fetchAGoodNews(e.currentTarget.getAttribute('data-id'));
    this.props.toggleSelect('update')
  }

  selectBranch(e) {
    if (Object.keys(this.state.goodNewsBranch).length > 0){
        this.props.selectGoodNewsBranch(this.state.goodNewsBranch);
        this.props.getCustomAttributeGoodNews(this.state.goodNewsBranch.id, Constants.ATTACHED_TO_TEXT[3]);
    }
    this.props.toggleSelect();
    this.props.toggleSelect(this.props.action, 1);
  }

  onOpenModal = (e) => { this.setState({ open: true, editBox: this.state.editBox, id: e.currentTarget.getAttribute('data-id') }) };
  onCloseModal = () => { this.setState({ open: false, editBox: this.state.editBox }) };

  download(e) {
    e.preventDefault();
    window.open(
      Constants.API + 'report/newsPdf?id='+e.currentTarget.getAttribute('data-id')+'&token=' + JSON.parse(localStorage.getItem('user')).access_token,
      '_blank' // <- This is what makes it open in a new window.
  );
  }

  render() {
    const user = JSON.parse(localStorage.getItem('user')).user;
    const branch = user.branch.map(branch => branch.id)
    const goodNewsList = this.props.goodNewsView.goodNewsList;

    const goodNewses = goodNewsList.map((goodNews, key) => {
      return (
        <div className="list-div normal-box reltv" key={key}>
          <div className="user-logo-div inline">
            <div className="user-logo">
              {goodNews.user.first_name.charAt(0) + goodNews.user.last_name.charAt(0)}
            </div>
          </div>
          <div className="name inline">
            {goodNews.user.first_name + ' ' + goodNews.user.last_name}
            <br />
            <span>{timeDifference(goodNews.updated_at) + " . " + goodNews.branch.name}</span>
          </div>
          {(user.role_id < Constants.GUEST && user.role_id >  Constants.ADMIN &&  branch.includes(goodNews.branch_id)) || user.role_id <= Constants.ADMIN ? 
          <img src="/img/dot-black.svg" alt="Dot Black" className="dot-black float-right clickable" data-id={goodNews.id} onClick={this.toggleEditBox.bind(this)}></img>
          :""}
          {this.state.editBox === goodNews.id ?
            <div className="edit-box ab" ref="node" data-type="1">
              <p className="clickable" data-type="1" data-id={goodNews.id} onClick={this.handleEdit.bind(this)}>Edit</p>
              <p className="clickable" data-type="1" data-id={goodNews.id} onClick={this.onOpenModal.bind(this)}>Delete</p>
              <p className="clickable" data-type="1" data-id={goodNews.id} onClick={this.download.bind(this)}>Download</p>
            </div> : ""}
          <div className="feedContent">
            <div className="feedThumb">
              <img className="feedThumbContainer" src={goodNews.image_url} alt={goodNews.title}></img>
            </div>
            <div className="feedTitle">
              <p><strong>{goodNews.title}</strong></p>
            </div>
            <div className="feedExcerpt">
              <p>{goodNews.description}</p>
            </div>
            <br/>
            {
              goodNews.attribute_goodnews.map((attr, key) =>  (
                  <div className="feedExcerpt" key={key} style={{marginBottom: '10px'}}>
                      <p><strong>{attr.title}</strong></p>
                      <p>{attr.answer ? attr.answer : ''}</p>
                  </div>
                )
              )
            }
          </div>
        </div>
      );
    });
    return (
      <div className="manage-div normal-box-margin-1 float-left" ref="scroll">
        <div className="manage-list" >
          {goodNewses}
        </div>
        <Modal open={this.state.open} onClose={this.onCloseModal.bind(this)} center>
          <div className="userModal">
            <p>Are you sure you want to delete this Social Impact</p>
            <div className="center">
              <button className="yesButton" onClick={this.handDelete.bind(this)}>Yes</button>
              <button className="cancelButton" onClick={this.onCloseModal.bind(this)}>Cancel</button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoodNewsList);
