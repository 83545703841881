import * as Constants from '../Constants';

const customReportView = (state = Constants.customeReportView, action) => {
    switch (action.type) {
        case Constants.REPORT_RECEIVE_ATTRIBUTE_LIST:
            return {
                add_success: false,
                block: state.block,
                hover_id: state.hover_id,
                attributeList: [...state.attributeList, ...action.attributeList]
            }
        case Constants.REPORT_RECEIVE_ATTRIBUTE_LIST_BRANCH:
            return {
                add_success: false,
                block: state.block,
                hover_id: state.hover_id,
                attributeList: action.attributeList
            }    
        case Constants.HOVER_ENTER:
            return {
                add_success: false,
                block: state.block,
                hover_id: parseInt(action.hover_id),
                attributeList: state.attributeList
            }
        case Constants.HOVER_LEAVE:
            return {
                add_success: false,
                block: state.block,
                hover_id: 0,
                attributeList: state.attributeList
            }
        case Constants.PAGE_CHANGE:
            return {
                add_success: false,
                block: {
                    isBlocking: false,
                    blockOpen: false,
                    block_id: 0
                },
                message: "",
                total_count: 0,
                hover_id: 0,
                attributeList: state.attributeList
            }
        case Constants.UPDATE_MODAL:
            return {
                add_success: false,
                block: action.block,
                hover_id: state.hover_id,
                attributeList: state.attributeList
            }
        default:
            return state;
    }
}

export default customReportView;