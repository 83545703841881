import axios from 'axios';
// import { fetchingStart } from './view';
import { fetchBranchList } from './branch';
import * as Constants from '../Constants';

export const receiveAttributeList = (data) => ({
    type: Constants.RECEIVE_ATTRIBUTE_LIST,
    attributeList: data.data.attributes,
    total_count: data.data.total_count
});

export const receiveAttributeListBranch = (data) => ({
    type: Constants.RECEIVE_ATTRIBUTE_LIST_BRANCH,
    attributeList: data.data.attributes,
    total_count: data.data.total_count
});

export const hoverEnter = (hover_id) => ({
    type: Constants.HOVER_ENTER,
    hover_id: hover_id
});
export const hoverLeave = () => ({ type: Constants.HOVER_LEAVE });

export const showServerErrorMsg = (errors) => ({
    type: Constants.SHOW_SERVER_ERROR_MSG,
    errors: errors.error.messages,
});

export const updateModal = (block) => ({
    type: Constants.UPDATE_MODAL,
    block: block
});

export const fetchAttribute = (data) => ({
    type: Constants.FETCH_ATTRIBUTE,
    attribute: data
});

export const receiveCreateAttribute = (data) => ({
    type: Constants.RECEIVE_CREATE_ATTRIBUTE,
    attribute: data.data.attribute,
    message: data.data.message
});

export const receiveUpdateAttribute = (data) => ({
    type: Constants.RECEIVE_UPDATE_ATTRIBUTE,
    attribute: data.data.attribute,
    message: data.data.message
});

export const receiveDeleteAAttribute = (data) => ({
    type: Constants.RECEIVE_DELETE_A_ATTRIBUTE,
    attribute: data
});

export const handleChangeAttribute = (data) => ({
    type: Constants.ATTRIBUTE_CHANGE,
    attribute: data
});

export const selectAttributeActivity = (data) => ({
    type: Constants.ATTRIBUTE_ACTIVITY_CHANGE,
    activities: data
})

export const selectAttributeBranch = (data) => ({
    type: Constants.ATTRIBUTE_BRANCH_CHANGE,
    branches: data
})

export const removeErrorMsg = () => ({ type: Constants.REMOVE_ERROR_MSG });

function fetchAttributeListAPI(str = '', branch_id = '', activity_id = '', start = 0, count = Constants.MAX_COUNT) {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        // dispatch(fetchingStart())
        try {
            const response = await axios.get(Constants.API + 'attribute/list?str=' + str + '&branches=' + branch_id + '&activities=' + activity_id + '&start=' + start + '&count=' + count, axiosConfig);
            const data = response.data;

            if (start === 0) {
                return dispatch(receiveAttributeListBranch(data));
            } else {
                return dispatch(receiveAttributeList(data));
            }
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

function createAttributeAPI(atttribute) {

    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        // dispatch(fetchingStart())
        try {
            const response = await axios.post(Constants.API + 'attribute/create', atttribute, axiosConfig);
            const data = response.data;
            return dispatch(receiveCreateAttribute(data));
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
            dispatch(showServerErrorMsg(error.response.data));
        }
    }
}

function updateAttributeAPI(attribute) {

    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };

        /*
        {
            "id": "3",
            "title": "test attribute",
            "description": "",
            "attribute_type_id":"1",
            "attached_to": "session",
            "branch_id": ["2"],
            "activities":[
                    "1", "3"
            ],
            "options":[
                    "this", "that", "there", "test those"
            ]
        }*/
        attribute.branch_id = [];
        if (attribute.branches && Array.isArray(attribute.branches)) {
            attribute.branches.forEach(branch => {
                attribute.branch_id.push(branch.id)
            });
        }

        attribute.options = [];
        if (attribute.attribute_options && Array.isArray(attribute.attribute_options)) {
            attribute.attribute_options.forEach(option => {
                attribute.options.push(option.option)
            });
        }

        let activities = [];
        if (attribute.activities && Array.isArray(attribute.activities)) {
            attribute.activities.forEach(activity => {
                activities.push(activity.id)
            });

            attribute.activities = activities;
        }

        // dispatch(fetchingStart())
        try {
            const response = await axios.post(Constants.API + 'attribute/edit', attribute, axiosConfig);
            const data = response.data;
            return dispatch(receiveUpdateAttribute(data));
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
            dispatch(showServerErrorMsg(error.response.data));
        }
    }
}

function deleteAAttributeAPI(attribute_id, branch_id, page) {

    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        // dispatch(fetchingStart())
        try {
            const response = await axios.get(Constants.API + 'attribute/delete/' + attribute_id, axiosConfig);
            const data = response.data;
            dispatch(receiveDeleteAAttribute(data));
            alert(data.data.message)
            dispatch(fetchAttributeListAPI('', branch_id, '', 0, page * Constants.MAX_COUNT));
            dispatch(fetchBranchList());
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

export function deleteAAttribute(attribute_id = '', branch_id = 0, page = 1) {
    return (dispatch) => {
        return dispatch(deleteAAttributeAPI(attribute_id, branch_id, page))
    }
}


export function fetchAttributeList(str = '', branch_id = '', activity_id = '', start = 0, count = 20) {
    return (dispatch) => {
        return dispatch(fetchAttributeListAPI(str, branch_id, activity_id, start, count))
    }
}

export function createAttribute(data) {
    return (dispatch) => {
        return dispatch(createAttributeAPI(data))
    }
}

export function updateAttribute(data) {
    return (dispatch) => {
        return dispatch(updateAttributeAPI(data))
    }
}
