import React from 'react';
import { connect } from "react-redux";
import { upsertSession } from '../../action/session';
import { hoverEnter, hoverLeave } from '../../action/user';
import { toggleSelect, updateField, updateAttributeField } from '../../action/view';
import { formatDeliveryDate, formatDeliveryDateHourMinute } from '../../utils/date';
import * as Constants from '../../Constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  upsertSession,
  hoverEnter,
  hoverLeave,
  toggleSelect,
  updateField,
  updateAttributeField
};
class EditBranchForm extends React.Component {

  constructor(props) {
    super(props);
    let delivery_date = this.props.sessionView.session.delivery_date
    this.state = {
      startDate: new Date(delivery_date ? delivery_date : '')
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.sessionView.session.id !== prevProps.sessionView.session.id) {
      let delivery_date = this.props.sessionView.session.delivery_date
      this.setState({
        startDate: new Date(delivery_date ? delivery_date : '')
      })
    }
  }

  onDateChange(date, e) {
    if (e && typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    this.setState({
      startDate: date
    });
    let delivery_date = this.props.sessionView.session.delivery_date
    const hourMin = formatDeliveryDateHourMinute(delivery_date).split(":");
    date.setHours(hourMin[0]);
    date.setMinutes(hourMin[1]);
    date.setSeconds(0);
    var moment = require('moment-timezone');
    var local = moment.tz(date.toISOString(), "Australia/Brisbane");
    this.props.updateField('delivery_date', Constants.SESSION_VIEW, local.format());
  }

  onHourChange(e) {
    let hour = parseInt(e.currentTarget.value)
    let ampm = e.currentTarget.getAttribute('data-ampm')
    let delivery_date = this.props.sessionView.session.delivery_date
    var date = new Date(Date.parse(delivery_date));
    if (hour >= 0 && hour <= 12) {
      date.setHours(ampm === 'PM' ? (hour + 12) : hour);
      var moment = require('moment-timezone');
      var local = moment.tz(date.toISOString(), "Australia/Brisbane");
      this.props.updateField('delivery_date', Constants.SESSION_VIEW, local.format());
    }
  }

  onMinuteChange(e) {
    let minute = e.currentTarget.value
    let delivery_date = this.props.sessionView.session.delivery_date
    var date = new Date(Date.parse(delivery_date));
    if (minute >= 0 && minute < 60) {
      date.setMinutes(minute);
      var moment = require('moment-timezone');
      var local = moment.tz(date.toISOString(), "Australia/Brisbane");
      this.props.updateField('delivery_date', Constants.SESSION_VIEW, local.format());
    }
  }

  onMinuteBlur(e) {
    let delivery_date = this.props.sessionView.session.delivery_date
    var date = new Date(Date.parse(delivery_date));
    if (e.currentTarget.value >= 0) {
      date.setMinutes(date.getMinutes() - date.getMinutes() % 15);
      var moment = require('moment-timezone');
      var local = moment.tz(date.toISOString(), "Australia/Brisbane");
      this.props.updateField('delivery_date', Constants.SESSION_VIEW, local.format());
    }
  }

  onAmPmChange(e) {
    let delivery_date = this.props.sessionView.session.delivery_date
    var date = new Date(Date.parse(delivery_date))
    let ampm = e.currentTarget.value;
    if (ampm === 'AM') {
      date.setHours(date.getHours() + 12);
    } else {
      date.setHours(date.getHours() - 12);
    }
    var moment = require('moment-timezone');
    var local = moment.tz(date.toISOString(), "Australia/Brisbane");
    this.props.updateField('delivery_date', Constants.SESSION_VIEW, local.format());
  }

  selectDate() {
    document.getElementsByClassName('react-datepicker__input-container')[1].getElementsByTagName('input')[0].focus();
  }


  updateTextField(e) {
    e.preventDefault();
    this.props.updateField(e.currentTarget.getAttribute('data-field'), Constants.SESSION_VIEW, e.currentTarget.value);
  }

  updateNumberField(e) {
    e.preventDefault();
    let value = e.currentTarget.value;
    if (!value) {
      value = 0;
    }
    this.props.updateField(e.currentTarget.getAttribute('data-field'), Constants.SESSION_VIEW, parseFloat(value));
  }

  updateAgeField(e) {
    e.preventDefault();
    let gender = e.currentTarget.getAttribute('data-gender');
    let count = parseInt(e.currentTarget.value ? e.currentTarget.value : 0);
    let id = parseInt(e.currentTarget.getAttribute('data-id'));
    let age = {
      id: id,
      name: Constants.AGE_ID[id],
      gender: gender,
      count: count
    }
    let activity_session_age = this.props.sessionView.session.activity_session_age;
    let updateAges = [];
    for (let i = 0; i < activity_session_age.length; i++) {
      if (gender === activity_session_age[i].gender && id === activity_session_age[i].id) {
        updateAges.push(age);
      } else {
        updateAges.push(activity_session_age[i]);
      }
    }
    this.props.updateField('activity_session_age', Constants.SESSION_VIEW, updateAges);
  }

  handHoverEnter(e) {
    e.preventDefault();
    this.props.hoverEnter(e.currentTarget.getAttribute('data-id'));
  }

  handHoverLeave(e) {
    e.preventDefault();
    this.props.hoverLeave();
  }

  toggleSelect(e) {
    e.preventDefault();
    this.props.toggleSelect(e.currentTarget.getAttribute('data-id'));
  }

  upsertSession(e) {
    e.preventDefault();
    this.props.upsertSession(this.props.sessionView.session);
  }

  updateSelectField(e) {
    e.preventDefault();
    let field = e.currentTarget.getAttribute('data-field');
    let data = e.currentTarget.getAttribute('data-id');

    if (data) {
      this.props.updateField(field, Constants.SESSION_VIEW, data);
    } else {
      this.props.updateField(field, Constants.SESSION_VIEW, e.currentTarget.value);
    }
  }

  updateSelectAttribute(e) {
    e.preventDefault();
    let field = e.currentTarget.getAttribute('data-field');
    let data = e.currentTarget.getAttribute('data-id');

    if (data) {
      this.props.updateAttributeField(field, Constants.SESSION_VIEW, data);
    } else {
      this.props.updateAttributeField(field, Constants.SESSION_VIEW, e.currentTarget.value);
    }
  }

  renderSwitch(attribute) {
    let answer = [];
    let currentFilledAttributes = this.props.sessionView.session.attribute_activity_session;
    switch (attribute.attribute_type_id) {
      case 1: // multi select
        return (
          <div className="role attributes">
            {
              attribute.attribute_options.map((option, key) => {

                if (attribute.pivot && attribute.pivot.option_select_answer) {
                  answer = attribute.pivot.option_select_answer.split(',').map(Number);
                } else {
                  if (currentFilledAttributes && currentFilledAttributes.length > 0) {
                    let currentFilledAttribute = currentFilledAttributes.find(element => parseInt(element.id) === parseInt(attribute.id));
                    if (currentFilledAttribute && !attribute.answer && currentFilledAttribute.pivot.option_select_answer) {
                      attribute.answer = currentFilledAttribute.pivot.option_select_answer.split(',').map(Number);
                    }
                    answer = attribute.answer ? attribute.answer : [];
                  } else {
                    answer = attribute.answer ? attribute.answer : [];
                  }
                }
                if (!option.archived)
                  return (
                    <div className={"role-div " + (answer.includes(option.id) ? 'active' : '')} key={key} onClick={this.updateSelectAttribute.bind(this)} data-id={option.id} data-field={attribute.id} data-type="Int">{option.option}</div>
                  )
                else
                  return <></>
              })
            }
          </div>
        );
      case 2: // single select
        return (
          <div className="role attributes">
            {
              attribute.attribute_options.map((option, key) => {
                if (!option.archived) {
                  if (attribute.pivot && attribute.pivot.option_select_answer) {
                    answer = [parseInt(attribute.pivot.option_select_answer)];
                  } else {
                    if (currentFilledAttributes && currentFilledAttributes.length > 0) {
                      let currentFilledAttribute = currentFilledAttributes.find(element => parseInt(element.id) === parseInt(attribute.id));
                      if (currentFilledAttribute && !attribute.answer) {
                        attribute.answer = [parseInt(currentFilledAttribute.pivot.option_select_answer)];
                      }
                      answer = attribute.answer ? attribute.answer : [];
                    } else {
                      answer = attribute.answer ? attribute.answer : [];
                    }
                  }
                }
                if (!option.archived)
                  return (
                    <div className={"role-div " + (answer.includes(option.id) ? 'active' : '')} key={key} onClick={this.updateSelectAttribute.bind(this)} data-id={option.id} data-field={attribute.id} data-type="Int">{option.option}</div>
                  )
                else
                  return <></>
              })
            }
          </div>
        );
      case 3: // yes no question
        if (attribute.pivot && attribute.pivot.true_false_answer) {
          answer = [parseInt(attribute.pivot.true_false_answer)];
        } else {
          if (currentFilledAttributes && currentFilledAttributes.length > 0) {
            let currentFilledAttribute = currentFilledAttributes.find(element => parseInt(element.id) === parseInt(attribute.id));
            if (currentFilledAttribute && !attribute.answer) {
              attribute.answer = [parseInt(currentFilledAttribute.pivot.true_false_answer)];
            }
            answer = attribute.answer ? attribute.answer : [];
          } else {
            answer = attribute.answer ? attribute.answer : [];
          }
        }
        return (
          <div className="role attributes">
            <div className={"role-div " + (answer.includes(1) ? 'active' : '')} data-id="1" onClick={this.updateSelectAttribute.bind(this)} data-field={attribute.id} data-type="Int">Yes</div>
            <div className={"role-div " + (answer.includes(0) ? 'active' : '')} data-id="0" onClick={this.updateSelectAttribute.bind(this)} data-field={attribute.id} data-type="Int">No</div>
          </div>
        );
      case 4:
        if (attribute.pivot && attribute.pivot.free_text_answer) {
          answer = [attribute.pivot.free_text_answer];
        } else {
          if (currentFilledAttributes && currentFilledAttributes.length > 0) {
            let currentFilledAttribute = currentFilledAttributes.find(element => parseInt(element.id) === parseInt(attribute.id));
            if (currentFilledAttribute && !attribute.answer) {
              attribute.answer = [currentFilledAttribute.pivot.free_text_answer];
            }
            answer = attribute.answer ? attribute.answer : [''];
          } else {
            answer = attribute.answer ? attribute.answer : [''];
          }
        }
        return (
          <input className="narrow" data-field={attribute.id}
            value={answer ? answer[0] : ''} onChange={this.updateSelectAttribute.bind(this)}
            data-step={this.props.type === Constants.TYPE_DIRECT ? 4 : 1}></input>
        );
      default:
        return <></>;
    }
  }

  render() {
    const user = JSON.parse(localStorage.getItem('user')).user;
    const session = this.props.sessionView.session
    const branchList = user.branch
    const hourMin = formatDeliveryDateHourMinute(session.delivery_date).split(":");
    const hour = hourMin[0];
    const minute = hourMin[1];

    let custom_attributes = this.props.sessionView.session.attribute_activity_session;

    if (this.props.sessionView.session.attributes) {
      custom_attributes = this.props.sessionView.session.attributes;
    }

    const ages = session.activity_session_age.map((age, key) => {
      return (
        <div className="field-group" key={key}>
          <div className="field">
            <label><span>{age.gender + " Aged " + age.name}</span></label>
            <input data-gender={age.gender} data-id={age.id} className="narrow center" placeholder={0} type="number" value={age.count ? age.count : ''} onChange={this.updateAgeField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
          </div>
        </div>
      )
    });

    const program_category = Constants.PROGRAM_CATEGORY.map((value, key) => {
      return (
        <div className={"role-div widthAuto " + (session.program_category && session.program_category === value ? 'active' : '')} key={key} onClick={this.updateSelectField.bind(this)} data-id={value} data-field='program_category'>{value}</div>
      )
    })

    const program_type = Constants.PROGRAM_TYPE.map((value, key) => {
      return (
        <div className={"role-div widthAuto " + (session.program_type && session.program_type === value ? 'active' : '')} key={key} onClick={this.updateSelectField.bind(this)} data-id={value} data-field='program_type'>{value}</div>
      )
    })

    const participants_with_disability = Constants.PARTICIPANTS_WITH_DISABILITY.map((value, key) => {
      return (
        <div className="field-group" key={key}>
          <div className="field">
            <label><span>{value.label}</span></label>
            <input className="narrow center" name={value.value} data-field={value.value}
              placeholder={0} type="number" value={session[value.value] ? session[value.value] : ''} onChange={this.updateSelectField.bind(this)}
              data-step="4"></input>
          </div>
        </div>
      )
      /*return (
        <div className={"role-div widthAuto " + (session.participants_with_disability && session.participants_with_disability === value ? 'active' : '')} key={key} onClick={this.updateSelectField.bind(this)} data-id={value} data-field='participants_with_disability'>{value}</div>
      )*/
    })

    const kpi = Constants.KPI.map((value, key) => {
      return (
        <div className={"role-div widthAuto " + (session.kpi && session.kpi === value ? 'active' : '')} key={key} onClick={this.updateSelectField.bind(this)} data-id={value} data-field='kpi'>{value}</div>
      )
    })

    const errors = this.props.sessionView.errors;
    const server_errors = this.props.sessionView.server_errors;
    const server_error_str = server_errors ? server_errors.map((error, key) =>
      <div className="error-img-div" key={key}>
        <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
      </div>
    ) : "";
    return (
      <div className="session upsert-one-element-div normal-box normal-box-margin float-left">
        <div className="upsert-one-element" >
          {server_errors ?
            <div className="error-div">
              {server_error_str}
            </div> : ""}
          <h1>Edit Session</h1>
          {branchList.length > 1 ?
            <div className="field-group">
              <div className="field with-arrow" data-id="branch" onClick={user.role_id < Constants.GUEST ? this.toggleSelect.bind(this) : ""}>
                <div className="inline">
                  <label><span>Club</span></label>
                  <div>{session.branch.name}</div>
                </div >
                <div className="inline arrow-div">
                  <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
                </div>
              </div>
            </div>
            : ""}
          <div className="field-group">
            <div className="field with-arrow" data-id="activity" onClick={user.role_id < Constants.GUEST ? this.toggleSelect.bind(this) : ""}>
              <div className="inline">
                <label><span>Activity</span></label><br />
                {session.activity_type.image_url ? <div className="image-circle inline"><img className="img small" src={session.activity_type.image_url} alt={session.activity_type.name} /></div>
                  : <div className="user-logo-div inline">
                    <div className="user-logo small">
                      {session.activity_type.abbreviation}
                    </div>
                  </div>}
                <div className="inline">{session.activity_type.name}</div>
              </div>
              <div className="inline arrow-div">
                <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
              </div>
            </div>
          </div>
          <div className="field-group">
            <div className="field with-arrow" onClick={user.role_id < Constants.GUEST ? this.selectDate.bind(this) : ""}>
              <div className="inline">
                <label><span>Delivery Date</span></label><br />
                <div>{formatDeliveryDate(session.delivery_date)}</div>
                <DatePicker
                  selected={this.state.startDate}
                  onChange={this.onDateChange.bind(this)}
                />
              </div>

              <div className="inline arrow-div">
                <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
              </div>
            </div>
          </div>
          <div className="field-group">
            <div className="field">
              <div className="inline">
                <label><span>Start Time</span></label><br />
                <input className="inline hour center" placeholder="Hour" type="number" value={hour > 12 ? ("0" + (hour - 12)).slice(-2) : hour} data-ampm={hour >= 12 ? 'PM' : 'AM'} onChange={this.onHourChange.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
                <div className="inline colon">:</div>
                <input className="inline minute center" placeholder="Minute" type="number" step="15" value={minute} onBlur={this.onMinuteBlur.bind(this)} onChange={this.onMinuteChange.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
                <input className="inline am-pm" value={hour >= 12 ? 'PM' : 'AM'} readOnly onClick={this.onAmPmChange.bind(this)} ></input>
              </div>
            </div>
          </div>
          <div className="field-group">
            <div className="field">
              <label><span>Duration (minutes)</span></label>
              <input className="narrow center" placeholder="0" data-field="delivery_duration" type="number" value={session.delivery_duration ? session.delivery_duration : ""} onChange={this.updateNumberField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
            </div>
            {errors && errors.delivery_duration ?
              <div className="error-img-div">
                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.delivery_duration}
              </div> : ""}
          </div>
          <div className="field-group">
            <div className="field">
              <label><span>Coordination Duration (minutes)</span></label>
              <input className="narrow center" placeholder="0" data-field="overhead_duration" type="number" value={session.overhead_duration ? session.overhead_duration : ""} onChange={this.updateNumberField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
            </div>
          </div>
          {session.activity_type.type === Constants.TYPE_DIRECT ?
            <div className="field-group">
              <div className="field">
                <label><span>Program Category Attendance</span></label>
                <div className="role attributes">
                  {program_category}
                </div>
              </div>
            </div>
            : ""}
          {session.activity_type.type === Constants.TYPE_DIRECT ?
            <div className="field-group">
              <div className="field">
                <label><span>Program Type</span></label>

                <div className="role attributes">
                  {program_type}
                </div>
              </div>
            </div>
            : ""}
          {session.activity_type.type === Constants.TYPE_DIRECT ?
            <div className="field-group">
              <div className="field">
                <label><span>Social Outcomes & Key Results</span></label>

                <div className="role attributes">
                  {kpi}
                </div>
              </div>
            </div>
            : ""}
          {session.activity_type.type === Constants.TYPE_DIRECT ?
            <div>
              <div className="field-group">
                <div className="field">
                  <label><span>Aboriginal</span></label>
                  <input className="narrow center" placeholder="0" data-field="aboriginal" type="number" value={session.aboriginal ? session.aboriginal : ""} onChange={this.updateNumberField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
                </div>
              </div>
              <div className="field-group">
                <div className="field">
                  <label><span>Caucasian</span></label>
                  <input className="narrow center" placeholder="0" data-field="australian" type="number" value={session.australian ? session.australian : ""} onChange={this.updateNumberField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
                </div>
              </div>
              <div className="field-group">
                <div className="field">
                  <label><span>Torres Strait Islander</span></label>
                  <input className="narrow center" placeholder="0" data-field="torres" type="number" value={session.torres ? session.torres : ""} onChange={this.updateNumberField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
                </div>
              </div>
              <div className="field-group">
                <div className="field">
                  <label><span>Aboriginal & Torres Strait Islander</span></label>
                  <input className="narrow center" placeholder="0" data-field="asti" type="number" value={session.asti ? session.asti : ""} onChange={this.updateNumberField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
                </div>
              </div>
              <div className="field-group">
                <div className="field">
                  <label><span>CALD (Optional - specify CALD in Notes)</span></label>
                  <input className="narrow center" placeholder="0" data-field="cald" type="number" value={session.cald ? session.cald : ""} onChange={this.updateNumberField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
                </div>
              </div>
              <div className="field-group">
                <div className="field">
                  <label><span>Prefer not to identify</span></label>
                  <input className="narrow center" placeholder="0" type="number" data-field="other" value={session.other ? session.other : ""} onChange={this.updateNumberField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
                </div>
                {errors && errors.total ?
                  <div className="error-img-div">
                    <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.total}
                  </div> : ""}
              </div>
              <div className="field-group">
                <div className="field">
                  <label><span>Volunteers</span></label>
                  <input className="narrow center" placeholder="0" data-field="volunteers" type="number" value={session.volunteers ? session.volunteers : ""} onChange={this.updateNumberField.bind(this)} disabled={user.role_id >= Constants.GUEST}></input>
                </div>
              </div>
            </div>
            : ""}
          {ages}
          {session.activity_type.type === Constants.TYPE_DIRECT ?
            <div className="field-group">
              <div className="field">
                <label><span>Participants with Disability</span></label>
                {participants_with_disability}
              </div>
            </div> : ''
          }
          <div className="field-group">
            <div className="field">
              <label><span>Notes</span></label>
              <textarea className="narrow" placeholder="Notes" data-field="notes" value={session.notes ? session.notes : ""} onChange={this.updateTextField.bind(this)} disabled={user.role_id >= Constants.GUEST}></textarea>
            </div>
          </div>
          {
            custom_attributes && custom_attributes.length > 0 && custom_attributes.length !== custom_attributes.filter(attribute => attribute.archived).length ?
              (
                <div className="field-group">
                  <h1>Custom Attributes</h1>
                  {
                    custom_attributes.map((attribute, key) => {
                      if (!attribute.archived)
                        return (<div className="field-group" key={key}>
                          <div className="field">
                            <label><span>{attribute.title}</span></label>
                            {this.renderSwitch(attribute)}
                          </div>
                        </div>)
                      else return <></>
                    })
                  }
                </div>
              ) : ''
          }
          {user.role_id < Constants.GUEST ?
            <button className="red-white-button not-ab" onClick={this.upsertSession.bind(this)}>Update</button> : ""}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBranchForm);
