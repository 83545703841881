import * as Constants from '../Constants';

const activityView = (state = Constants.activityViewInitialState, action) => {
    let activity = {};
    switch (action.type) {
        case Constants.RECEIVE_ACTIVITY_LIST:
            return {
                activityList: action.activityList,
                activity: state.activity
            }
        case Constants.FETCH_AN_ACTIVITY:
            let returnActivity = {};
            for (var i = 0; i < state.activityList.length; i++) {
                activity = state.activityList[i];
                if (activity.id === parseInt(action.id)) {
                    returnActivity = activity;
                    break;
                }
            }
            if (returnActivity.description === null) {
                returnActivity.description = '';
            }
            return {
                activityList: state.activityList,
                activity: {
                    ...returnActivity
                }
            }
        case Constants.CLOSE_IMAGE:
            if (action.view === Constants.ACTIVITY_VIEW) {
                return {
                    activityList: state.activityList,
                    activity: {
                        ...state.activity,
                        image_url: null,
                        image_base_64: null
                    }
                }
            } else {
                return state;
            }
        case Constants.UPDATE_PREVIEW_FILE:
            if (action.view === Constants.ACTIVITY_VIEW) {
                return {
                    activityList: state.activityList,
                    activity: {
                        ...state.activity,
                        image_base_64: action.result
                    }
                }
            } else {
                return state;
            }

        case Constants.UPDATE_FIELD:
            if (action.view === Constants.ACTIVITY_VIEW) {
                activity = {
                    ...state.activity,
                }
                activity[action.field] = action.data;
                return {
                    activityList: state.activityList,
                    activity: activity
                }
            }
            return state;

        case Constants.RECEIVE_UPDATE_ACTIVITY:
            for (var i2 = 0; i2 < state.activityList.length; i2++) {
                activity = state.activityList[i2];
                if (activity.id === parseInt(action.activity.id)) {
                    state.activityList[i2] = action.activity
                    break;
                }
            }
            return {
                activityList: state.activityList,
                activity: action.activity
            }
        case Constants.PAGE_CHANGE:
            return {
                activityList: state.activityList,
                activity: {}
            }
        case Constants.SHOW_INLINE_ERROR_MSG:
            return {
                activityList: state.activityList,
                activity: state.activity,
                errors: action.errors
            }

        case Constants.SHOW_SERVER_ERROR_MSG:
            return {
                activityList: state.activityList,
                activity: state.activity,
                server_errors: action.errors,
            }
        case Constants.RECEIVE_CREATE_ACTIVITY:
            return {
                add_success: true,
                message: action.message,
                activityList: state.activityList,
                activity: action.activity
            }
        case Constants.RECEIVE_DELETE_AN_ACTIVITY:
            return {
                activityList: state.activityList.filter(function(activity) {
                    return activity.id !== parseInt(action.id);
                }),
                activity: {}
            }
        default:
            return state;
    }
}

export default activityView;