import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import * as Constants from "../../Constants";

class RadialChart extends React.Component {
    render() {
        let data = this.props.data;
        let colors = Constants.COLORS;
        return (
            <ResponsiveContainer height="100%" width="100%">
                <PieChart>
                    <Pie
                        data={data}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="total"
                    >
                        {
                            data.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />)
                        }
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        );
    }
}

export default RadialChart;
