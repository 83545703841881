import * as Constants from '../Constants';

export const showInlineErrorMsg = (errors) => ({
    type: Constants.SHOW_INLINE_ERROR_MSG,
    errors: errors,
});

export const showServerErrorMsg = (errors) => ({
    type: Constants.SHOW_SERVER_ERROR_MSG,
    errors: errors.error.messages,
});