import React from 'react';
import { connect } from "react-redux";
import * as Constants from '../../Constants';
import { changeFirstName, changeLastName, changeEmail, changePhone, changePassword, changeRole, updateUser, fetchUserList, assignUserBranches } from '../../action/user';
import { toggleSelect } from '../../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    changeFirstName,
    changeLastName,
    changeEmail,
    changePhone,
    changePassword,
    changeRole,
    updateUser,
    fetchUserList,
    toggleSelect,
    assignUserBranches
};
class EditUser extends React.Component {

    handleChangeRole(e) {
        e.preventDefault();
        this.props.changeRole(e.currentTarget.getAttribute('data-id'));
    }

    handleChangeFirstName(e) {
        e.preventDefault();
        this.props.changeFirstName(e.currentTarget.value);
    }

    handleChangeLastName(e) {
        e.preventDefault();
        this.props.changeLastName(e.currentTarget.value);
    }

    handleChangeEmail(e) {
        e.preventDefault();
        this.props.changeEmail(e.currentTarget.value);
    }

    handleChangePhone(e) {
        e.preventDefault();
        this.props.changePhone(e.currentTarget.value);
    }

    handleChangePassword(e) {
        e.preventDefault();
        this.props.changePassword(e.currentTarget.value);
    }

    toggleSelect(e) {
        e.preventDefault();
        if (this.props.userView.user.role_id > Constants.ADMIN) {
            this.props.toggleSelect();
        }
    }

    handleUpdateButton(e) {
        e.preventDefault();
        this.props.updateUser(this.props.userView.user);
    }

    handleSelectAll(e) {
        this.props.assignUserBranches([], true);
    }

    render() {
        const loginUser = JSON.parse(localStorage.getItem('user')).user;
        const user = this.props.userView.user;

        let not_admin = user.role_id > Constants.ADMIN;
        let branchStr = not_admin ? 
            (user.branch || user.branch.length === 0) ? 
                user.all_branches ?
                    [] 
                :
                    (user.branch.map(branch =>
                        !branch.before_admin ? branch.name : ''
                    ).filter(function (el) {
                        return el !== '';
                    })) 
            : 
            ['Select Club'] 
        : 
        ['Admins can view all branches'];

        const errors = this.props.userView.errors;
        const server_errors = this.props.userView.server_errors;
        const server_error_str = server_errors ? server_errors.map((error, key) =>
            <div className="error-img-div" key={key}>
                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
            </div>
        ) : "";
        return (
            <div className="edit-user-div normal-box normal-box-margin float-left">
                <div className="edit-user normal-margin">
                    {server_errors ?
                        <div className="error-div">
                            {server_error_str}
                        </div> : ""}
                    <div className="user-logo-div">
                        <div className="user-logo">
                            {user.id ? user.first_name.charAt(0) + user.last_name.charAt(0) : ""}
                        </div>
                    </div>
                    <div className="field-group">
                        <div className="field">
                            <label className="inline">First Name</label>
                            <input className="inline" placeholder="First Name" type="text" value={user.first_name ? user.first_name : ""} onChange={this.handleChangeFirstName.bind(this)}></input>
                        </div>
                        {errors && errors.first_name ?
                            <div className="error-img-div">
                                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.first_name}
                            </div> : ""}
                        <div className="field">
                            <label className="inline">Last Name</label>
                            <input className="inline" placeholder="Last Name" type="text" value={user.last_name ? user.last_name : ""} onChange={this.handleChangeLastName.bind(this)}></input>
                        </div>
                        {errors && errors.last_name ?
                            <div className="error-img-div">
                                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.last_name}
                            </div> : ""}
                    </div>
                    <div className="field-group">
                        <div className="field">
                            <label className="inline">Email Address</label>
                            <input className="inline" placeholder="Email Address" type="email" value={user.email ? user.email : ""} onChange={this.handleChangeEmail.bind(this)}></input>
                        </div>
                        {errors && errors.email ?
                            <div className="error-img-div">
                                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.email}
                            </div> : ""}
                        <div className="field">
                            <label className="inline">Phone Number</label>
                            <input className="inline" placeholder="Phone Number" type="text" value={user.phone ? user.phone : ""} onChange={this.handleChangePhone.bind(this)}></input>
                        </div>
                    </div>

                    <div className="field-group">
                        <div className="field">
                            <label className="inline">Password</label>
                            <input className="inline" placeholder="Password" type="password" value={user.password ? user.password : ""} onChange={this.handleChangePassword.bind(this)}></input>
                        </div>
                    </div>

                    <div className={!not_admin ? "field-group" : "field-group--mt8"}>
                        <div className={!not_admin ? "field" : "field--mt0"}>
                            <label><span>Assigned to:</span></label>
                            {not_admin && 
                                <div>
                                    <div className={"role-div inline" + (!user.all_branches && user.branch && user.branch.length > 0 ? " active" : "")} onClick={this.toggleSelect.bind(this)}>Select</div>
                                    {loginUser.role_id < Constants.ADMIN && <div className={"role-div inline" + (user.all_branches ? " active" : "")} onClick={this.handleSelectAll.bind(this)}>All</div>}
                                </div>
                            }
                            <div>{branchStr.join(', ')}</div>
                        </div>
                        <div className={!not_admin ? "field" : "field--mt0"}>
                            <label><span>Role</span></label>
                            <div className="role">
                                {loginUser.role_id <= Constants.SUPER_ADMIN ?
                                    <div
                                        className={user.id && user.role_id === Constants.SUPER_ADMIN ? 'role-div inline active' : 'role-div inline'}
                                        data-id={Constants.SUPER_ADMIN}
                                        onClick={this.handleChangeRole.bind(this)}>
                                        Super
                                </div>
                                    : ""}
                                {loginUser.role_id < Constants.ADMIN ?
                                    <div
                                        className={user.id && user.role_id === Constants.ADMIN ? 'role-div inline active' : 'role-div inline'}
                                        data-id={Constants.ADMIN}
                                        onClick={this.handleChangeRole.bind(this)}>
                                        Admin
                                </div>
                                    : ""}
                                {loginUser.role_id < Constants.BRANCH_MANAGER ?
                                    <div
                                        className={user.id && user.role_id === Constants.BRANCH_MANAGER ? 'role-div inline active' : 'role-div inline'}
                                        data-id={Constants.BRANCH_MANAGER}
                                        onClick={this.handleChangeRole.bind(this)}>
                                        Man
                                </div>
                                    : ""}
                                <div
                                    className={user.id && user.role_id === Constants.BRANCH_OFFICER ? 'role-div inline active' : 'role-div inline'}
                                    data-id={Constants.BRANCH_OFFICER}
                                    onClick={this.handleChangeRole.bind(this)}>
                                    Off
                            </div>
                                {loginUser.role_id !== Constants.BRANCH_MANAGER ?
                                    <div
                                        className={user.id && user.role_id === Constants.GUEST ? 'role-div inline active' : 'role-div inline'}
                                        data-id={Constants.GUEST}
                                        onClick={this.handleChangeRole.bind(this)}>
                                        Guest
                            </div>
                                    : ""}
                            </div>
                        </div>
                        {errors && errors.role_id ?
                            <div className="error-img-div float-right">
                                <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.role_id}
                            </div> : ""}
                    </div>
                    <button onClick={this.handleUpdateButton.bind(this)} className={server_errors || (errors && Object.keys(errors).length > 2) ? "red-white-button not-ab" : "red-white-button not-ab"}>Update</button>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditUser);
