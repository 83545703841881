import React from 'react';
import { connect } from "react-redux";
import { fetchBranchList } from '../../action/branch';
import { fetchActivityList } from '../../action/activity';
import { getCustomAttributeReportList } from '../../action/customReports';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    fetchBranchList,
    fetchActivityList,
    getCustomAttributeReportList
};

class SelectFilterAttribute extends React.Component {
    constructor(props) {
        super(props);

        this.filterList = this.filterList.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);

        switch (this.props.type) {
            case 'branch_id':
                this.props.fetchBranchList();
                break;
            case 'activity_type_id':
                this.props.fetchActivityList();
                break;
            case 'attributes':
                this.props.getCustomAttributeReportList('', this.props.currentState.branch_id, this.props.currentState.activity_type_id, 0, 99999);
                break;
            default:
                break;
        }

        this.state = {
            items: [],
            initialItems: [],
            selected: this.props.currentSelected
        }
    }

    filterList(e) {
        let updatedList = this.props.surveyView.branchList;

        if (this.props.type === 'activity_type_id') {
            updatedList = this.props.activityView.activityList;
        }

        updatedList = updatedList.filter(function (item) {

            try {
                return item.name.toLowerCase().search(
                    e.currentTarget.value.toLowerCase()) !== -1;
            } catch (e) {
                return false
            }

        });

        this.setState({ items: updatedList });
    }

    handleOnClick(e) {
        this.setState({ selected: parseInt(e.currentTarget.value) });
        if (e.currentTarget.value === 'all') {
            // get all item id
            let list = this.props.attributeView.branchList;

            if (this.props.type === 'activity_type_id') {
                list = this.props.activityView.activityList;
            }

            if (this.props.type === 'attributes') {
                list = this.props.customReportView.attributeList;
            }

            let ids = [];

            list.forEach(item => {
                ids.push(item.id);
            });
            this.props.onHandleUpdateForm(this.props.type, ids);
        } else {
            this.props.onHandleUpdateForm(this.props.type, e.currentTarget.value);
        }

    }

    render() {
        let list = this.props.attributeView.branchList;
        const user = JSON.parse(localStorage.getItem('user')).user;
        if (this.props.type === 'activity_type_id') {
            list = this.props.activityView.activityList;
        }

        if (this.props.type === 'attributes') {
            list = [];
            this.props.customReportView.attributeList.forEach(attr => {
                if (attr.attribute_type_id === 1 || attr.attribute_type_id === 2) {
                    list.push(attr);
                }
            });
        }

        if (this.state.items.length > 0) {
            list = this.state.items;
        }
        let itemIds = [];
        const items = list.map((item, key) => {
            itemIds.push(item.id);
            let img = '';
            if (item.image_url !== undefined && item.image_url !== '' && item.image_url !== null) {
                img = <img alt={item.name} src={item.image_url} className="activityIcon" />
            }

            if ((item.type === 'direct' || this.props.type === 'activity_type_id' || this.props.type === 'branch_id' || this.props.type === 'attributes') && !item.deleted_at) {
                if (this.props.currentSelected) {
                    //console.log(this.props.currentSelected.includes(item.id));
                }

                return (
                    <li className="branch-name" data-category={item.name ? item.name : item.title} key={key}>
                        <label className="container">
                            {img ? img
                                : item.abbreviation ? <div className="user-logo-div inline activityIcon">
                                    <div className="user-logo">
                                        {item.abbreviation}
                                    </div>
                                </div> : ""}
                            {item.name ? item.name : item.title}
                            {
                                Array.isArray(this.props.currentSelected) ?
                                    <input type="checkbox" id={item.name ? item.name : item.title} name="branch" value={item.id} onChange={this.handleOnClick} checked={this.props.currentSelected.includes(item.id)} /> :
                                    <input type="radio" id={item.name ? item.name : item.title} name="branch" value={item.id} onChange={this.handleOnClick} checked={item.id === parseInt(this.state.selected)} />
                            }

                            <span className="checkmark"></span>
                        </label>
                    </li>
                )
            } else {
                return null
            }

        });


        return (
            <div className="branchList">
                <input type="text" className="form-control form-control-lg" placeholder="Type your search here..." onChange={this.filterList} />
                <div className="groupContainer">
                    <ul className="branch-group">
                        <li className="branch-name">
                            <label className="container">
                                Select All

                                <input type="checkbox" id='all' name="branch" value='all' onChange={this.handleOnClick} checked={this.props.currentSelected ? this.props.currentSelected.includes(itemIds) : false} />

                                <span className="checkmark"></span>
                            </label>
                        </li>
                        {items}
                    </ul>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectFilterAttribute);
