import React from 'react';
import { connect } from "react-redux";
import { fetchBranchList } from '../../action/branch';
import { fetchSessionList, deleteASession } from '../../action/session';
import { fetchActivityList } from '../../action/activity';
import BranchList from './BranchList';
import ManageSession from './ManageSession';
import EditSessionForm from './EditSessionForm';
import SelectBranch from './SelectBranch';
import SelectActivity from './SelectActivity';
import * as Constants from '../../Constants';


function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  fetchBranchList,
  fetchSessionList,
  fetchActivityList,
  deleteASession
};

class Session extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      branch_id: '',
      page: 1,
      endPage: false
    }
    this.props.fetchBranchList();
    this.props.fetchSessionList();
    this.props.fetchActivityList();
  }

  loadMore() {
    let nextPage = this.state.page + 1;
    let currentCount = this.state.page * Constants.MAX_COUNT;
    let nextCount = nextPage * Constants.MAX_COUNT;

    if (this.state.endPage !== true) {
      if (nextCount < this.props.sessionView.total_count) {
        this.setState({
          ...this.state,
          page: nextPage
        });
      } else {
        nextCount = this.props.sessionView.total_count;
        this.setState({
          ...this.state,
          page: nextPage,
          endPage: true
        });
      }
      // fetch survey list again
      this.props.fetchSessionList(this.state.branch_id, this.state.date, this.state.activity, currentCount, Constants.MAX_COUNT, this.state.end_date);
    }
  }

  handleDelete(session_id) {
    // after delete, the survey list need to reload using the same data from the previous page
    this.props.deleteASession(session_id, this.state.branch_id, this.state.date, this.state.activity, this.state.page, this.state.end_date);

  }

  onBranchChange(branch_id) {
    this.setState({
      branch_id: branch_id,
      page: 1,
      endPage: false
    })
    this.props.fetchSessionList(branch_id, this.state.date, this.state.activity, 0, Constants.MAX_COUNT, this.state.end_date);
  }

  onDateChange(date) {
    this.setState({
      ...this.state,
      date: date
    })
    this.props.fetchSessionList(this.state.branch_id, date, this.state.activity, 0, Constants.MAX_COUNT, this.state.end_date);
  }

  onEndDateChange(date) {
    this.setState({
      ...this.state,
      end_date: date
    })
    this.props.fetchSessionList(this.state.branch_id, this.state.date, this.state.activity, 0, Constants.MAX_COUNT, date);
  }

  onActivityChange(activity) {
    this.setState({
      ...this.state,
      activity: activity
    })
    this.props.fetchSessionList(this.state.branch_id, this.state.date, activity, 0, Constants.MAX_COUNT, this.state.end_date);
  }

  render() {
    return (
      <div className="Sessions">
        <BranchList onBranchChange={this.onBranchChange.bind(this)} onDateChange={this.onDateChange.bind(this)} onEndDateChange={this.onEndDateChange.bind(this)} onActivityChange={this.onActivityChange.bind(this)}></BranchList>
        <ManageSession loadMore={this.loadMore.bind(this)} handleDelete={this.handleDelete.bind(this)}></ManageSession>
        {this.props.sessionView.session.id ?
          this.props.root.overlay ?
            this.props.root.view_type === 'branch' ? <SelectBranch></SelectBranch> :
              this.props.root.view_type === 'activity' ? <SelectActivity></SelectActivity> : <EditSessionForm></EditSessionForm> :
            <EditSessionForm></EditSessionForm> : ""}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Session);
