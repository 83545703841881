import React from 'react';

class TimeBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let data = this.props.data;
        let type = this.props.type;
        let className = 'weekBar';

        if (type === 3 || type === 4) {
            return null;
        } else {
            return (
                <div className="timeBars">
                    {
                        data.map((d, key) => {
                            return <div key={key} className={className}>
                                <h5>{d.total.toLocaleString()}</h5>
                                <span>{d.name}</span>
                            </div>
                        })
                    }
                </div>
            );
        }
    }
}

export default TimeBar;
