import React from 'react';
import { connect } from "react-redux";
import { } from '../../action/user';
import { assignBranchUsers} from '../../action/branch';
import { toggleSelect } from '../../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    assignBranchUsers,
    toggleSelect
};
class BranchList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchUser: this.props.userView.userList,
            users: this.props.userView.userList,
            branchUsers: this.props.branchView.branch.users ? this.props.branchView.branch.users : []
        }
    }

    toggleSelect(e) {
        e.preventDefault();
        this.props.toggleSelect();
    }

    assignBranch(e) {
        this.props.assignBranchUsers(this.state.branchUsers);
        this.props.toggleSelect();
    }

    search(e) {
        let searchUsers = []
        for (var i = 0; i < this.state.users.length; i++) {
            let name = this.state.users[i].first_name + " " + this.state.users[i].last_name;
            if (name.toLowerCase().includes(e.currentTarget.value.toLowerCase())) {
                searchUsers.push(this.state.users[i]);
            }
        }
        this.setState({
            searchUser: searchUsers,
            users: this.state.users,
            branchUsers: this.state.branchUsers
        });

    }

    toggleOneUser(e) {
        let userId = e.currentTarget.getAttribute('data-id');

        let branchUsers = [];
        let addClicked = true;
        for (var i = 0; i < this.state.branchUsers.length; i++) {
            if (this.state.branchUsers[i].id !== parseInt(userId)) {
                branchUsers.push(this.state.branchUsers[i])
            } else {
                addClicked = false
            }
        }

        if (addClicked) {
            let first_name = "";
            let last_name = "";
            for (var i2 = 0; i2 < this.state.users.length; i2++) {
                if (this.state.users[i2].id === parseInt(userId)) {
                    first_name = this.state.users[i2].first_name;
                    last_name = this.state.users[i2].last_name;
                    break;
                }
            }
            branchUsers.push({
                id: parseInt(userId),
                first_name: first_name,
                last_name: last_name
            })
        }
        this.setState({
            searchUser: this.state.searchUser,
            users: this.state.users,
            branchUsers: branchUsers
        });
    }

    render() {
        const users = this.state.searchUser.map((user, key) => {
            let userClass = '';
            for (var i = 0; i < this.state.branchUsers.length; i++) {
                let branchuser =this.state.branchUsers[i];
                if (branchuser.id === user.id) {
                    userClass = 'active';
                }
            }

            return (
                <div className="item-name" key={user.id} data-id={user.id}>
                    <span style={{cursor: 'pointer'}} data-id={user.id} onClick={this.toggleOneUser.bind(this)}>{user.first_name+' '+user.last_name}</span>
                    <div className="float-right squre-checkbox" data-id={user.id} onClick={this.toggleOneUser.bind(this)}>
                        <div className={userClass}><img src="/img/check.svg" alt="checkbox" className="check-img"></img></div>
                    </div>
                </div>
            );
        });
        return (
            <div className="select-div normal-box normal-box-margin float-left">
                <div className="normal-margin-heading" >
                    <h1 className="clickable" onClick={this.toggleSelect.bind(this)}><img src="/img/arrow-left.svg" alt="Arrow Left" className="arrow-left"></img>Select User</h1>
                    <div className="search-field">
                        <input placeholder="Search Users" onChange={this.search.bind(this)}></input>
                        <img src="/img/search-icon.svg" alt="Search Icon" className="search-icon"></img>
                    </div>
                </div>
                <div className="normal-margin" >
                    {users}
                    <button className="red-white-button" onClick={this.assignBranch.bind(this)}>Assign</button>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BranchList);
