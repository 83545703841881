import axios from 'axios';
// import { fetchingStart } from './view';
import * as Constants from '../Constants';

export const receiveAttributeList = (data) => ({
    type: Constants.REPORT_RECEIVE_ATTRIBUTE_LIST,
    attributeList: data.data.attributes
});

export const receiveAttributeListBranch = (data) => ({
    type: Constants.REPORT_RECEIVE_ATTRIBUTE_LIST_BRANCH,
    attributeList: data.data.attributes
});


export const hoverEnter = (hover_id) => ({
    type: Constants.HOVER_ENTER,
    hover_id: hover_id
});
export const hoverLeave = () => ({ type: Constants.HOVER_LEAVE });

export const showServerErrorMsg = (errors) => ({
    type: Constants.SHOW_SERVER_ERROR_MSG,
    errors: errors.error.messages,
});

export const updateModal = (block) => ({
    type: Constants.UPDATE_MODAL,
    block: block
});

export const fetchAttribute = (data) => ({
    type: Constants.FETCH_ATTRIBUTE,
    attribute: data
});

export const selectAttributeActivity = (data) => ({
    type: Constants.REPORT_ATTRIBUTE_ACTIVITY_CHANGE,
    activities: data
})

export const selectAttributeBranch = (data) => ({
    type: Constants.REPORT_ATTRIBUTE_BRANCH_CHANGE,
    branches: data
})

export const removeErrorMsg = () => ({ type: Constants.REMOVE_ERROR_MSG });

function fetchAttributeListAPI(str = '', branch_id = '', activity_id = '', start = 0, count = Constants.MAX_COUNT) {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };
        branch_id = Array.isArray(branch_id) ? branch_id.join(',') : '';
        activity_id = Array.isArray(activity_id) ? activity_id.join(',') : '';

        // dispatch(fetchingStart())
        try {
            const response = await axios.get(Constants.API + 'attribute/list?str=' + str + '&branches=' + branch_id + '&activities=' + activity_id + '&start=' + start + '&count=' + count, axiosConfig);
            const data = response.data;

            if(start === 0) {
                return dispatch(receiveAttributeListBranch(data));
            } else {
                return dispatch(receiveAttributeList(data));
            }
            
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

export function getCustomAttributeReportList(str = '', branch_id = '', activity_id = '', start = 0, count = 20) {
    return (dispatch) => {
        return dispatch(fetchAttributeListAPI(str, branch_id, activity_id, start, count))
    }
}
