import React from 'react';
import BranchList from './BranchList';
import ManageAttribute from './ManageAttribute';
import EditAttributeForm from './EditAttributeForm';
import { connect } from "react-redux";
import { fetchBranchList } from '../../action/branch';
import { fetchActivityList } from '../../action/activity';
import { fetchAttributeList, deleteAAttribute } from '../../action/attribute';
import * as Constants from '../../Constants';
import SelectBranch from './SelectBranch';
import SelectActivity from './SelectActivity';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    fetchBranchList,
    fetchActivityList,
    fetchAttributeList,
    deleteAAttribute
};

class Attributes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: 1,
            branch_id: '',
            page: 1,
            endPage: false
        }
        this.props.fetchBranchList();
        this.props.fetchAttributeList();
        this.props.fetchActivityList();
    }

    handleClick(e) {
        this.setState({ active: e.target.id });
    }

    loadMore() {
        let nextPage = this.state.page + 1;
        let currentCount = this.state.page * Constants.MAX_COUNT;
        let nextCount = nextPage * Constants.MAX_COUNT;

        if (this.state.endPage !== true) {
            if (nextCount < this.props.attributeView.query_count) {
                this.setState({ page: nextPage });
            } else {
                nextCount = this.props.attributeView.query_count;
                this.setState({ page: nextPage, endPage: true });
            }
            // fetch attribute list again
            this.props.fetchAttributeList('', this.state.branch_id, this.state.str, currentCount, Constants.MAX_COUNT);
        }
    }

    handleDelete(attribute_id) {
        // after delete, the survey list need to reload using the same data from the previous page
        this.props.deleteAAttribute(attribute_id, this.state.branch_id, this.state.page);
        this.props.fetchBranchList();
    }

    onBranchChange(branch_id) {
        this.setState({
            branch_id: branch_id,
            page: 1,
            endPage: false
        })
    }

    render() {
        const selectedAttribute = this.props.attributeView.attribute;
        return (
            <div className="Surveys">
                {/* Club List with attribute in it */}
                <BranchList onBranchChange={this.onBranchChange.bind(this)} />
                <ManageAttribute loadMore={this.loadMore.bind(this)} handleDelete={this.handleDelete.bind(this)} />
                {selectedAttribute.id ?
                    this.props.root.overlay ?
                        this.props.root.view_type === 'branch' ? <SelectBranch></SelectBranch> :
                            this.props.root.view_type === 'activity' ? <SelectActivity></SelectActivity> : <EditAttributeForm /> :
                        <EditAttributeForm /> : ""}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Attributes);
