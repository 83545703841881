import { combineReducers } from 'redux'
import root from './root'
import loginView from './loginView'
import userView from './userView'
import overviewView from './overviewView'
import performanceView from './performanceView'
import reportCardView from './reportCardView'
import goodNewsView from './goodNewsView'
import surveyView from './surveyView'
import activityView from './activityView'
import branchView from './branchView'
import sessionView from './sessionView'
import workplanView from './workplanView'
import attributeView from './attributeView'
import chartView from './chartView'
import customReportView from './customReportView'

export default combineReducers({
    root,
    loginView,
    userView,
    overviewView,
    performanceView,
    reportCardView,
    goodNewsView,
    surveyView,
    activityView,
    branchView,
    sessionView,
    workplanView,
    attributeView,
    chartView,
    customReportView
})