import React from 'react';
import { connect } from "react-redux";
import { } from '../../action/user';
import { selectAttributeBranch } from '../../action/attribute';
import { toggleSelect } from '../../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    selectAttributeBranch,
    toggleSelect
};
class SelectBranch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchBranches: this.props.branchView.branchList.filter(function (branch) {
                return branch.deleted_at === null;
            }),
            branches: this.props.branchView.branchList.filter(function (branch) {
                return branch.deleted_at === null;
            }),
            attributeBranches: this.props.attributeView.attribute.branches ? this.props.attributeView.attribute.branches : []
        }
    }

    toggleSelect(e) {
        e.preventDefault();
        this.props.toggleSelect();
    }

    selectBranch(e) {
        if (Object.keys(this.state.attributeBranches).length > 0) {
            this.props.selectAttributeBranch(this.state.attributeBranches);
        } else {
            alert('Branches should not be blank')
        }
        this.props.toggleSelect();
    }

    search(e) {
        let searchBranches = []
        for (var i = 0; i < this.state.branches.length; i++) {
            let branchName = this.state.branches[i].name;
            if (branchName.toLowerCase().includes(e.currentTarget.value.toLowerCase())) {
                searchBranches.push(this.state.branches[i]);
            }
        }
        this.setState({
            searchBranches: searchBranches,
            branches: this.state.branches,
            attributeBranches: this.state.attributeBranches
        });
    }

    toggleOneBranch(e) {
        let branchId = e.currentTarget.getAttribute('data-id');

        let attributeBranches = [];
        let addClicked = true;
        for (var i = 0; i < this.state.attributeBranches.length; i++) {
            if (this.state.attributeBranches[i].id !== parseInt(branchId)) {
                attributeBranches.push(this.state.attributeBranches[i])
            } else {
                addClicked = false
            }
        }

        if (addClicked) {
            let branch = "";
            for (var i2 = 0; i2 < this.state.branches.length; i2++) {
                if (this.state.branches[i2].id === parseInt(branchId)) {
                    branch = this.state.branches[i2];
                    break;
                }
            }
            attributeBranches.push({
                ...branch,
                order: 1
            })
        }

        this.setState({
            searchBranches: this.state.searchBranches,
            branches: this.state.branches,
            attributeBranches: attributeBranches
        });
    }

    render() {
        const branches = this.state.searchBranches.map((branch, key) => {
            let branchClass = '';
            this.state.attributeBranches.forEach(element => {
                if (branch.id === element.id) {
                    branchClass = 'active';
                }
            });

            return (
                <div className="item-name" key={branch.id} data-id={branch.id}>
                    {branch.name}
                    <div className="float-right squre-checkbox" data-id={branch.id} onClick={this.toggleOneBranch.bind(this)}>
                        <div className={branchClass}><img src="/img/check.svg" alt="checkbox" className="check-img"></img></div>
                    </div>
                </div>
            );
        });
        return (
            <div className="session select-div normal-box normal-box-margin-2 step-div">
                <div className="normal-margin-heading" >
                    <h1 className="clickable" onClick={this.toggleSelect.bind(this)}><img src="/img/arrow-left.svg" alt="Arrow Left" className="arrow-left"></img>Select Club</h1>
                    <div className="search-field">
                        <input placeholder="Search Clubs" onChange={this.search.bind(this)}></input>
                        <img src="/img/search-icon.svg" alt="Search Icon" className="search-icon"></img>
                    </div>
                </div>
                <div className="normal-margin" >
                    {branches}
                    <button className="red-white-button" onClick={this.selectBranch.bind(this)}>Select</button>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectBranch);
