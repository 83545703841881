import * as Constants from '../Constants';

const reportCardView = (state = [], action) => {
    let nextState = [];
    switch (action.type) {
        case Constants.RECEIVE_REPORT_CARD_DATA:
            // update the state and return another array

            state.map((entry) => {
                if (entry.typeCard === action.typeCard) {

                    nextState.push({
                        data: entry.typeCard > 5 ? action.data.data[0] : action.data.data,
                        timeSelected: action.timeSelected,
                        typeCard: action.typeCard,
                        startDate: action.startDate,
                        endDate: action.endDate,
                    })
                } else {
                    nextState.push(entry)
                }

                return null
            })

            return nextState
        /*
        if (state.length === Constants.REPORT_CARD.length) {
            
        } else {
            return [
                ...state,
                {
                    data: action.data.data,
                    timeSelected: action.timeSelected,
                    typeCard: action.typeCard,
                    startDate: action.startDate,
                    endDate: action.endDate,
                    attributeId: action.attributeId
                }
            ]
        }*/

        case Constants.RECEIVE_ALL_DASHBOARD:
            for (const key in action.data.data) {
                if (action.data.data.hasOwnProperty(key) && Number.isInteger(parseInt(key))) {
                    const cart = action.data.data[key];
                    nextState.push({
                        data: cart,
                        timeSelected: action.time_id,
                        typeCard: parseInt(key),
                        startDate: action.startDate,
                        endDate: action.endDate
                    })
                }
            }
            return nextState

        // case Constants.FETCHING_START + '_reportCard':
        //     if (state.length === Constants.REPORT_CARD.length) {
        //         // update the state and return another array
        //         let nextState = [];
        //         state.map((entry) => {
        //             if (entry.typeCard === action.typeCard) {
        //                 nextState.push({
        //                     data: [],
        //                     timeSelected: action.timeSelected,
        //                     typeCard: action.typeCard
        //                 })
        //             } else {
        //                 nextState.push(entry)
        //             }

        //             return null
        //         })

        //         return nextState
        //     } else {
        //         return state
        //     }
        case Constants.PAGE_CHANGE:
            return []
        default:
            return state
    }
}

export default reportCardView;