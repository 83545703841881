import React from 'react';
import { connect } from "react-redux";
import { updateAttribute, fetchAttribute, hoverEnter, hoverLeave } from '../../action/attribute';
import { pageChange, toggleSelect } from '../../action/view';
import Modal from 'react-responsive-modal';
//import { timeDifference } from '../../utils/date';
import * as Constants from '../../Constants';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    hoverEnter,
    hoverLeave,
    pageChange,
    fetchAttribute,
    updateAttribute,
    toggleSelect
};
class ManageAttribute extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showArchived: false,
            open: false
        };
    }

    handHoverEnter(e) {
        e.preventDefault();
        this.props.hoverEnter(e.currentTarget.getAttribute('data-id'));
    }

    handHoverLeave(e) {
        e.preventDefault();
        this.props.hoverLeave();
    }

    handDelete(e) {
        e.preventDefault();
        this.onCloseModal();
        this.props.handleDelete(e.currentTarget.getAttribute('data-id'));
    }

    handleArchive(e) {
        e.preventDefault();
        this.onCloseModal();
        this.props.attributeView.attribute.archived = !this.props.attributeView.attribute.archived
        this.props.updateAttribute(this.props.attributeView.attribute);
    }

    handleEdit(e) {
        e.preventDefault();

        this.props.attributeView.attributeList.map((entry) => {
            if (parseInt(e.currentTarget.getAttribute('data-id')) === entry.id) {
                this.props.fetchAttribute(entry)
            }

            return null;
        })
    }

    handleConfirmEdit(e) {
        e.preventDefault();
        let block = this.props.attributeView.block;
        if (block.type === "user") {
            this.props.fetchAUser(block.block_id);
        } else {
            this.props.pageChange(block.block_id);
        }
    }

    onOpenModal = (e) => { this.setState({ open: true, attributeId: e.currentTarget.getAttribute('data-id'), archived: e.currentTarget.getAttribute('archived') }) };
    onCloseModal = () => { this.setState({ open: false, attributeId: '', archived: '' }) };

    toggleArchived() {
        this.setState({
            showArchived: !this.state.showArchived
        })
    }

    onCloseBlockModal(e) {
        e.preventDefault();
        let block = this.props.attributeView.block;
        this.props.updateModal({
            blockOpen: false,
            block_id: block.block_id,
            isBlocking: block.isBlocking,
            type: block.type
        })
    }

    componentDidMount() {
        // Detect when scrolled to bottom.
        this.refs.scroll.addEventListener("scroll", () => {
            if (
                this.refs.scroll.scrollTop + this.refs.scroll.clientHeight >=
                this.refs.scroll.scrollHeight
            ) {
                this.props.loadMore();
            }
        });
    }

    openTypeExport() {
        this.props.toggleSelect('export', 'attributeReport');
    }

    capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    render() {
        const user = JSON.parse(localStorage.getItem('user')).user;
        const attributeList = this.props.attributeView.attributeList;
        const selectedAttribute = this.props.attributeView.attribute;
        const archivedAttributes = attributeList.filter(attribute => attribute.archived)

        const attributes = attributeList.map((attribute, key) => {
            let branches = '';
            let moreBranch = '';

            if (attribute.branches && Array.isArray(attribute.branches) && attribute.branches.length > 0) {
                branches = attribute.branches[0].name;
                if (attribute.branches.length > 1) {
                    moreBranch = '+' + attribute.branches.length - 1 + ' more';
                }
            }

            let attached_to = '';
            if (attribute.attached_to === 'goodnews') {
                attached_to = 'Social Impact';
            } else {
                attached_to = this.capitalize(attribute.attached_to);
            }

            if (!this.state.showArchived && !attribute.archived)
                return (
                    <a href={"#" + attribute.id} key={key} data-id={attribute.id}
                        onMouseEnter={this.handHoverEnter.bind(this)}
                        onMouseLeave={this.handHoverLeave.bind(this)}
                        onClick={this.handleEdit.bind(this)}>
                        <div className="list-div">
                            <div className="name inline">
                                {branches} <span className='moreBranch bold'>{moreBranch}</span> | {Constants.ATTRIBUTE_TYPE_VIEW[attribute.attribute_type_id]} | {attached_to}
                                <br />
                                <span>{attribute.title}</span>
                            </div>
                            <div className={this.props.attributeView.hover_id === attribute.id || parseInt(selectedAttribute.id) === parseInt(attribute.id) ? 'button-div inline float-right' : 'button-div inline float-right display-none'}>

                                {user.role_id < Constants.GUEST ?
                                    <>
                                        <button onClick={this.onOpenModal.bind(this)} data-id={attribute.id} archived="true">Archive</button>
                                        <button onClick={this.onOpenModal.bind(this)} data-id={attribute.id}>Delete</button>
                                    </>
                                    : ""}
                                <button href="#1">Edit</button>
                            </div>
                            <div className={this.props.attributeView.hover_id === attribute.id || parseInt(selectedAttribute.id) === parseInt(attribute.id) ? 'arrow-div inline float-right display-none' : 'arrow-div inline float-right'}>
                                <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
                            </div>
                        </div>
                    </a>
                );
            else if (this.state.showArchived && attribute.archived)
                return (
                    <a href={"#" + attribute.id} key={key} data-id={attribute.id}
                        onMouseEnter={this.handHoverEnter.bind(this)}
                        onMouseLeave={this.handHoverLeave.bind(this)}
                        onClick={this.handleEdit.bind(this)}>
                        <div className="list-div">
                            <div className="name inline">
                                {branches} <span className='moreBranch bold'>{moreBranch}</span> | {Constants.ATTRIBUTE_TYPE_VIEW[attribute.attribute_type_id]} | {attached_to}
                                <br />
                                <span>{attribute.title}</span>
                            </div>
                            <div className={this.props.attributeView.hover_id === attribute.id || selectedAttribute.id === attribute.id ? 'button-div inline float-right' : 'button-div inline float-right display-none'}>

                                {user.role_id < Constants.GUEST ?
                                    <>
                                        <button onClick={this.onOpenModal.bind(this)} data-id={attribute.id} archived="true">Restore</button>
                                        <button onClick={this.onOpenModal.bind(this)} data-id={attribute.id}>Delete</button>
                                    </>
                                    : ""}
                                <button href="#1">Edit</button>
                            </div>
                            <div className={this.props.attributeView.hover_id === attribute.id || selectedAttribute.id === attribute.id ? 'arrow-div inline float-right display-none' : 'arrow-div inline float-right'}>
                                <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
                            </div>
                        </div>
                    </a>
                )
        });

        return (
            <div className="manage-div normal-box normal-box-margin float-left" ref="scroll">
                <div className="manage-list">
                    <h1>Manage Attributes
                        {this.state.showArchived ?
                            <span className='archived cursor-pointer' onClick={this.toggleArchived.bind(this)}>General Attributes ({attributeList.length - archivedAttributes.length})</span>
                            :
                            <span className='archived cursor-pointer' onClick={this.toggleArchived.bind(this)}>Archived ({archivedAttributes.length})</span>
                        }
                    </h1>

                    {user.role_id < Constants.GUEST ?
                        <div className="clickable export-link ab" onClick={this.openTypeExport.bind(this)}>Export All</div>
                        : ""}
                    {attributes}
                    {
                        this.props.loading ? <div className="loadmore"></div> : ''
                    }
                </div>
                <Modal open={this.state.open} onClose={this.onCloseModal.bind(this)} center>
                    <div className="userModal">
                        <p>Are you sure you want to {this.state.showArchived && this.state.archived ? 'restore' : this.state.archived ? 'archive' : 'delete'} this attribute?</p>
                        <div className="center">
                            {this.state.archived ?
                                <button className="yesButton" data-id={this.state.attributeId} onClick={this.handleArchive.bind(this)}>Yes</button>
                                :
                                <button className="yesButton" data-id={this.state.attributeId} onClick={this.handDelete.bind(this)}>Yes</button>}

                            <button className="cancelButton" onClick={this.onCloseModal.bind(this)}>Cancel</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageAttribute);
