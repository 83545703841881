import React from 'react';
import PlaceholderGoodNews from './placeholder/PlaceholderGoodNews';
import { connect } from "react-redux";
import { fetchGoodNewsList } from '../../action/goodNews';
import { timeDifference } from '../../utils/date'

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    fetchGoodNewsList
};

class GoodNews extends React.Component {

    constructor(props) {
        super(props);
        this.props.fetchGoodNewsList();
    }

    render() {
        const rawData = this.props.goodNewsView.goodNewsList;
        let data = [];
        
        if(rawData.length > 0) {
            rawData.map((entry) => {
                
                let tempt = {
                    title: entry.title,
                    thumbnail: entry.image_url,
                    url: '#', // missing here
                    excerpt: entry.description,
                    first_name: entry.user.first_name,
                    last_name: entry.user.last_name,
                    time: timeDifference(entry.updated_at),
                    branch: entry.branch.name,
                    profilePicture: '' // missing here
                }

                data.push(tempt);

                return null;
            });
        }

        let bgStyle = {
            background: '#fff',
            position: 'relative',
            height: '560px'
        };

        if(data.length > 0) {
            return (
                <div className="reportCard last">
                    <div className="reportCardContainer">
                        {/* Filter headers */}
                        <div className="header">
                            <div className="headerTitle">
                                <h4>Social Impact</h4>
                            </div>
                        </div>

                        <div className="feedWrapper">
                            {
                                data.map((entry, key) => {

                                    return (<div className="feedContainer" key={key}>
                                        <div className="authorProfile">
                                            <div className="authorPicture">
                                                {(() => {
                                                    if (entry.profilePicture.length > 0) {
                                                        return (
                                                            <img alt={entry.first_name} src={entry.profilePicture} width="30" height="30" style={{ borderRadius: '50%' }} />
                                                        )
                                                    } else {
                                                        return (
                                                            <div style={{ fontSize: '16px', color: '#707070', fontWeight: '100', textAlign: 'center', marginTop: '4px' }}>
                                                                {entry.first_name.charAt(0) + entry.last_name.charAt(0)}
                                                            </div>
                                                        )
                                                    }
                                                })()}
                                            </div>
                                            <div className="authorName">
                                                <p>{entry.first_name + ' ' + entry.last_name}</p>
                                                <span>{entry.time + ' - ' + entry.branch}</span>
                                            </div>
                                        </div>

                                        <div className="feedContent">
                                            <div className="feedThumb">
                                                <div className="feedThumbContainer" style={{ backgroundImage: 'url(' + entry.thumbnail + ')' }}></div>
                                            </div>
                                            <div className="feedTitle">
                                                <p><strong>{entry.title}</strong></p>
                                            </div>
                                            <div className="feedExcerpt">
                                                <p>{entry.excerpt}</p>
                                            </div>
                                        </div>
                                    </div>)
                                })
                            }

                        </div>
                    </div>
                </div>
            );
        } else {
            if (this.props.goodNewsView.total_count < 0){
                return (
                    <div className="reportCard last" style={bgStyle}>
                        <PlaceholderGoodNews/>
                    </div>
                )
            }else{
                return (
                    <div className="reportCard last" style={bgStyle}>
                        <div className="normal-margin">
                        No Social Impact Found
                        </div>
                    </div>
                )
            }
        }
        
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GoodNews);
