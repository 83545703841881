import React from 'react';
import * as Constants from "../../Constants";

class VerticalChart extends React.Component {
    render() {
        let data = this.props.data;
        
        if (this.props.sort) {
            data = data.sort((a,b) => (a.total > b.total) ? -1 : ((b.total > a.total) ? 1 : 0))
        }

        let colors = Constants.COLORS;
        let total = 0;

        data.map((entry) => {
            total += parseInt(entry.total);
            return null;
        });

        return (
            <div className={this.props.short ? "shortVerticalChartContainer" : "verticalChartContainer"}>
            {
                data.map((entry, index) => {
                    let width = entry.total * 100 / total;
                    if(isNaN(width)) {
                        width = 0;
                    }
                    return (
                        <div className="verticalBar" key={index}>
                            <div className="barLabel">{entry.name}</div>
                            <div className="barValue">{entry.total}</div>
                            
                            <div className="bar" style={{ backgroundColor: colors[index % colors.length], width: width + '%'}}/>
                            <div className="barBackground" />
                        </div>
                    )
                })
            }
            </div>
                
        );
    }
}

export default VerticalChart;
