import * as Constants from '../Constants';
import axios from 'axios';
import { receiveOverviewData } from './overview'
import { getDateInUsFormat } from '../utils/date';

export const pageChange = (page) => ({ type: Constants.PAGE_CHANGE, page: page });
export const pageChangeWithNoStateChange = (page) => ({ type: Constants.PAGE_CHANGE_WITH_NO_STATE_CHANGE, page: page });
export const fetchingStart = () => ({ type: Constants.FETCHING_START });
export const fetchingEnd = () => ({ type: Constants.FETCHING_END });
export const toggleSelect = (view_type = null, id = null, id2 = null, id3 = null) => ({ type: Constants.TOGGLE_SELECT, view_type: view_type, id: id, id2: id2, id3: id3 });
export const closeImage = (view) => ({ type: Constants.CLOSE_IMAGE, view: view });
export const updatePreviewFile = (result, view) => ({ type: Constants.UPDATE_PREVIEW_FILE, result: result, view: view });
export const updateField = (field, view, data) => ({ type: Constants.UPDATE_FIELD, field: field, view: view, data: data });
export const updateAttributeField = (field, view, data) => ({ type: Constants.UPDATE_CUSTOM_FIELD, field: field, view: view, data: data });

export const nochange = () => ({ type: "no change" });


export function previewFile(file_id, view) {
    return dispatch => {
        var file = document.getElementById(file_id).files[0];


        if (file && file.name) {

            let filename = file.name.split('.')
            let filetype = filename[filename.length - 1];

            if (filetype === 'png' || filetype === 'PNG' || filetype === 'jpg' || filetype === 'JPG' || filetype === 'jpeg' || filetype === 'JPEG') {
                var reader = new FileReader();

                reader.onload = function () {
                    dispatch(updatePreviewFile(reader.result, view));

                }
                reader.readAsDataURL(file);
            } else {
                alert("You can only upload image in the format of png, jpg and jpeg")
            }
        }
    }
}

export const receiveAllDashboard = (data, time_id, startDate, endDate) => ({ type: Constants.RECEIVE_ALL_DASHBOARD, data: data, time_id: time_id, startDate: startDate, endDate: endDate });

// fetch overview data for dashboard
function fetchAllDashboardAPI(time_id, branch_id, startDate = null, endDate = null) {
    return async dispatch => {
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + JSON.parse(localStorage.getItem('user')).access_token,
            }
        };

        // dispatch(fetchingData())
        try {
            const response = await axios.get(
                Constants.API +
                'dashboard/all/' +
                (time_id ? parseInt(time_id) + 1 : 1) +
                (branch_id ? '?branch_id=' + branch_id : '') +
                (startDate ? (branch_id ? '&startDate=' : '?startDate=') + getDateInUsFormat(startDate) : '') +
                (endDate ? '&endDate=' + getDateInUsFormat(endDate) : ''), axiosConfig);
            const data = response.data;
            return dispatch(receiveAllDashboard(data, time_id, startDate, endDate));
        } catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error.code === 1) {
                    localStorage.removeItem('user');
                    window.location.reload();
                }
            }
        }
    }
}

export function fetchAllDashboard(time_id = 0, branch_id = 0, startDate = null, endDate = null) {
    if (time_id === 5 && !startDate && !endDate) {
        return (dispatch) => {
            return dispatch(receiveOverviewData({ data: [] }, time_id));
        }
    } else {
        return (dispatch) => {
            return dispatch(fetchAllDashboardAPI(time_id, branch_id, startDate, endDate))
        }
    }
}

export const changeDashboardBranch = (branch_id) => ({
    type: Constants.CHANGE_DASHBOARD_BRANCH,
    dashboard_branch: branch_id
});

export const sizeForPrint = () => ({ type: Constants.SIZE_FOR_PRINT })