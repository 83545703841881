import React from 'react';
import { connect } from "react-redux";
import { pageChange } from '../../action/view';
import AddAttributeForm from './AddAttributeForm';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageChange
};

class Attributes extends React.Component {
    render() {
        return (
            <div className="AddSurvey">
                <AddAttributeForm/>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Attributes);
