import React from 'react';


class AddSession extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step_count: this.props.step_count,
            done: this.props.done,
            editing:this.props.editing
        }
    }

    handleClick(e){
        e.preventDefault();
        let id = parseInt(e.currentTarget.getAttribute('data-id'));
        let elm = document.getElementById(id)
        if (typeof(elm) != 'undefined' && elm != null){
            elm.scrollIntoView({behavior: 'smooth'});
        }
        this.props.changeStep(id);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props !== prevProps) {
            this.setState({
                step_count: this.props.step_count,
                done: this.props.done,
                editing:this.props.editing
            })
        }
      }

    render() {
        const steps = [];
        for (let i= 0; i < this.state.step_count; i++){

            steps.push(<div key={i} className={"step-container clickable" + (this.state.done.includes(i) && this.state.editing !== i ? " done": "") + (this.state.editing === i ? " editing": "")} data-id={i} onClick={this.handleClick.bind(this)}>
                <div className="step-round">
                    {this.state.done.includes(i) && this.state.editing !== i ? <img src="/img/check-white.svg" alt="White Check" className="white-check"></img> : ""}
                    {this.state.editing === i ?<img src="/img/dotdotdot.svg" alt="Dot" className="dot"></img>: ""}
                </div>
                <h1>Step {i+1}</h1>
            </div>);

        }
        return (
            <div className="step-div ab">
                {steps}
            </div>
        );
    }
}

export default AddSession;
