import React from 'react';
import { connect } from "react-redux";
import { } from '../../action/user';
import { selectWorkplanBranch } from '../../action/workplan';
import { toggleSelect } from '../../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    selectWorkplanBranch,
    toggleSelect
};
class SelectBranch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchBranches: this.props.branchView.branchList.filter(function (branch) {
                return branch.deleted_at === null;
            }),
            branches: this.props.branchView.branchList.filter(function (branch) {
                return branch.deleted_at === null;
            }),
            workplanBranch: this.props.workplanView.workplan.branch ? this.props.workplanView.workplan.branch : {}
        }
    }

    toggleSelect(e) {
        e.preventDefault();
        this.props.toggleSelect();
    }

    selectBranch(e) {
        if (Object.keys(this.state.workplanBranch).length > 0) {
            this.props.selectWorkplanBranch(this.state.workplanBranch);
        }
        this.props.toggleSelect();
    }

    search(e) {
        let searchBranches = []
        for (var i = 0; i < this.state.branches.length; i++) {
            let branchName = this.state.branches[i].name;
            if (branchName.toLowerCase().includes(e.currentTarget.value.toLowerCase())) {
                searchBranches.push(this.state.branches[i]);
            }
        }
        this.setState({
            searchBranches: searchBranches,
            branches: this.state.branches,
            workplanBranch: this.state.workplanBranch
        });
    }

    toggleOneBranch(e) {
        let branchId = e.currentTarget.getAttribute('data-id');

        let workplanBranch = {};
        for (var i = 0; i < this.state.branches.length; i++) {
            if (this.state.branches[i].id === parseInt(branchId)) {
                workplanBranch = this.state.branches[i];
                break;
            }
        }
        this.setState({
            searchBranches: this.state.searchBranches,
            branches: this.state.branches,
            workplanBranch: workplanBranch
        });
    }

    render() {
        const branches = this.state.searchBranches.map((branch, key) => {
            let branchClass = '';
            if (branch.id === this.state.workplanBranch.id) {
                branchClass = 'active';
            }

            return (
                <div className="item-name" key={branch.id} data-id={branch.id}>
                    {branch.name}
                    <div className="float-right round-checkbox" data-id={branch.id} onClick={this.toggleOneBranch.bind(this)}>
                        <div className={branchClass}></div>
                    </div>
                </div>
            );
        });
        return (
            <div className="session select-div normal-box normal-box-margin-2 step-div">
                <div className="normal-margin-heading" >
                    <h1 className="clickable" onClick={this.toggleSelect.bind(this)}><img src="/img/arrow-left.svg" alt="Arrow Left" className="arrow-left"></img>Select Club</h1>
                    <div className="search-field">
                        <input placeholder="Search Clubs" onChange={this.search.bind(this)}></input>
                        <img src="/img/search-icon.svg" alt="Search Icon" className="search-icon"></img>
                    </div>
                </div>
                <div className="normal-margin" >
                    {branches}
                    <button className="red-white-button" onClick={this.selectBranch.bind(this)}>Select</button>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectBranch);
