import React from 'react';
import { connect } from "react-redux";
import { fetchSurvey, hoverEnter, hoverLeave } from '../../action/survey';
import { pageChange } from '../../action/view';
import Modal from 'react-responsive-modal';
import { timeDifference } from '../../utils/date';
import * as Constants from '../../Constants';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    hoverEnter,
    hoverLeave,
    pageChange,
    fetchSurvey
};
class ManageSurvey extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    handHoverEnter(e) {
        e.preventDefault();
        this.props.hoverEnter(e.currentTarget.getAttribute('data-id'));
    }

    handHoverLeave(e) {
        e.preventDefault();
        this.props.hoverLeave();
    }

    handDelete(e) {
        e.preventDefault();
        this.onCloseModal();
        this.props.handleDelete(e.currentTarget.getAttribute('data-id'));
    }

    handleEdit(e) {
        e.preventDefault();

        this.props.surveyView.surveyList.map((entry) => {
            if (parseInt(e.currentTarget.getAttribute('data-id')) === entry.id) {
                this.props.fetchSurvey(entry)
            }

            return null;
        })
    }

    handleConfirmEdit(e) {
        e.preventDefault();
        let block = this.props.surveyView.block;
        if (block.type === "user") {
            this.props.fetchAUser(block.block_id);
        } else {
            this.props.pageChange(block.block_id);
        }
    }

    onOpenModal = (e) => { this.setState({ open: true, surveyId: e.currentTarget.getAttribute('data-id') }) };
    onCloseModal = () => { this.setState({ open: false, surveyId: '' }) };

    onCloseBlockModal(e) {
        e.preventDefault();
        let block = this.props.surveyView.block;
        this.props.updateModal({
            blockOpen: false,
            block_id: block.block_id,
            isBlocking: block.isBlocking,
            type: block.type
        })
    }

    componentDidMount() {
        // Detect when scrolled to bottom.
        this.refs.scroll.addEventListener("scroll", () => {
            if (
                this.refs.scroll.scrollTop + this.refs.scroll.clientHeight >=
                this.refs.scroll.scrollHeight
            ) {
                this.props.loadMore();
            }
        });
    }

    openTypeExport() {
        window.open(
            Constants.API + 'report/surveyDataDump?token=' + JSON.parse(localStorage.getItem('user')).access_token,
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    openTypeSingleExport(id) {
        window.open(
            Constants.API + 'report/singleSurveyDataDump?token=' + JSON.parse(localStorage.getItem('user')).access_token + '&id=' + id,
            '_blank' // <- This is what makes it open in a new window.
        );
    }

    render() {
        const user = JSON.parse(localStorage.getItem('user')).user;
        const surveyList = this.props.surveyView.surveyList ? this.props.surveyView.surveyList : [];
        const selectedSurvey = this.props.surveyView.survey;

        const surveys = surveyList.map((survey, key) => {
            let timeDisplay = timeDifference(survey.created_at);

            let userName = 'Deleted User';
            if (survey.user !== undefined) {
                if (survey.user !== null) {
                    userName = survey.user.first_name + ' ' + survey.user.last_name;
                }

                return (
                    <a href="#1" key={survey.id} data-id={survey.id}
                        onMouseEnter={this.handHoverEnter.bind(this)}
                        onMouseLeave={this.handHoverLeave.bind(this)}
                        onClick={this.handleEdit.bind(this)}>
                        <div className="list-div">
                            <div className="name inline">
                                {userName}
                                <br />
                                <span>{timeDisplay} - {survey.branch.name}</span>
                            </div>
                            <div className={this.props.surveyView.hover_id === survey.id || selectedSurvey.id === survey.id ? 'button-div inline float-right' : 'button-div inline float-right display-none'}>
                                {user.role_id < Constants.GUEST ?
                                    <>
                                        <button onClick={() => this.openTypeSingleExport(survey.id)} data-id={survey.id}>Export</button>
                                        <button onClick={this.onOpenModal.bind(this)} data-id={survey.id}>Delete</button>
                                    </>
                                    : ""}

                            </div>
                            <div className={this.props.surveyView.hover_id === survey.id || selectedSurvey.id === survey.id ? 'arrow-div inline float-right display-none' : 'arrow-div inline float-right'}>
                                <img src="/img/arrow.svg" alt="arrow" className="arrow"></img>
                            </div>
                        </div>
                    </a>
                );
            } else
                return <></>

        });
        return (
            <div className="manage-div normal-box normal-box-margin float-left" ref="scroll">
                <div className="manage-list">
                    <h1>Manage Surveys</h1>
                    {user.role_id < Constants.GUEST ?
                        <div className="clickable export-link ab" onClick={this.openTypeExport.bind(this)}>Export All</div>
                        : ""}
                    {surveys}
                    {
                        this.props.loading ? <div className="loadmore"></div> : ''
                    }
                </div>
                <Modal open={this.state.open} onClose={this.onCloseModal.bind(this)} center>
                    <div className="userModal">
                        <p>Are you sure you want to delete this survey?</p>
                        <div className="center">
                            <button className="yesButton" data-id={this.state.surveyId} onClick={this.handDelete.bind(this)}>Yes</button>
                            <button className="cancelButton" onClick={this.onCloseModal.bind(this)}>Cancel</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageSurvey);
