import React from 'react';
import { connect } from "react-redux";
import { signup, removeErrorMsg } from '../../action/user';

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = {
  signup,
  removeErrorMsg
};

class Signup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      email: ''
    }
  }
  firstNameChange(e) {
    this.setState({
      first_name: e.currentTarget.value,
      last_name: this.state.last_name,
      email: this.state.email
    });
    this.props.removeErrorMsg();
  }

  lastNameChange(e) {
    this.setState({
      first_name: this.state.first_name,
      last_name: e.currentTarget.value,
      email: this.state.email
    });
    this.props.removeErrorMsg();
  }

  emailChange(e) {
    this.setState({
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: e.currentTarget.value
    });
    this.props.removeErrorMsg();
  }

  handleClick(e) {
    this.props.signup(this.state);
  }

  onSignInClick() {
    this.props.onSignInClick();
    this.props.removeErrorMsg();
    window.location.href = "/login";
  }

  render() {
    const message = this.props.loginView.message; 
    const errors = this.props.loginView.errors;
    const server_errors = this.props.loginView.server_errors;
    const server_error_str = server_errors ? server_errors.map((error, key) =>
      <div className="error-img-div" key={key}>
        <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{error}
      </div>
    ) : "";
    return (
      <div className="Signup login-system">
        <div className="right red-background">
          <img src="/img/red-logo.svg" alt="User Logo" className="logo"></img>
          <h1>Welcome back</h1>
          <h5>Sign in to Songbird</h5>
          <button onClick={this.onSignInClick.bind(this)} className="inline">Sign In</button>
          <p className="copyright">© PCYC Queensland</p>
        </div>
        <div className="left white-background">
          {server_errors ?
            <div className="error-div">
              {server_error_str}
            </div> : ""}
          
            { message ? 
            <div className="sign-in-box">
              <h1>Request Recived</h1>
              <img alt="Pistive Vote" src="/img/positive-vote.svg" className="postive-vote"></img>
              <p className="message">Thanks! An email has been sent to you, follow the instructions.</p>
            </div>
            :
            <div className="sign-in-box">
              <h1>Sign up as a guest</h1>
              <h5>Provide your details below for access</h5>
              <input name="first_name" type="text" placeholder="First Name" onChange={this.firstNameChange.bind(this)}></input>
              {errors && errors.first_name ?
                <div className="error-img-div float-right">
                  <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.first_name}
                </div> : ""}
              <input name="last_name" type="text" placeholder="Last Name" onChange={this.lastNameChange.bind(this)}></input>
              {errors && errors.last_name ?
                <div className="error-img-div float-right">
                  <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.last_name}
                </div> : ""}
              <input name="email" type="email" placeholder="Email Address" onChange={this.emailChange.bind(this)}></input>
              {errors && errors.email ?
                <div className="error-img-div float-right">
                  <img src="/img/error_icon.svg" alt="Error Icon" className="error-icon"></img>{errors.email}
                </div> : ""}
              <div className="button-div">
                <button onClick={this.handleClick.bind(this)}>Sign up</button>
              </div>
            </div>}
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup);
