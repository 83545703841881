import React from 'react';
import { connect } from "react-redux";
import * as Constants from '../../Constants';
import { pageChange } from '../../action/view';
import { Link } from "react-router-dom";
import { getDateInUsFormat } from '../../utils/date';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    pageChange
};

class AddSuccess extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            downloadReady: false
        };
    }

    returnToAttributes(e) {
        this.props.resetState();
        this.props.pageChange(Constants.ATTRIBUTES);
    }

    handleDownload(e) {
        let attributeIds = '' + this.props.currentState.attributes.join(',');
        let branchIds = '' + this.props.currentState.branch_id.join(',');
        let url = Constants.API + 'report/attributeReport' + '?token=' + JSON.parse(localStorage.getItem('user')).access_token + '&id=6&attribute_ids=' + attributeIds + '&branch_ids=' + branchIds;

        if (this.props.currentState.startDate) {
            window.open(
                url + '&startDate=' + getDateInUsFormat(this.props.currentState.startDate) + '&endDate=' + getDateInUsFormat(this.props.currentState.endDate),
                '_blank' // <- This is what makes it open in a new window.
            );
            this.props.resetState();
        } else {
            window.open(
                url,
                '_blank' // <- This is what makes it open in a new window.
            );
            this.props.resetState();
        }
        this.props.pageChange(Constants.CUSTOM_REPORTS);
    }

    render() {
        return (
            <div className="add-user-div normal-box float-left">
                <div className="add-user normal-margin center">
                    <img alt="Pistive Vote" src="/img/positive-vote.svg" className="postive-vote"></img>
                    <p>The report has been created.</p>
                    <button className="white-red-button" onClick={this.handleDownload.bind(this)}>Download</button>
                    <br />

                    <Link to="/attributes" onClick={this.returnToAttributes.bind(this)}><img src="/img/arrow-left-red.svg" alt="Arrow Left" className="arrow"></img>Return to Attributes</Link>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSuccess);
