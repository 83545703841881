import React from 'react';

class PlaceholderPerformance extends React.Component {
    render() {
        const timeBar = 6;
        const chartBar = 28;

        let resultBar = [];
        let resultChart = [];

        for (let i = 0; i < timeBar; i++) {
            resultBar.push(<div className="barPlaceholder" key={i}/>);
        }

        for (let i = 0; i < chartBar; i++) {
            resultChart.push(<div className="chartPlaceholder" key={i}/>);
        }

        return (
            <div className="loaderContainer" style={{}}>
                <div className="resultBar">
                    {resultBar}
                </div>

                <div className="resultChart">
                    {resultChart}
                </div>
            </div>
        )
    }
}

export default PlaceholderPerformance;
