import * as Constants from '../Constants';

const overviewView = (state = Constants.overviewViewInitialState, action) => {
    switch (action.type) {
        case Constants.RECEIVE_OVERVIEW_DATA:
            return {
                data: action.data,
                timeSelected: action.timeSelected,
                date: state.date,
                startDate: action.startDate,
                endDate: action.endDate
            }
        case Constants.RECEIVE_ALL_DASHBOARD:
            return {
                data: { data: action.data.data.overview },
                timeSelected: action.time_id,
                date: action.data.data.daysSinceLastSession,
                startDate: action.startDate,
                endDate: action.endDate
            }
        case Constants.RECEIVE_DAYS_SINCE_LAST_SESSION:
            return {
                date: action.date,
                data: state.data,
                timeSelected: state.timeSelected
            }
        case Constants.PAGE_CHANGE:
            return {
                ...state,
                timeSelected: 0
            }

        default:
            return state
    }
}

export default overviewView;