import React from 'react';
import { connect } from "react-redux";
import { } from '../../action/user';
import { selectAttributeActivity } from '../../action/attribute';
import { toggleSelect } from '../../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    selectAttributeActivity,
    toggleSelect
};
class SelectActivity extends React.Component {

    constructor(props) {
        super(props);
        let type = this.props.type
        let attribute_activity = this.props.attributeView.attribute.activities ? this.props.attributeView.attribute.activities : []
        let attributeActivities = []
        for (let i = 0; i < attribute_activity.length; i++) {
            if (!attribute_activity[i].deleted) {
                attributeActivities.push({
                    ...attribute_activity[i],
                })
            }
        }
        this.state = type ? {
            searchActivities: this.props.activityView.activityList.filter(function (activity) {
                return activity.type === type;
            }),
            activities: this.props.activityView.activityList.filter(function (activity) {
                return activity.type === type;
            }),
            attributeActivities: attributeActivities
        } : {
            searchActivities: this.props.activityView.activityList,
            activities: this.props.activityView.activityList,
            attributeActivities: attributeActivities
        }
    }

    toggleSelect(e) {
        e.preventDefault();
        this.props.toggleSelect();
    }

    selectActivity(e) {
        this.props.selectAttributeActivity(this.state.attributeActivities);
        this.props.toggleSelect();
    }

    search(e) {
        let searchActivities = []
        for (var i = 0; i < this.state.activities.length; i++) {
            let activityName = this.state.activities[i].name;
            if (activityName.toLowerCase().includes(e.currentTarget.value.toLowerCase())) {
                searchActivities.push(this.state.activities[i]);
            }
        }
        this.setState({
            searchActivities: searchActivities,
            activities: this.state.activities,
            attributeActivities: this.state.attributeActivities
        });
    }

    toggleOneBranch(e) {
        let activityId = e.currentTarget.getAttribute('data-id');

        let attributeActivities = [];
        let addClicked = true;
        for (var i = 0; i < this.state.attributeActivities.length; i++) {
            if (this.state.attributeActivities[i].id !== parseInt(activityId)) {
                attributeActivities.push(this.state.attributeActivities[i])
            } else {
                addClicked = false
            }
        }

        if (addClicked) {
            let activity = "";
            for (var i2 = 0; i2 < this.state.activities.length; i2++) {
                if (this.state.activities[i2].id === parseInt(activityId)) {
                    activity = this.state.activities[i2];
                    break;
                }
            }
            attributeActivities.push({
                ...activity,
                order: 1
            })
        }
        this.setState({
            searchActivities: this.state.searchActivities,
            activities: this.state.activities,
            attributeActivities: attributeActivities
        });
    }

    render() {
        const activities = this.state.searchActivities.map((activity, key) => {
            let activityClass = '';
            for (var i = 0; i < this.state.attributeActivities.length; i++) {
                let attributeActivity = this.state.attributeActivities[i];
                if (attributeActivity.id === activity.id) {
                    activityClass = 'active';
                }
            }

            return (
                <div className="item-name" key={key} data-id={activity.id}>
                    {activity.image_url ? <div className="image-circle inline"><img className="img" src={activity.image_url} alt={activity.name} /></div>
                        : <div className="user-logo-div inline">
                            <div className="user-logo">
                                {activity.abbreviation}
                            </div>
                        </div>}
                    <span className="inline">{activity.name}</span>
                    <div className={"inline colordot" + (activity.require_instructor ? " amber" : "") + (activity.require_indemnification ? " red" : "")}></div>
                    <div className="float-right squre-checkbox activity" data-id={activity.id} onClick={this.toggleOneBranch.bind(this)}>
                        <div className={activityClass}><img src="/img/check.svg" alt="checkbox" className="check-img"></img></div>
                    </div>
                </div>
            );
        });
        return (
            <div className="session select-div normal-box normal-box-margin-2 step-div">
                <div className="normal-margin-heading" >
                    <h1 className="clickable" onClick={this.toggleSelect.bind(this)}><img src="/img/arrow-left.svg" alt="Arrow Left" className="arrow-left"></img>Select Activity</h1>
                    <div className="search-field">
                        <input placeholder="Search Activities" onChange={this.search.bind(this)}></input>
                        <img src="/img/search-icon.svg" alt="Search Icon" className="search-icon"></img>
                    </div>
                </div>
                <div className="normal-margin" >
                    {activities}
                    <button className="red-white-button" onClick={this.selectActivity.bind(this)}>Select</button>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectActivity);
