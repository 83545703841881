import React from 'react';
import { connect } from "react-redux";
import { } from '../../action/user';
import { selectWorkplanUser } from '../../action/workplan';
import { toggleSelect } from '../../action/view';

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    selectWorkplanUser,
    toggleSelect
};
class SelectUser extends React.Component {

    constructor(props) {
        super(props);
        let user_id = this.props.root.id
        let user_inarray = this.props.userView.userList.filter(function (el) {
            return el.id === user_id;
        })

        this.state = {
            searchUsers: this.props.userView.userList,
            users: this.props.userView.userList,
            workplanUser: user_inarray[0] ? user_inarray[0] : {}
        }
    }

    toggleSelect(e) {
        e.preventDefault();
        this.props.toggleSelect();
    }

    selectUser(e) {
        if (Object.keys(this.state.workplanUser).length > 0) {
            this.props.selectWorkplanUser(this.state.workplanUser, this.props.root.id2, this.props.root.id3);
        }
        this.props.toggleSelect();
    }

    search(e) {
        let searchUsers = []
        for (var i = 0; i < this.state.users.length; i++) {
            let name = this.state.users[i].first_name + " " + this.state.users[i].last_name;
            if (name.toLowerCase().includes(e.currentTarget.value.toLowerCase())) {
                searchUsers.push(this.state.users[i]);
            }
        }
        this.setState({
            searchUsers: searchUsers,
            users: this.state.users,
            workplanUser: this.state.workplanUser
        });
    }

    toggleOneUser(e) {
        let userId = e.currentTarget.getAttribute('data-id');

        let workplanUser = {};
        for (var i = 0; i < this.state.users.length; i++) {
            if (this.state.users[i].id === parseInt(userId)) {
                workplanUser = this.state.users[i];
                break;
            }
        }
        this.setState({
            searchUsers: this.state.searchUsers,
            users: this.state.users,
            workplanUser: workplanUser
        });
    }

    render() {
        const users = this.state.searchUsers.map((user, key) => {
            let userClass = '';
            if (user.id === this.state.workplanUser.id) {
                userClass = 'active';
            }

            return (
                <div className="item-name" key={user.id} data-id={user.id}>
                    <span style={{ cursor: 'pointer' }} data-id={user.id} onClick={this.toggleOneUser.bind(this)}>{user.first_name + ' ' + user.last_name}</span>
                    <div className="float-right round-checkbox" data-id={user.id} onClick={this.toggleOneUser.bind(this)}>
                        <div className={userClass}></div>
                    </div>
                </div>
            );
        });
        return (
            <div className="session select-div normal-box normal-box-margin-2 step-div">
                <div className="normal-margin-heading" >
                    <h1 className="clickable" onClick={this.toggleSelect.bind(this)}><img src="/img/arrow-left.svg" alt="Arrow Left" className="arrow-left"></img>Select User</h1>
                    <div className="search-field">
                        <input placeholder="Search Users" onChange={this.search.bind(this)}></input>
                        <img src="/img/search-icon.svg" alt="Search Icon" className="search-icon"></img>
                    </div>
                </div>
                <div className="normal-margin" >
                    {users}
                    <button className="red-white-button" onClick={this.selectUser.bind(this)}>Select</button>
                </div>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectUser);
