import React from 'react';

class ArrowNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick(e) {
        this.props.onHandleNavigation(this.props.stepType, e.currentTarget.getAttribute('value'));
    }

    render() {
        return (
            <div className="navigation">
                {(() => {
                    if(this.props.isFirst === true) {
                        return (
                            <div className="back navArrow" onClick={this.handleOnClick} value="-1">Back</div>
                        )
                    } else {
                        return (<div className="back navArrow" onClick={this.handleOnClick} value="-1">← Previous</div>)
                    }
                })()}

                {(() => {
                    if (this.props.isLast === true) {
                        return (
                            <div className="next navArrow" onClick={this.handleOnClick} value="+1">Done</div>
                        )
                    } else {
                        return (<div className="next navArrow" onClick={this.handleOnClick} value="+1">Next →</div>)
                    }
                })()}
                
            </div>
        )
    }
}

export default ArrowNavigation
