import React from 'react';
import { connect } from "react-redux";
import { fetchSurveyList } from '../../action/survey';
import { pageChange } from '../../action/view';
import * as Constants from '../../Constants';
import { Link } from "react-router-dom";

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = {
    fetchSurveyList,
    pageChange
};
class BranchList extends React.Component {

    constructor(props) {
        super(props);
        this.state =  {
            id: 0
        }
    }

    handBranchChange(e) {
        e.preventDefault();
        this.props.fetchSurveyList(e.currentTarget.getAttribute('data-id'));
        this.setState({
            id:parseInt(e.currentTarget.getAttribute('data-id'))
        })
        this.props.onBranchChange(e.currentTarget.getAttribute('data-id'))
    }

    onPageChange() {
        this.props.pageChange(Constants.ADD_SURVEY);
    }

    render() {
        const user = JSON.parse(localStorage.getItem('user')).user;
        const branchList = this.props.surveyView.branchList;

        let mySurveyCount = 0;

        branchList.map((branch, key) => {
            mySurveyCount += branch.my_survey_count;

            return null;
        });

        const branches = branchList.map((branch, key) => {
            return (
                branch.survey_count ?
                    <a href="#1" key={branch.id} data-id={branch.id} onClick={this.handBranchChange.bind(this)}>
                        <div className={"branch-name " + (this.state.id === branch.id ? "active" : "")}>
                            <div className="left">{branch.name}</div>
                            <span className="float-right">{branch.survey_count}</span>
                        </div>
                    </a> : "");
        });

        return (
            <div className="branch-div float-left">
                <a href="#1" onClick={this.handBranchChange.bind(this)} data-id="0">
                    <div className={"branch-name " + (this.state.id === 0 ? "active" : "")}>
                        All Surveys
                      <span className="float-right">{this.props.surveyView.total_count}</span>
                    </div>
                </a>
                <div className="single-branch">
                    {branches}
                </div>
                <a href="#mysurvey" data-id="-1" onClick={this.handBranchChange.bind(this)}>
                    <div className={"branch-name " + (this.state.id === -1 ? "active" : "")}>
                        My Survey
                        <span className="float-right">{mySurveyCount}</span>
                    </div>
                </a>
                {user.role_id < Constants.GUEST ?
                <Link to="/add/surveys" className="white-red-button" onClick={this.onPageChange.bind(this)}>{Constants.ADD_SURVEY}</Link> :""}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BranchList);
